import { useState, useRef, useEffect } from "react";

const useIsHover = () => {
  const ref = useRef(null);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    function mouseMove(e) {
      if (ref.current) {
        const buttonRect = ref.current.getBoundingClientRect();
        const isMouseOverButton =
          buttonRect.left <= e.clientX &&
          e.clientX <= buttonRect.right &&
          buttonRect.top <= e.clientY &&
          e.clientY <= buttonRect.bottom;
        const cbe = isMouseOverButton ? true : false;
        setIsHover(cbe);
      } else {
        setIsHover(false);
      }
    }

    document.addEventListener("mousemove", mouseMove);
    return () => {
      document.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  return [ref, isHover];
};

export default useIsHover;
