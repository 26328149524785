import React from "react";
import "./footer.css";
import logo from "../../assets/images/Underline_footer.png";
// import logo from "../../assets/images/game/png/main-logoo.png";
import instaIcon from "../../assets/images/icons/insta_icon.png";
import facebookIcon from "../../assets/images/icons/fb_icon.png";
import twitter from "../../assets/images/icons/twit_icon.png";
import { BsInstagram } from "react-icons/bs"
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
const index = () => {
  const { gameMode } = useSelector((store) => store.game);
  return (
    <div className={`footer z-10 ${gameMode ? 'hidden': ''}`}>
      <div className="mx-auto flex w-[100%] h-max max-w-[1250px] flex-col flex-wrap py-5 ">
        <div className="flex flex-wrap items-center justify-center md:gap-8 ">
          <NavLink to="/">
            <div className="w-full max-w-[300px] md:mx-auto lg:ml-0">
              <img className=" " src={logo} alt="" />
              {/* <img className="w-[300px] sm:w-full object-contain " src={logo} alt="" /> */}
            </div>
          </NavLink>
          <div className="navItems flex w-full md:w-[70%] flex-col flex-wrap items-center md:gap-5 justify-center xl:justify-between text-center text-[20px] font-medium text-[#ebe0c7] md:flex-row gap-y-4  py-4 ">
            <NavLink className="" to="leaderboard">
              Leaderboard
            </NavLink>
            <NavLink className="" to="/about">
              About us
            </NavLink>
            <NavLink className="" to="/contact">
              Contact us
            </NavLink>
            <NavLink className="" to="/profile">
              Profile
            </NavLink>
            <span className="md:hidden">
              <NavLink to="/privacy"> Privacy Policy </NavLink>
            </span>
            <span className="md:hidden">
              <NavLink to="/termsandconditions"> Terms and Conditions </NavLink>
            </span>
            <div className="logos my-2 flex items-center">
              <a href="https://www.instagram.com/officialdreamhoops/" target="_blank">
                <div className="mx-4 flex h-[40px] w-[40px] items-center justify-center rounded-[50%] bg-[#ff5700] duration-300 hover:scale-110">
                  <BsInstagram />
                  {/* <img className="w-[20px] object-contain" src={instaIcon} alt="instagram" />
                   */}
                </div>
              </a>
              {/* <a href="https://www.instagram.com/officialdreamhoops/" target="_blank">
                <div className="mx-4 flex h-[40px] w-[40px] items-center justify-center rounded-[50%] bg-[#ff5700]">
                  <img className="w-[20px] p-1 object-contain" src={facebookIcon} alt="instagram" />
                </div>
              </a>
              <a href="https://www.instagram.com/officialdreamhoops/" target="_blank">
                <div className="mx-4 flex h-[40px] w-[40px] items-center justify-center rounded-[50%] bg-[#ff5700]">
                  <img className="w-[20px] object-contain" src={twitter} alt="instagram" />
                </div>
              </a> */}
            </div>
          </div>
        </div>
        <hr className="hidden md:block mt-[50px] mb-7 w-full border-[#ebe0c7]" />
        <div className="flex justify-around flex-wrap items-center mt-7 pb-4 ">
          <span className="hidden md:block">
            <NavLink to="/privacy"> Privacy Policy </NavLink>
          </span>
          <p className="text-center font-semibold text-[#ebe0c7]">© 2023, Dreamhoops All Rights Reserved</p>
          <span className="hidden md:block">
            <NavLink to="/termsandconditions"> Terms and Conditions </NavLink>
          </span>
        </div>
      </div>
    </div>
  );
};

export default index;
