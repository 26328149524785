
export const loungePlayer = [
  // { id: crypto.randomUUID(), name: "John Appleseed", Icon: Assets1, position: "PG", agoTime: 20 },
  // { id: crypto.randomUUID(), name: "John Smith", Icon: Assets2, position: "SG", agoTime: 18 },
  // { id: crypto.randomUUID(), name: "Nathan Holmes", Icon: Assets3, position: "SF", agoTime: 17 },
  // { id: crypto.randomUUID(), name: "Chris Evans", Icon: Assets4, position: "PG", agoTime: 16 },
  // { id: crypto.randomUUID(), name: "Henry Cavil", Icon: Assets5, position: "C", agoTime: 16 },
  // { id: crypto.randomUUID(), name: "John Smith", Icon: Assets6, position: "SG", agoTime: 14 },
  // { id: crypto.randomUUID(), name: "Nathan Holmes", Icon: Assets7, position: "SF", agoTime: 12 },
  // { id: crypto.randomUUID(), name: "John Appleseed", Icon: Assets8, position: "PG", agoTime: 10 },
  // { id: crypto.randomUUID(), name: "Chris Evans", Icon: Assets9, position: "PG", agoTime: 9 },
  // { id: crypto.randomUUID(), name: "Henry Cavil", Icon: Assets10, position: "C", agoTime: 9 },
];

export const team1Cards = [
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
];

export const team2Cards = [
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
  { User: { Id: '', Name: '' } },
];
export const GAME_STATUS = {
  Started: 0,
  Ended: 1,
  Paused: 2
}