
import ScrollTop from "./utils/ScrollTop";
import AppRouter from "./config/AppRouter";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { useSelector } from "react-redux";
import { useTelemetryHub } from './utils/useRealtimeHub';
import { useEffect } from "react";


// *********** MAIN APP WRAPPER **************
const App = () => {
  const { isAuthenticated } = useSelector(
    (state) => state.auth
  );
  useTelemetryHub(isAuthenticated);

  useEffect(() => {
    let images = ['/assets/images/game/png/person.png',
      '/assets/images/game/shuffle.png',
      '/assets/images/game/time.png',
      '/assets/images/game/info.png',
      "/assets/images/game/bg.png",
      "/assets/images/game/png/Asset 11.png",
      '/assets/images/themes/LA-24/user_dh.png',
      "/assets/images/themes/LA-24/Asset 3-lafitness.png",
      "/assets/images/themes/LA-24/Asset 4-lafitness.png",
      "/assets/images/themes/LA-24/Info_LA.png",
      "/assets/images/themes/LA-24/bg_LA.png",
      "/assets/images/themes/LA-24/user_plus_LA.png",
      '/assets/images/themes/LA-24/user_LA.png',
      "/assets/images/themes/LA-24/LAF_logo_2C_H.png",
      "/assets/images/themes/LA-24/Asset 1-24hour.png",
      "/assets/images/themes/LA-24/Asset 2-24hour.png",
      "/assets/images/themes/LA-24/Info_24.png",
      "/assets/images/themes/LA-24/bg_24.png",
      "/assets/images/themes/LA-24/user_plus_24.png",
      '/assets/images/themes/LA-24/user_24.png',
      "/assets/images/themes/LA-24/24_logo_2C_H.png",
      "/assets/images/game/SVG/Asset 15.svg"];
      
      images.forEach((image) => {
        const newImage = new Image();
        newImage.src = image;
        window[image] = newImage;
    });
  }, [])
  return (
    <>
      <AppRouter />
      <ScrollTop />
    </>
  )
}

export default App
