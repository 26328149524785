
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSignalRConnection } from "../utils/signalR";
import { addPlayerInLounge } from '../store/game/gameSlice';
import { db } from '../libs/db';
export const useTelemetryHub = (connect) => {
    let connection;
    const dispatch = useDispatch();
    const { user } = useSelector(
        (state) => state.auth
    );

    useEffect(() => {
        if (!connect) {
            return;
        }

        async function subscribe() {
            connection = await getSignalRConnection('/realtimehub');

            connection.on('loungejoined', (response) => {
                const { player, locationId } = JSON.parse(response);
                if (locationId == user.LocationId) {
                    dispatch(addPlayerInLounge(player));
                    db.activity.add({ time: new Date().toISOString(), action: `${player.User.Name} joined the lounge` });
                }
                console.log('loungejoined', player);
            });
            connection.on('loungeleave', (response) => {
                console.log('loungeleave', response);
            });
            connection.on('gameEnd', (response) => {
                console.log('gameEnd', response);
            });
        }

        subscribe();

        return () => {
            if (connection) {
                connection.off('loungejoined');
                connection.off('loungeleave');
                connection.stop().then(() => {
                    console.log('connection closed');
                });
            }
        }

    }, [connect]);
}