import { useNavigate } from 'react-router-dom'
import emailSent from '../../assets/images/emailSent.png'


// ------------------ Reset Email sent success screen ---------------
const EmailSent = () => {
  const navigate = useNavigate();
  const handleResend = () => {
    navigate("/forgot-password");
  }
  return (
    <section className="h-full sm:px-5 w-full sm:flex flex-1 items-center justify-center">
      <div className="mx-auto text-center my-3 w-full bg-primary px-6 py-4 sm:min-w-[385px] max-w-[486px] sm:px-12">
        <img className="w-full max-w-[260px] mb-4 mx-auto" src={emailSent} alt="" />
        <h3 className="my-2 text-xl font-black text-white sm:text-2xl text-center">
          Check your mail
        </h3>
        <p className="text-center text-white pb-6">
          We have sent password reset instructions
          <br />
          to your email.
        </p>

        <div className="mt-[65px]">
          <p className="text-center text-white text-[15px]">
            Did not receive the email? Check your spam folder.
          </p>
          <button
            onClick={handleResend}
            className="block w-full underline font-bold text-sm cursor-pointer bg-transparent text-center text-[16px] text-orange-600 hover:text-orange-600 border-0 py-1 disabled:opacity-70"
          >
            or try another email address
          </button>
        </div>
      </div>
    </section>
  )
}

export default EmailSent