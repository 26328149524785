import { Link } from 'react-router-dom'
import loginSuccess from '../../assets/images/loginSuccess.png'


// ------------------ Success page for login ---------------
const LoginSuccess = () => {
  return (
    <section className="h-full sm:px-5 w-full sm:flex flex-1 items-center justify-center">
      <div className="mx-auto text-center my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
        <h3 className="mb-4 text-3xl font-black text-white sm:text-4xl text-center">
          dreamhoops
        </h3>
        <img className="w-full max-w-[260px] my-2 mx-auto" src={loginSuccess} alt="" />
        <p className="text-center text-white pb-6">
          Welcome back.
          <br />
          Check the player's lounge for upcoming <br />
          game details
        </p>
        <Link
          to="/profile"
          className="my-6 block w-full no-underline cursor-pointer bg-orange-600 rounded-full p-2 text-center text-[16px] text-white hover:text-white border-0 py-4 disabled:opacity-70 sm:p-3"
        >
          Done
        </Link>
      </div>
    </section>
  )
}

export default LoginSuccess