import { useState, useEffect } from "react";

import "./counter.css";
import { setGameMode } from "../../store/game/gameSlice";
import { useDispatch, useSelector } from "react-redux";

const Counter = ({ startDate }) => {
  const [remainingTime, setRemainingTime] = useState({});
  const dispatch = useDispatch();
  const {
    gameMode
  } = useSelector((store) => store.game);
  const handleGameMode = () => {
    dispatch(setGameMode(!gameMode));
  };
  useEffect(() => {
    let obj;
    if (startDate)
      obj = setInterval(() => {
        const timeInS = Math.floor((new Date().getTime() - new Date(startDate)) / 1000);
        const seconds = timeInS % 60;
        const minutes = Math.floor((timeInS / 60) % 60);
        const hours = Math.floor((timeInS / 60 / 60) % 24);
        const days = Math.floor(timeInS / 60 / 60 / 24);
        if (seconds < 0) setRemainingTime({ seconds: 0, minutes: 0, hours: 0, days: 0 });
        else setRemainingTime({ seconds, minutes, hours, days });
      }, 1000);
    else setRemainingTime({ seconds: "00", minutes: "00", hours: "00", days: "00" });
    return () => {
      clearInterval(obj);
    };
  }, [startDate]);

  return (
    <a onClick={handleGameMode} className="counter-bg mx-auto grid w-max auto-cols-max grid-flow-col justify-center rounded-xl p-1 text-center text-6xl text-[#ffb43a] font_digital-7">
      {/* minutes */}
      <div className="flex flex-col p-1 font-medium">
        <span className="own-countdown flex justify-center ">
          <span style={{ "--value": remainingTime?.minutes }}></span>
        </span>
      </div>
      <div className="self-center">:</div>
      {/* seconds */}
      <div className="flex flex-col p-1 font-medium">
        <span className="own-countdown flex justify-center ">
          <span style={{ "--value": remainingTime?.seconds }}></span>
        </span>
      </div>
    </a>
  );
};

export default Counter;
