import React, { useState, useEffect } from 'react'
import { FaSortUp, FaSortDown, FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { formatRating, isEven } from '../utils/helpers';
import axios from '../libs/http';
import { toast } from '../utils/customToast';

// ----------- Game Records table --------------------
const GameRecords = ({ userId }) => {

    const [expendedRow, setExpendedRow] = useState(null);
    const [games, setGames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [loadMore, setIsLoadMore] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        setIsLoading(true);
        axios.get(`/Game/getRecent/${userId}`).then(res => {
            setIsLoading(false);
            const data = res.data;
            data.forEach(x => x.playerDetails = { Home: [], Away: [], init: false });
            setGames(data.slice(0, 5));
            setData(data);
            if(data.length > 5) {
                setIsLoadMore(true);
            }
        }).catch(error => {
            setIsLoading(false);
            console.log(error);
            toast.error('Unable to fetch user profile');
        })
    }, [])
    useEffect(() => {
        if (expendedRow != null) {
            const game = games[expendedRow];
            if (game.playerDetails.init) return;
            axios.get(`/Game/getGamePlayers/${game.Id}`).then(res => {
                setIsLoading(false);
                const data = res.data;
                let _games = [...games];
                _games[expendedRow].playerDetails = { ...data, init: true };
                setGames(_games);
            }).catch(error => {
                setIsLoading(false);
                console.log(error);
                toast.error('Unable to fetch user profile');
            })
        }

    }, [expendedRow])

    const handleLoadMore = () => {
        setGames(data);
        setIsLoadMore(false);
    }


    return (
        <>
            <table className='table-auto hover:table-auto w-full border-collapse'>
                <thead >
                    <tr className='select-none'>
                        <th className="text-left sm:text-sm text-[14px] sm:pl-5 pl-4 cursor-pointer pr-2">
                            Date
                        </th>
                        <th className="text-left sm:text-sm text-[14px] sm:pl-5 pl-4 cursor-pointer pr-2">
                            Duration
                        </th>
                        <th className='text-left cursor-pointer sm:text-sm text-[14px] pr-2'>
                            Score
                        </th>
                        <th className="py-3 cursor-pointer sm:text-sm text-[14px] px-2">
                            Result
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {games?.map((v, i) => (
                        <React.Fragment key={i}>
                            <tr className={isEven(i) ? "bg-[#02251f]" : "bg-[#011612]"} onClick={() => setExpendedRow((prev) => prev === i ? null : i)}>
                                <td className="text-left sm:pl-5 pl-4 pr-2">
                                    <p className="py-3 sm:text-sm text-[12px]">
                                        {new Date(v.CreatedOn).toLocaleDateString()}
                                    </p>
                                </td>
                                <td className="text-left sm:pl-5 pl-4 pr-2">
                                    <p className="py-3 sm:text-sm text-[12px]">
                                        {Math.round(v.Duration)}m
                                    </p>
                                </td>
                                <td className='text-left'>
                                    <p className="py-3 pr-2 sm:text-sm text-[14px]">
                                        {v.Score.HomeScore} - {v.Score.AwayScore}
                                    </p>
                                </td>
                                <td>
                                    <p className={`py-3 px-2 sm:text-sm text-[14px] ${v.Result.toLocaleLowerCase() === "w" ? "text-[#00dd00]" : "text-[#ff0000]"}`}>
                                        {v.Result}
                                    </p>
                                </td>
                                <td className="sm:px-4 px-2 text-left">
                                    <p className="py-3 sm:text-sm text-[13px] flex justify-">
                                        <span role="button"
                                            className="inline-block ml-auto ms:pr-0 pr-2 select-none cursor-pointer">
                                            {expendedRow?.toString() && expendedRow === i ?
                                                <FaChevronUp className="text-gray-300 text-[12px] opacity-70" />
                                                : <FaChevronDown className="text-gray-300 text-[12px] opacity-70" />
                                            }
                                        </span>
                                    </p>
                                </td>
                            </tr>
                            {expendedRow?.toString() && expendedRow === i ?
                                <tr className="bg-[#011612] animate-fadein">
                                    <td colSpan={5}>
                                        <div className="flex w-full mt-2 py-2 px-3">
                                            <div className="w-1/2 sm:px-7 px-5">
                                                <p>
                                                    Home
                                                    <span className={`font-medium px-2 ${games[i].IsHomeWin ? 'text-[#00dd00]' : 'text-[#ff0000]'}`}>{games[i].IsHomeWin ? "W" : "L"}</span>
                                                </p>
                                                <table className="table-auto mx-auto w-full mt-3 sm:text-sm text-[14px]">
                                                    <tbody>
                                                        {
                                                            games[i].playerDetails.Home.map(x => {
                                                                return (
                                                                    <tr>
                                                                        <td className="py-1 text-left">
                                                                            {x.Name}
                                                                        </td>
                                                                        <td className="py-1 text-right">
                                                                            {x.IsGuest ? "-" : formatRating(x.Rating)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="h w-[2px] bg-white opacity-60"></div>
                                            <div className="w-1/2 sm:px-7 px-5">
                                                <p>
                                                    Away
                                                    <span className={`font-medium px-2 ${games[i].IsHomeWin ? 'text-[#ff0000]' : 'text-[#00dd00]'}`}>{games[i].IsHomeWin ? "L" : "W"}</span>

                                                </p>
                                                <table className="table-auto mx-auto w-full mt-3 sm:text-sm text-[14px]">
                                                    <tbody>
                                                        {
                                                            games[i].playerDetails.Away.map(x => {
                                                                return (
                                                                    <tr>
                                                                        <td className="py-1 text-left">
                                                                            {x.Name}
                                                                        </td>
                                                                        <td className="py-1 text-right">
                                                                            {x.IsGuest ? "-" : formatRating(x.Rating)}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                : null}
                        </React.Fragment>
                    ))}
                    {games.length == 0 && !isLoading && <tr><td colSpan="4" className="mb-5 mt-5">No games found</td></tr>}
                    { loadMore && <tr><td colSpan="6">
                        <button onClick={handleLoadMore}
                            className="mb-5 mt-5 rounded-3xl bg-[#ff8200] md:py-1 px-14 text-base font-medium uppercase text-white sm:text-2xl py-3"
                        >
                            SEE MORE
                        </button>
                    </td></tr>}
                </tbody>
            </table>
        </>
    )
}

export default GameRecords