import { Fragment } from "react";
import { ReactComponent as XRedIcon } from "../../assets/images/game/SVG/x.svg";
import { useDispatch } from "react-redux";
import { setActivityBar } from "../../store/game/gameSlice";
import { useLiveQuery } from "dexie-react-hooks";
import { db }  from '../../libs/db';
const tableData = [
  { time: "00:00:05", action: "John Appleseed was added to the Players Lounge" },
  { time: "00:15:03", action: "John Smith was added to the Players Lounge" },
];

const ROW_HEIGHT = "h-14";

const ActivityLog = ({ isOpen }) => {
  const dispatch = useDispatch();
  const logs = useLiveQuery(() => db.activity.reverse().toArray(), [], []);

  const patternBg = Array.from({ length: 15 }, (_, i) => i);
  const handleDate = (date) => {
    let d = new Date(date);
    return `${d.toLocaleTimeString()}`;
  }
  return (
    <div className={`fixed inset-0 z-[999] text-black ${isOpen ? "rest-body-all-scroll" : "pointer-events-none"}`}>
      <div
        className={`absolute inset-0 z-10 bg-black transition-all duration-300 ${isOpen ? "bg-opacity-25" : "pointer-events-none bg-opacity-0"
          }`}
        onClick={() => dispatch(setActivityBar(false))}
      />
      <div
        className={`overflow-y-auto max-h-screen relative z-20 ml-auto flex min-h-full w-[450px] flex-col overflow-y-auto rounded-tl-2xl rounded-bl-2xl bg-white text-[13px] font-medium transition-all duration-300 ${isOpen ? "translate-x-0" : "translate-x-[450px]"
          }`}
      >
        <div className="border-b-2 border-[#eaeaea]">
          <div className="flex items-center justify-between px-6 pt-8 pb-6 ">
            <h3 className="text-3xl font-medium">Activity Log</h3>
            <XRedIcon className="aspect-square w-5 cursor-pointer" onClick={() => dispatch(setActivityBar(false))} />
          </div>
        </div>
        <div className="relative isolate grid flex-grow grid-cols-[100px_1fr] content-start">
          {/* border */}
          <div className="absolute left-0 top-0 bottom-0 z-10 w-[100px] border-r-2 border-[#eaeaea]" />
          <div className="absolute inset-0 -z-10">
            {patternBg.map((i) => (
              <div key={i} className={`${ROW_HEIGHT} ${i % 2 === 0 ? "" : "bg-[#ededed]"}`} />
            ))}
          </div>
          {/* odd */}
          <div className={`flex ${ROW_HEIGHT} items-center justify-center text-center`}>
            <h3 className="py-2 text-lg font-semibold">Time</h3>
          </div>
          {/* even */}
          <div className={`flex ${ROW_HEIGHT} items-center justify-start pl-5 text-center`}>
            <h3 className="py-2 text-lg font-semibold">Actions</h3>
          </div>
          {logs.map((data, i) => (
            <Fragment key={i}>
              <div className={`flex ${ROW_HEIGHT} items-center justify-center text-center`}>{handleDate(data.time)}</div>
              <div className={`flex ${ROW_HEIGHT} items-center justify-start pl-5 pr-2`}>{data.action}</div>
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
