import React from "react";

const PrivacyPolicy = () => {

    const privacyPolicy = [
        {
            title: "Information We Collect 1.1. ",
            text: "Personal Information: We may collect personal information, such as your full name and email address, when you voluntarily provide it to us through the Website's registration or contact forms. 1.2. Non-Personal Information: We may also collect non-personal information, such as your IP address, browser type, device information, and usage data, through cookies and similar technologies as you interact with the Website."
        },
        {
            title: "Use of Information 2.1. ",
            text: "Personal Information: We use the personal information you provide to us for the following purposes: a) To provide and improve the services offered on the Website. b) To respond to your inquiries and communicate with you regarding your use of the Website. c) To send you promotional materials and updates about DreamHoops.com, provided you have given your explicit consent. 2.2. Non-Personal Information: We use non-personal information to analyze trends, administer the Website, track users' movements, and gather demographic information for aggregate use."
        },
        {
            title: "Personal Information: ",
            text: "We use the personal information you provide to us for the following purposes: a) To provide and improve the services offered on the Website. b) To respond to your inquiries and communicate with you regarding your use of the Website. c) To send you promotional materials and updates about DreamHoops.com, provided you have given your explicit consent. 2.2. Non-Personal Information: We use non-personal information to analyze trends, administer the Website, track users' movements, and gather demographic information for aggregate use."
        },
        {
            title: "Data Security 3.1. ",
            text: "We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the Internet or electronic storage system is 100% secure. Therefore, we cannot guarantee the absolute security of your personal information. 3.2. If we become aware of a data breach that compromises the security of your personal information, we will promptly notify you and take necessary steps to mitigate the breach."
        },
        {
            title: "Disclosure of Information 4.1. ",
            text: "We do not sell, rent, or share your personal information with third parties for their marketing purposes without your explicit consent. 4.2. We may share your personal information with trusted service providers who assist us in operating the Website and providing services to you. These service providers are contractually obligated to keep your information confidential and are prohibited from using it for any other purpose. 4.3. We may disclose your personal information if required to do so by law or in good faith belief that such action is necessary to comply with legal obligations or protect the rights, property, or safety of DreamHoops.com, its users, or others."
        },
        {
            title: "Third-Party ",
            text: "Links The Website may contain links to third-party websites or services that are not operated or controlled by DreamHoops.com. We are not responsible for the privacy practices or content of such third-party websites. We encourage you to review the privacy policies of those websites before providing any personal information."
        },
        {
            title: "Changes to this Policy ",
            text: "We reserve the right to modify or update this Policy at any time without prior notice. The updated Policy will be effective immediately upon posting on the Website. It is your responsibility to review this Policy periodically for any changes. Continued use of the Website after any modifications signify your acceptance of the updated Policy."
        },
        {
            title: "Contact Us ",
            text: "If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at info@dreamhoops.com."
        }

    ]

    return (
        <div className="p-4 sm:p-8 bg-white padding-top ">
            <div className="mb-12">
                <h1 className="font-bold mb-3 text-xl sm:text-3xl text-[#00352e]">DreamHoops.com Privacy Policy</h1>
                <p className="w-[95%] md:w-[80%] text-sm sm:text-base text-[#000]">
                    This Privacy Policy ("Policy") describes how DreamHoops.com ("we," "us," or "our") collects, uses, and
                    safeguards the personal information provided by users ("you" or "your") of the DreamHoops.com website
                    ("Website"). By accessing or using the Website, you consent to the collection and use of your personal
                    information as outlined in this Policy.
                </p>
            </div>
            <div>
                {
                    privacyPolicy.map((item) => (
                        <div className="flex flex-col mb-8">
                            <h3 className="font-bold mb-1 text-base sm:text-xl text-[#00352e]">{item.title}</h3>
                            <p className="w-[90%] md:w-[80%] text-sm sm:text-base text-[#000]">{item.text}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default PrivacyPolicy;
