import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";

// ------- Layout with header ----------
const WithHeader = () => {
  return (
    <main id="main-app-wrapper-" className="fill-available-height flex flex-col bg-primary text-white">
      <Header />
      <Outlet />
      <Footer />
    </main>
  );
};

export default WithHeader;
