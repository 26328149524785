import { useState } from "react";
import {  useSelector } from "react-redux";
import { themes } from "./theme";

const ControlledCounter = ({ className, color, count, setCount, side }) => {
  const { user } = useSelector((store) => store.auth);
  const bgNumber = themes[user.theme].footer.footBody.bgGrey;

  return (
    <div className="flex items-center justify-between gap-4 px-1 pt-6 pb-8 text-4xl font-semibold text-black">
      <button
        className={`flex aspect-square h-9 w-9 items-center justify-center rounded-full p-4 ${color ? color : `${bgNumber}`
          }`}
        onClick={() => setCount(side, count <= 10 ? (Number(count) <= 0 ? "00" : `0${--count}`) : --count)}
      >
        -
      </button>
      <div
        className={`flex-grow rounded-3xl ${bgNumber} py-2 px-8 text-5xl lg:text-8xl xl:px-10 ${className ? className : ""
          }`}
      >
        {count}
      </div>
      <button
        className={`flex aspect-square h-9 w-9 items-center justify-center rounded-full p-4 ${color ? color : `${bgNumber}`
          }`}
        onClick={() => setCount(side, count < 9 ? `0${++count}` : ++count)}
      >
        +
      </button>
    </div>
  );
};
export default ControlledCounter;
