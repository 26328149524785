import { themes } from "./theme";
 import { useSelector } from "react-redux";

const GameCardPlaceholder = ({
  team,
  index,
  handlePlayerDrop
}) => {
  const { user } = useSelector((store) => store.auth);
const userPlusIcon = themes[user.theme].card.cardIcons;

  const drop = e => {
    e.preventDefault();
    const player = JSON.parse(e.dataTransfer.getData('player'));
    handlePlayerDrop({ ...player, PlayerIndex: index, Team: team });
  }

  return (
    <div
      onDrop={drop}
      onDragOver={(e) => { e.preventDefault();}}
      className={`relative flex items-baseline justify-between rounded-full py-4 pr-5 text-black xl:min-w-[330px] bg-[#ffffff] bg-opacity-50`}
    >
      <div className="absolute top-0 bottom-0 aspect-square overflow-hidden rounded-full p-2 shadow-black/40 [box-shadow:0_0_10px_rgb(0_0_0_/_0.5)]">
        <img src={userPlusIcon} alt="icon" className="h-full w-full object-contain" />
      </div>
      <p className="pl-[75px] font-bold">Drag & Drop Player</p>
    </div>
  );
};
export default GameCardPlaceholder;
