import { Reorder, motion, useMotionValue } from "framer-motion";
import { useState } from "react";
import { useRaisedShadow } from "../../utils/useRelativeShadow";
import { useDispatch } from "react-redux";
import {  useSelector } from "react-redux";
import { themes } from "./theme";


const DragPlayerCard = ({
  card,
  setRootDragging,
  index,
  lastIndex,
  queue,
  handleReorder,
  dragItem,
  dragOverItem,
  isListHover
}) => {
  const { user } = useSelector((store) => store.auth);
  const BgUserCard = themes[user.theme].lounge.userBg;

  const [isDragging, setIsDragging] = useState(false);
  const dispatch = useDispatch();

  const onDragEnd = (e) => {
    e.target.style.opacity = '1';
    setIsDragging(false);
    dispatch(setRootDragging(false));
  };
  const onDrop = (e) => {
    console.log('ondrop');
    console.log(dragItem.current);
    console.log(dragOverItem.current);
    if (dragItem.current == dragOverItem.current) return;
    if (dragOverItem.current == null || dragOverItem.current == undefined) return;
    if (dragItem.current != dragOverItem.current && isListHover) {
      const cardData = queue[dragItem.current];
      handleReorder({ NewPosition: dragOverItem.current, OldPosition: dragItem.current, UserId: cardData.User.Id });
      dragItem.current = null;
      dragOverItem.current = null;
    }
  }
  function diffMinutes(queueTime) {
    const d1 = new Date(queueTime).getTime();
    const d2 = new Date().getTime();
    const min = Math.round((d2 - d1) / 60000); // Can use Math.floor or Math.ceil depends up to you
    if (min > 99) {
      return (min / 60).toFixed(1) + 'h';
    }
    return min + 'm';
  }
  let groupPlayer;
  if (!isDragging) groupPlayer = (index + 1) % 5 === 0 && !lastIndex;
  else groupPlayer = null;

  const time = diffMinutes(card.QueueStartTime);
  return (
    <>
      <div style={{width:'15.5vw'}} className={`grid grid-cols-[1fr_31px] items-center justify-between gap-4 py-[5px]  ${isDragging ? "cursor-grabbing" : "cursor-grab"}`}>
        <div style={{width:'14.7vw',overflow:'hidden'}} className={`font-size flex flex-grow gap-2 rounded-full ${BgUserCard} px-4 py-2  text-black`}
          draggable
          onDragStart={(e) => {
            e.target.style.opacity = '0.4';
            console.log('onDragStart called');
            e.dataTransfer.setData('player', JSON.stringify(card));
            dragItem.current = index;
            setIsDragging(true);
            dispatch(setRootDragging(true));
          }}
          onDragEnter={(e) => {
            console.log('onDragEnter called');
            dragOverItem.current = index;
          }}
          onDrop={onDrop}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragEnd={onDragEnd}
          id={card.User.Id}
        >
          <p className="font-medium">{card.User.Name}</p>
          {!card.IsGuest && <span className="text-[#6d6e71]">- {card.User.GroupName}</span>}
        </div>
        {/* <p className={`text-sm`}>{diffMinutes(card.QueueStartTime)}</p> */}
      </div>
      {!isDragging && groupPlayer && <div style={{width:'88%'}} className="my-1 h-[4px] bg-[#ffffffa1]" />}
    </>
  );
};

// const PlayerCard = ({ User, QueueStartTime, isDragging }) => {
//   function diffMinutes() {
//     const d1 = new Date(QueueStartTime).getTime();
//     const d2 = new Date().getTime();
//     const min = Math.round((d2 - d1) / 60000); // Can use Math.floor or Math.ceil depends up to you
//     if (min > 99) {
//       return (min / 60).toFixed(1) + 'h';
//     }
//     return min + 'm';
//   }
//   const time = diffMinutes();
//   return (

//   );
// };

export default DragPlayerCard;
