import { Link } from "react-router-dom";
import basketBall from "../../assets/images/ball_bg.png";

// ---- Hero section form home page -----------
const Hero = () => {
  return (
    <section className="relative min-h-[unset] overflow-visible bg-white md:overflow-hidden padding-top  ">
      <div className="container mx-auto max-w-[1400px]">
        <div className="flex items-center py-[35px] px-6 sm:py-[120px] lg:px-8   ">
          <div className="z-[2] flex-1">
            <h2 className="my-4 whitespace-nowrap text-3xl font-semibold text-[#191919] sm:text-3xl md:text-4xl lg:text-6xl">
              Pick up Basketball.
              <br />
              Revolutionized.
            </h2>
            <Link
              to="/register"
              className="mt-8 block w-max rounded-full border border-solid border-orange-500 py-3 px-6 text-[17px] font-semibold text-orange-500 no-underline hover:bg-orange-500 hover:text-white sm:text-[30px]"
            >
              Join Dreamhoops
            </Link>
          </div>
          <div className="absolute  right-[0px] w-[120px] md:right-0 md:bottom-[-120px] md:w-[34%]  bottom-0 2xl:w-[24%]">
            <img className="right-0 z-[-1] w-full object-contain" src={basketBall} alt="Basketball image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
