import { createSlice } from "@reduxjs/toolkit";
import {
  addTeamPlayer,
  endGame,
  getCurrentLocationGame,
  getCurrentLounge,
  joinAsGuestLounge,
  joinLounge,
  leaveGuestPlayer,
  leavePlayer,
  removeTeamPlayer,
  startGame,
  updatePlayerPosition,
  updateScore,
  updateStatus,
} from "./gameAPI";
import { toast } from "../../utils/customToast";
import { TEAMS } from "../../utils/constants";
import { GAME_STATUS } from "../../pages/Game/constant";
import { pad } from "../../utils/helpers";
import { db } from "../../libs/db";
const initialState = {
  error: "",
  success: false,
  isLoading: false,
  loadingText: "",
  lounge: {
    Queue: [],
    Home: [
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
    ],
    Away: [
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
      { User: { Id: "", Name: "" } },
    ],
    LocationId: "",
    GameStatus: 1,
    IsGameLive: false,
    Id: "",
  },
  needIntro: false,
  activityBar: false,
  rmvSelectedPlayer: false,
  xChangePlayer: false,
  isDragging: false,
  guestDialog: false,
  rmvPlayerDialog: false,
  endGameDialog: false,
  isGameStart: false,
  isCountDownStarted: false,
  startBtnProgress: 0,
  startedTime: null,
  confirmScore: false,
  isSub: false,
  winTeam: false,
  homeCount: "00",
  awayCount: "00",
  qrcode: "",
  gameError: { warn: false, error: false },
  guestPlayerName: "",
  positionError: false,
  currentGame: {
    is_live: false,
    game: {},
  },
  startBtnMouseUp: true,
  gameMode: false,
};
export const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    setSuccess: (state, action) => {
      state.success = false;
      state.error = "";
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.success = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
      state.error = "";
    },
    setNeedIntro: (state, action) => {
      console.log(action.payload);
      state.needIntro = action.payload;
    },
    setActivityBar: (state, action) => {
      state.activityBar = action.payload;
    },
    setLoungePlayer: (state, action) => {
      state.lounge.Queue = action.payload;
    },
    setTeam1State: (state, action) => {
      state.lounge.Home = action.payload;
    },
    setTeam2State: (state, action) => {
      state.lounge.Away = action.payload;
    },
    setRmvSelectedPlayer: (state, action) => {
      state.rmvSelectedPlayer = action.payload;
    },
    setXChangePlayer: (state, action) => {
      state.xChangePlayer = action.payload;
    },
    setIsDragging: (state, action) => {
      state.isDragging = action.payload;
    },
    setGuestDialog: (state, action) => {
      state.guestDialog = action.payload;
      state.guestPlayerName = "";
    },
    setRmvPlayerDialog: (state, action) => {
      state.rmvPlayerDialog = action.payload;
    },
    setEndGameDialog: (state, action) => {
      state.endGameDialog = action.payload;
    },
    setGameError: (state, action) => {
      state.gameError = action.payload;
    },
    setIsGameStart: (state, action) => {
      state.isGameStart = action.payload;
    },
    setIsCountDownStarted: (state, action) => {
      state.isCountDownStarted = action.payload;
    },
    setStartBtnMouseUp: (state, action) => {
      state.startBtnMouseUp = action.payload;
    },
    setStartBtnProgress: (state, action) => {
      state.startBtnProgress = action.payload;
    },
    setStartedTime: (state, action) => {
      state.startedTime = action.payload;
    },
    setConfirmScore: (state, action) => {
      state.confirmScore = action.payload;
    },
    setIsSub: (state, action) => {
      state.isSub = action.payload;
    },
    setWinTeam: (state, action) => {
      state.winTeam = action.payload;
    },
    setHomeCount: (state, action) => {
      state.homeCount = action.payload;
    },
    setAwayCount: (state, action) => {
      state.awayCount = action.payload;
    },
    setQRCode: (state, action) => {
      state.qrcode = action.payload;
    },
    setGuestPlayerName: (state, action) => {
      state.guestPlayerName = action.payload;
    },
    setPositionError: (state, action) => {
      state.positionError = action.payload;
    },
    addPlayerInLounge: (state, action) => {
      state.lounge.Queue.push(action.payload);
    },
    setGameMode: (state, action) => {
      state.gameMode = action.payload;
    },
  },
  extraReducers: {
    [joinLounge.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.success = false;
    },
    [joinLounge.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload || "Network Error";
    },
    [joinLounge.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.success = "Please check the Player’s Lounge.";
    },
    [joinAsGuestLounge.pending]: (state) => {
      state.isLoading = true;
      state.loadingText = "Joining as guest,";
      state.error = "";
      state.success = false;
    },
    [joinAsGuestLounge.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      toast.error(action.payload || "Network Error");
    },
    [joinAsGuestLounge.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.error = "";
      const player = action.payload;
      state.lounge.Queue.push(player);
      state.guestDialog = false;
      state.guestplayerName = "";
      db.activity.add({ time: new Date().toISOString(), action: `${player.User.Name} (guest) joined the lounge` });
    },
    [getCurrentLounge.pending]: (state) => {
      state.isLoading = true;
      state.loadingText = "Loading player lounge,";
      state.error = "";
      state.success = false;
    },
    [getCurrentLounge.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.success = false;
      state.error = action.payload || "Network Error";
    },
    [getCurrentLounge.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.error = "";
      const { Queue, Away, Home, Id } = action.payload;
      state.lounge.Id = Id;
      state.lounge.Queue = Queue;
      for (let i = 0; i < Home.length; i++) {
        const element = Home[i];
        state.lounge.Home[element.Position] = element;
      }
      for (let i = 0; i < Away.length; i++) {
        const element = Away[i];
        state.lounge.Away[element.Position] = element;
      }
    },
    [leavePlayer.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.loadingText = "Removing player,";
    },
    [leavePlayer.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.rmvSelectedPlayer = false;
      state.rmvPlayerDialog = false;
      state.error = action.payload || "Network Error";
      toast.error(state.error);
    },
    [leavePlayer.fulfilled]: (state, action) => {
      db.activity.add({
        time: new Date().toISOString(),
        action: `${state.rmvSelectedPlayer.User.Name} left the lounge`,
      });
      state.error = "";
      state.lounge.Queue = state.lounge.Queue.filter((ca) => ca.User.Id !== state.rmvSelectedPlayer.User.Id);
      state.isLoading = false;
      state.loadingText = "";
      state.rmvSelectedPlayer = false;
      state.rmvPlayerDialog = false;
    },
    [leaveGuestPlayer.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.loadingText = "Removing player,";
    },
    [leaveGuestPlayer.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.rmvSelectedPlayer = false;
      state.rmvPlayerDialog = false;
      state.error = action.payload || "Network Error";
      toast.error(state.error);
    },
    [leaveGuestPlayer.fulfilled]: (state, action) => {
      db.activity.add({
        time: new Date().toISOString(),
        action: `${state.rmvSelectedPlayer.User.Name} (guest) left the lounge`,
      });
      state.error = "";
      state.lounge.Queue = state.lounge.Queue.filter((ca) => ca.User.Id !== state.rmvSelectedPlayer.User.Id);
      state.isLoading = false;
      state.loadingText = "";
      state.rmvSelectedPlayer = false;
      state.rmvPlayerDialog = false;
    },
    [updatePlayerPosition.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.loadingText = "Updating players,";
    },
    [updatePlayerPosition.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.error = action.payload || "Network Error";
      state.positionError = true;
      toast.error(state.error);
    },
    [updatePlayerPosition.fulfilled]: (state, action) => {
      state.error = "";
      state.positionError = false;
      const { user, position } = action.payload;
      const index = state.lounge.Queue.findIndex((x) => x.User.Id == user.User.Id);
      state.lounge.Queue.splice(index, 1);
      state.lounge.Queue.splice(position, 0, user);
      state.isLoading = false;
      state.loadingText = "";
      db.activity.add({ time: new Date().toISOString(), action: `${user.User.Name} position updated in lounge` });
    },
    [addTeamPlayer.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.loadingText = "Adding player,";
    },
    [addTeamPlayer.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.error = action.payload || "Network Error";
      state.positionError = true;
      state.xChangePlayer = false;
      toast.error(state.error);
    },
    [addTeamPlayer.fulfilled]: (state, action) => {
      state.error = "";
      state.positionError = false;
      const { user, team } = action.payload;
      const key = team == TEAMS.Home ? "Home" : "Away";
      const index = state.lounge.Queue.findIndex((x) => x.User.Id == user.User.Id);
      state.lounge.Queue.splice(index, 1);
      state.lounge[key][user.Position] = user;
      state.isLoading = false;
      state.loadingText = "";
      state.xChangePlayer = false;
      state.isDragging = false;
      db.activity.add({ time: new Date().toISOString(), action: `${user.User.Name} added to ${key} team` });
    },
    [removeTeamPlayer.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.loadingText = "Removing player,";
    },
    [removeTeamPlayer.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.error = action.payload || "Network Error";
      state.positionError = true;
      state.xChangePlayer = false;
      toast.error(state.error);
    },
    [removeTeamPlayer.fulfilled]: (state, action) => {
      state.error = "";
      state.positionError = false;
      const { user, team, isSub } = action.payload;
      const key = team == TEAMS.Home ? "Home" : "Away";
      const index = state.lounge[key].findIndex((x) => x.User.Id == user.User.Id);
      state.lounge[key][index] = { User: { Id: "", Name: "" } };
      if (isSub) {
        state.lounge.Queue.splice(0, 0, user);
      } else {
        state.lounge.Queue.push(user);
      }
      state.isLoading = false;
      state.loadingText = "";
      state.xChangePlayer = false;
      db.activity.add({ time: new Date().toISOString(), action: `${user.User.Name} removed from ${key} team` });
    },
    [startGame.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.loadingText = "Starting game,";
    },
    [startGame.rejected]: (state, action) => {
      state.isLoading = false;
      state.loadingText = "";
      state.error = action.payload || "Network Error";
      toast.error(state.error);
    },
    [startGame.fulfilled]: (state, action) => {
      state.error = "";
      state.positionError = false;
      state.currentGame.is_live = true;
      state.currentGame.game = action.payload;
      state.isGameStart = true;
      state.startedTime = new Date();
      state.isLoading = false;
      state.loadingText = "";
      state.startBtnMouseUp = false;
      db.activity.add({ time: new Date().toISOString(), action: "New game started" });
    },
    [getCurrentLocationGame.fulfilled]: (state, action) => {
      if (action.payload) {
        state.currentGame.is_live = true;
        state.currentGame.game = action.payload;
        state.isGameStart = true;
        state.startedTime = new Date(action.payload.CreatedOn);
        state.homeCount = pad(action.payload.Score.HomeScore, 2);
        state.awayCount = pad(action.payload.Score.AwayScore, 2);
        const status = action.payload.GameStatus;
        if (status == GAME_STATUS.Paused) {
          state.isSub = true;
        }
      } else {
        state.currentGame.is_live = false;
        state.currentGame.game = action.payload;
        state.isGameStart = false;
        state.startedTime = null;
      }
    },
    [updateScore.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [updateScore.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload || "Network Error";
      toast.error(state.error);
    },
    [updateScore.fulfilled]: (state, action) => {
      state.error = "";
      state.isLoading = false;
      state.loadingText = "";
      const { Side, Score } = action.payload;
      if (Side == TEAMS.Home) {
        state.homeCount = pad(Score, 2);
      } else {
        state.awayCount = pad(Score, 2);
      }
    },
    [updateStatus.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
    },
    [updateStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload || "Network Error";
      toast.error(state.error);
    },
    [updateStatus.fulfilled]: (state, action) => {
      state.error = "";
      state.isLoading = false;
      state.loadingText = "";
      state.gameError = { error: false, warn: false };
      if (action.payload.Status == GAME_STATUS.Paused) {
        state.isSub = true;
      } else {
        state.isSub = false;
      }
      db.activity.add({ time: new Date().toISOString(), action: "Game status updated" });
    },
    [endGame.pending]: (state) => {
      state.isLoading = true;
      state.error = "";
      state.loadingText = "Ending game, ";
    },
    [endGame.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload || "Network Error";
      state.loadingText = "";
      toast.error(state.error);
    },
    [endGame.fulfilled]: (state, action) => {
      state.error = "";
      state.isLoading = false;
      state.loadingText = "";
      state.currentGame.is_live = false;
      state.currentGame.game = {};
      state.isGameStart = false;
      state.startedTime = null;
      state.isSub = false;
      state.homeCount = "00";
      state.awayCount = "00";
      state.endGameDialog = false;
      let { Team, Result: { Home, Away, Queue } } = action.payload;
      let away = [...initialState.lounge.Away];
      let home = [...initialState.lounge.Home];

      for (let i = 0; i < away.length; i++) {
        let a = Away.find(x => x.Position == i);
        if (a) {
          away[i] = a;
        }
      }
      for (let i = 0; i < home.length; i++) {
        let h = Home.find(x => x.Position == i);
        if (h) {
          home[i] = h;
        }
      }

      state.lounge.Queue = Queue;
      state.lounge.Away = away;
      state.lounge.Home = home;



      // const key = Team == TEAMS.Home ? "Away" : "Home";
      // let away = initialState.lounge.Away;
      // let home = initialState.lounge.Home;
      // for (let i = 0; i < queue.length; i++) {
      //   queue[i].Position = i;
      //   state.lounge.Away[i] = queue[i];
      // }


      // let list = [];
      // for (let i = 0; i < state.lounge[key].length; i++) {
      //   let user = { ...state.lounge[key][i] };
      //   if (user.User.Id) {
      //     user.Position = 0;
      //     user.QueueStartTime = new Date().toISOString();
      //     list.push(user);
      //     state.lounge[key][i] = { User: { Id: "", Name: "" } };
      //   }
      // }
      // if (Team == TEAMS.Away) {
      //   state.lounge.Home = [...state.lounge.Away];
      //   state.lounge.Away = [...initialState.lounge.Away];
      // }
      // var queue = state.lounge.Queue.splice(0, 5);
      // for (let i = 0; i < queue.length; i++) {
      //   queue[i].Position = i;
      //   state.lounge.Away[i] = queue[i];
      // }
      // state.lounge.Queue = [...state.lounge.Queue, ...list];
      db.activity.add({ time: new Date().toISOString(), action: "Game ended" });
    },
  },
});
export const {
  setSuccess,
  setLoading,
  setNeedIntro,
  setActivityBar,
  setLoungePlayer,
  setTeam1State,
  setTeam2State,
  setRmvSelectedPlayer,
  setXChangePlayer,
  setIsDragging,
  setGuestDialog,
  setRmvPlayerDialog,
  setEndGameDialog,
  setError,
  setGameError,
  setIsGameStart,
  setIsCountDownStarted,
  setStartBtnMouseUp,
  setStartBtnProgress,
  setStartedTime,
  setConfirmScore,
  setIsSub,
  setWinTeam,
  setHomeCount,
  setAwayCount,
  setQRCode,
  setGuestPlayerName,
  setPositionError,
  addPlayerInLounge,
  setGameMode,
} = gameSlice.actions;

export default gameSlice.reducer;
