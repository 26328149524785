

import signupSuccess from '../../assets/images/signupSuccess.png'

// ------------------ Success page for Sign up/Register ---------------
const RegisterSuccess = () => {
  
  return (
    <section className="h-full sm:px-5 w-full sm:flex flex-1 items-center justify-center">
      <div className="mx-auto text-center my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
        <h3 className="mb-3 text-3xl font-black text-white sm:text-4xl text-center">
          dreamhoops
        </h3>
        <img className="w-full max-w-[260px] my-2 mx-auto" src={signupSuccess} alt="" />
        <p className="text-center text-white pb-6">
          Registration complete.
          <br />
          A verification email has just been sent to your email address. 
          <br/>
          Please follow the instructions in the email to complete your player profile.
        </p>
      </div>
    </section>
  )
}

export default RegisterSuccess