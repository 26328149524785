import { Reorder, motion } from "framer-motion";
import shuffleArr from "lodash.shuffle";
import Joyride from "react-joyride";
import warn from "../../assets/images/game/SVG/Asset 15.svg";
import { ReactComponent as TrashIcon } from "../../assets/images/game/SVG/trash.svg";
// import bg from "../../assets/images/game/bg.png";
// import info from "../../assets/images/game/info.png";
import person from "../../assets/images/game/png/person.png";
import shuffle from "../../assets/images/game/shuffle.png";
import time from "../../assets/images/game/time.png";
import MyDialog from "../../components/MyDialog";
import Counter from "../../utils/Counter/Counter";
import ActivityLog from "./ActivityLog";
import ControlledCounter from "./ControlledCounter";
import DesktopOnly from "./DesktopOnly";
import DragPlayerCard from "./DragPlayerCard";
import GameCard from "./GameCard";
import GameCardPlaceholder from "./GameCardPlaceholder";
import Tooltip from "./Tooltip";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import {
  addTeamPlayer,
  endGame,
  getCurrentLocationGame,
  getCurrentLounge,
  joinAsGuestLounge,
  leaveGuestPlayer,
  leavePlayer,
  removeTeamPlayer,
  startGame,
  updatePlayerPosition,
  updateScore,
  updateStatus,
} from "../../store/game/gameAPI";
import { BsCheckLg } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { STATUS } from "react-joyride";
import { themes } from "./theme";
const DESKTOP_WIDTH = 899;
export const WIN = {
  home: "home",
  away: "away",
};
import {
  setNeedIntro,
  setActivityBar,
  setRmvSelectedPlayer,
  setXChangePlayer,
  setIsDragging,
  setGuestDialog,
  setRmvPlayerDialog,
  setEndGameDialog,
  setGameError,
  setStartBtnMouseUp,
  setStartBtnProgress,
  setConfirmScore,
  setIsSub,
  setWinTeam,
  setQRCode,
  setGuestPlayerName,
  setGameMode,
  setIsCountDownStarted,
} from "../../store/game/gameSlice";
import useIsHover from "../../utils/useIsHover";
import { useRef, useEffect } from "react";
import Spinner from "../../utils/Spinner";
import { useState } from "react";
import { toast } from "../../utils/customToast";
import { TEAMS } from "../../utils/constants";
import { GAME_STATUS } from "./constant";
import CountDown from "./CountDown";

const Game = () => {
  const timerRef = useRef(null);
  const { user } = useSelector((store) => store.auth);
  const {
    lounge,
    needIntro,
    activityBar,
    rmvSelectedPlayer,
    isDragging,
    guestDialog,
    rmvPlayerDialog,
    endGameDialog,
    error,
    isGameStart,
    startBtnMouseUp,
    startBtnProgress,
    startedTime,
    isSub,
    homeCount,
    awayCount,
    isLoading,
    loadingText,
    guestPlayerName,
    gameMode,
    gameError,
    currentGame: { game },
    isCountDownStarted,
  } = useSelector((store) => store.game);
  const dispatch = useDispatch();
  const { locationId } = useParams();
  const [submitted, setSubmitted] = useState();
  const [listRef, isListHover] = useIsHover();
  const [tiescore, setTieScore] = useState("");
  //theme
  const ButtonText = themes[user.theme].lounge.lobbyButton.text;
  const ButtonBgColor = themes[user.theme].lounge.lobbyButton.backgroundColor;
  const ButtonTextcolor = themes[user.theme].lounge.lobbyButton.color;
  const introSteps = themes[user.theme].tooltip.introSteps;
  const loungeHeading = themes[user.theme].lounge.playersHeading;
  const loungeBgColor = themes[user.theme].lounge.backgroundColor;
  const startBgColor = themes[user.theme].gameMode.startButton.backgroundColor;
  const startColor = themes[user.theme].gameMode.startButton.color;
  const bg = themes[user.theme].gameMode.backgroundImage;
  const siteName = themes[user.theme].site.siteName;
  const siteTextColor = themes[user.theme].site.siteColor;
  const info = themes[user.theme].tooltip.introStepsIcon;
  const diaButtonsBg = themes[user.theme].dialogs.buttons.backgroundColor;
  const diaButtonsColor = themes[user.theme].dialogs.buttons.color;
  const borderColor = themes[user.theme].dialogs.dialogEndGame.borderClr;
  const diaHomeAwayTextClr = themes[user.theme].dialogs.dialogEndGame.bordertext;
  const diaHomeAwayTextClrActive = themes[user.theme].dialogs.dialogEndGame.bordertextActive;
  const diaHomeAwayBg = themes[user.theme].dialogs.dialogEndGame.homeAwayBg;
  const resumeHover = themes[user.theme].gameMode.resumeButton.textClr;
  const resumetext = themes[user.theme].gameMode.resumeButton.text;
  const subtextClr = themes[user.theme].gameMode.subButtonClr;
  const textHome = themes[user.theme].footer.footHead.homeText;
  const TextAway = themes[user.theme].footer.footHead.awayText;
  const footHeadBg = themes[user.theme].footer.footHead.bgClr;
  const bgBody = themes[user.theme].footer.footBody.bodyBg;
  const bgLinefoot = themes[user.theme].footer.footBody.bgGrey;


  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://bernardo-castilho.github.io/DragDropTouch/DragDropTouch.js";
    script.id = "DragDropTouch";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const isFullTeamNotPresent = () => {
    const hCount = lounge.Home.filter((elem) => elem.User.Id != "").length;
    const aCount = lounge.Away.filter((elem) => elem.User.Id != "").length;

    if (hCount != aCount || hCount == 0 || aCount == 0) {
      dispatch(setGameError({ error: false, warn: true }));
      return true;
    }
    return false;
  };

  const handlePress = () => {
    console.log("press");
    if (isFullTeamNotPresent()) return;
    else dispatch(setGameError({ error: false, warn: false }));
    if (isGameStart) return;
    //prev
    // dispatch(setStartBtnProgress(0));
    // const ms = 1000;
    // const startTime = Date.now();

    // const interval = setInterval(() => {
    //   const elapsedTime = Date.now() - startTime;
    //   dispatch(setStartBtnProgress((elapsedTime / ms) * 100));
    //   if (elapsedTime >= ms) {
    //     dispatch(startGame({ LoungeId: lounge.Id }));
    //     clearInterval(interval);
    //   }
    // }, 50);

    // timerRef.current = interval;

    //new
    dispatch(setIsCountDownStarted(true));
  };

  // resume game
  const resumeGame = () => {
    if (isFullTeamNotPresent()) return;
    else dispatch(setGameError({ error: false, warn: false }));
    dispatch(updateStatus({ GameId: game.Id, Status: GAME_STATUS.Started, LocationId: locationId }));
  }

  // end game
  const endGameHandler = () => {
    if (isFullTeamNotPresent()) return;
    dispatch(setEndGameDialog(true));
    dispatch(setIsSub(false));
  };

  const handleRelease = () => {
    if (!startBtnMouseUp) return dispatch(setStartBtnMouseUp(true));
    if (isGameStart) {
      if (isFullTeamNotPresent()) return;
      dispatch(setEndGameDialog(true));
      dispatch(setIsSub(false));
      return;
    }
    if (startBtnProgress < 100 && !gameError.warn) {
      dispatch(setGameError({ error: true, warn: false }));
    }
    clearInterval(timerRef.current);
    timerRef.current = null;
    dispatch(setStartBtnProgress(0));
  };

  const handleRmvPlayer = () => {
    const params = { LocationId: locationId, UserId: rmvSelectedPlayer.User.Id, Name: rmvSelectedPlayer.User.Name };
    if (!rmvSelectedPlayer.IsGuest) {
      dispatch(leavePlayer(params));
    } else {
      dispatch(leaveGuestPlayer(params));
    }
  };

  const setWhoWin = (whoWin) => {
    if (!whoWin || whoWin === false) whoWin = homeCount >= awayCount ? WIN.home : WIN.away;
    dispatch(setWinTeam(whoWin));
    dispatch(setConfirmScore(true));
  };

  const onGameEnd = () => {
    if (homeCount === awayCount) {
      setTieScore("Please Enter the Final Score");
    }
    if (homeCount != awayCount) {
      setTieScore("");

      const timeInS = (new Date().getTime() - new Date(startedTime)) / 1000;
      const minutes = (timeInS / 60) % 60;
      dispatch(endGame({ GameId: game.Id, Duration: minutes.toFixed(2), LocationId: locationId }));
    }
  };

  const handleJoinGame = () => {
    if (guestPlayerName.trim() == "") {
      setSubmitted(true);
      return;
    }
    setSubmitted(false);
    if (lounge.Queue.find((x) => x.User.Name.toLowerCase() == guestPlayerName.toLowerCase())) {
      toast.info("Player with the same name already exists");
      return;
    }
    if (lounge.Away.find((x) => x.User.Name.toLowerCase() == guestPlayerName.toLowerCase())) {
      toast.info("Player with the same name already exists");
      return;
    }
    if (lounge.Home.find((x) => x.User.Name.toLowerCase() == guestPlayerName.toLowerCase())) {
      toast.info("Player with the same name already exists");
      return;
    }
    dispatch(joinAsGuestLounge({ LocationId: locationId, UserId: "", Name: guestPlayerName }));
  };

  useEffect(() => {
    if (window.innerWidth > DESKTOP_WIDTH) {
      dispatch(setNeedIntro(true));
    }
    dispatch(setQRCode(`${import.meta.env.VITE_PORTALURL}/login/${user.LocationId}`));
    dispatch(getCurrentLounge(locationId));
    dispatch(getCurrentLocationGame(locationId));
  }, []);

  // useEffect(() => {
  //   if (loungePlayer.find((cardArr) => cardArr.length === 0))
  //     setLoungePlayer((groupCard) => {
  //       return groupCard.filter((cardArr) => cardArr.length !== 0);
  //     });
  // }, [loungePlayer]);

  //joyride callback function

  const handleJoyrideCallback = (data) => {
    debugger;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(data.status)) {
      dispatch(setNeedIntro(false));
    }
  };
  const handleReorder = (data) => {
    console.log(data);
    dispatch(updatePlayerPosition({ LocationId: locationId, UserId: data.UserId, NewPosition: data.NewPosition }));
  };
  const handlePlayerDrop = (data) => {
    console.log(data);
    const key = data.Team == TEAMS.Home ? "Home" : "Away";
    const p = lounge[key].find((x) => x.User.Id == data.User.Id);
    if (p) return;
    if (isGameStart && !isSub) {
      return;
    }
    dispatch(
      addTeamPlayer({ Team: data.Team, LocationId: locationId, UserId: data.User.Id, PlayerPosition: data.PlayerIndex })
    );
  };
  const handlePlayerRemove = (data) => {
    if (isCountDownStarted) {
      toast.info("Cannot remove player now. Game is about to start");
      return;
    }
    dispatch(removeTeamPlayer({ Team: data.Team, LocationId: locationId, UserId: data.UserId, IsSub: data.IsSub }));
  };
  const handleScoreUpdate = (side, value) => {
    if (isGameStart) {
      setTieScore("")
      dispatch(updateScore({ Side: side, GameId: game.Id, Score: value, Location: locationId }));
    }
  };
  const handleSubstitue = () => {
    if (!isSub) {
      dispatch(updateStatus({ GameId: game.Id, Status: GAME_STATUS.Paused, LocationId: locationId }));
    }
    else {
      if (isFullTeamNotPresent()) return;
      else dispatch(setGameError({ error: false, warn: false }));
      dispatch(updateStatus({ GameId: game.Id, Status: GAME_STATUS.Started, LocationId: locationId }));
    }
  };
  const handleGameMode = () => {
    dispatch(setGameMode(!gameMode));
  };

  // Scroll table when we are dragging a row
  const handleDraggingForTable = (e) => {
    const container = e.target;
    const containerRect = container.getBoundingClientRect();
    const mouseY = e.clientY - containerRect.top;

    // Adjust these thresholds as needed
    const scrollUpThreshold = 150; // Pixels from the top
    const scrollDownThreshold = containerRect.height - 50; // Pixels from the bottom

    // Determine whether to scroll up, down, or stop scrolling
    if (mouseY < scrollUpThreshold) {
      container.scrollTop -= 5; // Scroll up
    } else if (mouseY > scrollDownThreshold) {
      container.scrollTop += 5; // Scroll down
    }
  };

  const handleTooltipOpen = () => {
    dispatch(setNeedIntro(true));
  }

  return (
    <>
      {isLoading && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Spinner />
          <p className="text-white ml-2">{loadingText} Please wait ...</p>
        </div>
      )}
      <div className="reset-body-color hidden bg-[#191919] pt-7 [@media(min-width:900px)]:block">
        <ActivityLog isOpen={activityBar} />
        <MyDialog
          isOpen={guestDialog}
          setIsOpen={(value) => dispatch(setGuestDialog(value))}
          title={"ENTER PLAYER’S LOUNGE"}
        >
          <div className="mx-auto my-4 px-9">
            <div className="flex w-full items-center justify-center gap-5 rounded-full bg-[#eaeaea] py-2 px-4">
              <img src={person} alt="person" className="w-7" />
              <input
                type="text"
                value={guestPlayerName}
                onChange={(e) => dispatch(setGuestPlayerName(e.target.value))}
                maxLength={18}
                className="flex-grow bg-transparent outline-none"
                placeholder="Player name"
              />
            </div>
          </div>
          {submitted && <p className="text-sm text-[#ff5700]">Name is required</p>}
          <button
            style={{ color: `${diaButtonsColor}` }}
            className={`mx-auto select-none my-5 rounded-full ${diaButtonsBg} px-12 py-2 text-2xl uppercase`}
            onClick={handleJoinGame}
          >
            Join
          </button>
        </MyDialog>
        <MyDialog
          isOpen={!!rmvSelectedPlayer && rmvPlayerDialog}
          setIsOpen={(value) => dispatch(setRmvPlayerDialog(value))}
          title={"Remove player"}
        >
          <div className="mx-auto my-4 px-9 text-xl">
            Are you sure you want to remove {rmvSelectedPlayer?.User?.Name}?
          </div>
          <div className="mx-auto flex max-w-sm items-center justify-center gap-4">
            <button
              className="mx-auto select-none my-5 flex-grow rounded-full bg-[#ededed] px-12 py-2 text-2xl uppercase"
              onClick={() => dispatch(setRmvPlayerDialog(false))}
            >
              Cancel
            </button>
            <button
              style={{ color: `${diaButtonsColor}` }}
              className={`mx-auto select-none my-5 flex-grow rounded-full ${diaButtonsBg} px-12 py-2 text-2xl uppercase`}
              onClick={handleRmvPlayer}
            >
              Remove
            </button>
          </div>
        </MyDialog>
        <MyDialog
          isOpen={endGameDialog}
          setIsOpen={(val) => dispatch(setEndGameDialog(val))}
          title={"SELECT THE WINNING TEAM"}
          rootClasses={"!max-w-2xl"}
        >
          <div className="flex justify-center px-2">
            <div className={`rounded-full w-full flex border-2 ${borderColor} mt-3`}>
              <button
                className={`w-[90%] select-none rounded-l-full ${homeCount > awayCount
                  ? `${diaHomeAwayBg} ${diaHomeAwayTextClrActive}`
                  : homeCount === awayCount
                    ? `${diaHomeAwayTextClr} border-r-[3px] ${borderColor}`
                    : null
                  } py-2 px-4 text-center text-2xl uppercase relative `}
                onClick={() => setWhoWin(WIN.home)}
              >
                {homeCount > awayCount ? (
                  <span className="absolute top-1/2 -translate-y-1/2 left-6">
                    <BsCheckLg />
                  </span>
                ) : null}
                Home
              </button>
              <button
                className={`w-[90%] select-none rounded-r-full ${awayCount > homeCount ? `${diaHomeAwayBg} ${diaHomeAwayTextClrActive}` :
                  homeCount === awayCount ? `${diaHomeAwayTextClr}` : null
                  } py-2 px-4 text-center text-2xl uppercase relative`}
                onClick={() => setWhoWin(WIN.away)}
              >
                {homeCount < awayCount ? (
                  <span className="absolute top-1/2 -translate-y-1/2 right-6">
                    <BsCheckLg />
                  </span>
                ) : null}
                Away
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 divide-x-2 divide-[#dcdbdb] rounded-3xl pt-4">
            <div>
              <ControlledCounter
                className={"max-w-xs bg-[#ededed]"}
                color={"bg-[#ededed]"}
                count={homeCount}
                side={1}
                setCount={(side, value) => handleScoreUpdate(side, value)}
              />
            </div>
            <div>
              <ControlledCounter
                className={"max-w-xs bg-[#ededed]"}
                color={"bg-[#ededed]"}
                count={awayCount}
                side={2}
                setCount={(side, value) => handleScoreUpdate(side, value)}
              />
            </div>
          </div>
          <div className=" mx-auto">
            {tiescore && <span className="text-[#ff5700] block mx-auto w-full  text-center pb-1 pt-3">{tiescore}</span>}
          </div>

          <button
            style={{ color: `${diaButtonsColor}` }}
            className={`mx-auto my-3 rounded-full px-4 ${diaButtonsBg} p-2 text-center text-xl uppercase text-white`}
            onClick={onGameEnd}
          >
            Confirm Score
          </button>
        </MyDialog>

        <Joyride
          scrollToFirstStep
          tooltipComponent={Tooltip}
          continuous
          run={needIntro}
          disableOverlayClose={true}
          steps={introSteps}
          callback={(data) => {
            const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
            if (finishedStatuses.includes(data.status)) {
              dispatch(setNeedIntro(false));
            }
          }}
          styles={{
            options: {
              zIndex: 999,
            },
            beaconInner: {
              backgroundColor: "#ff5700",
            },
            beaconOuter: {
              borderColor: "#ff5700",
            },
          }}
        />

        <div className="mx-auto w-full px-6 flex gap-2">
          <div style={{ width: '82.8vw', height: '100%' }} className="">
            {/* left column */}
            <div className={`relative overflow-hidden rounded-2xl h-max-screen bg-${loungeBgColor} pt-36 pb-16`}>
              <img
                style={{ height: "705px", width: "100%" }}
                src={bg}
                alt="background"
                className="absolute inset-0 overflow-hidden object-cover"
                onClick={() => dispatch(setIsSub(false))}
              />
              {/* absolute assets */}
              <div className="absolute top-5 left-5">
                <img
                  src={info}
                  alt="info"
                  className="w-8 cursor-pointer"
                  onTouchStart={handleTooltipOpen}
                  onClick={handleTooltipOpen}
                />
              </div>
              {!isGameStart && (
                <img
                  id="shuffle"
                  src={shuffle}
                  alt="shuffle"
                  className="absolute bottom-5 left-5 w-8 cursor-pointer h-0"
                />
              )}
              <h2
                style={{ color: `${siteTextColor}` }}
                className={`absolute top-5 right-5 text-2xl text-${siteTextColor} font-semibold`}
              >
                {siteName}
              </h2>
              {isGameStart && (
                <button
                  className={`absolute select-none bottom-5 right-5 text-2xl font-semibold uppercase ${isSub ? `hover:${resumeHover}` : `${subtextClr}`
                    }`}
                  id="sub"
                  onClick={handleSubstitue}
                >
                  {isSub ? `${resumetext}` : "SUB"}
                </button>
              )}
              <div className="relative z-10 mx-auto w-full max-w-5xl px-5">
                {isCountDownStarted && !gameError.warn && <CountDown onComplete={() => dispatch(startGame({ LoungeId: lounge.Id, LocationId: locationId }))} />}
                <div className="flex items-center justify-between gap-4 lg:gap-16">
                  <div className="space-y-7">
                    {lounge.Home.map((card, i) =>
                      card.User.Id ? (
                        <GameCard
                          {...card}
                          key={i}
                          team={1}
                          isSub={isSub}
                          isGameStart={isGameStart}
                          handlePlayerRemove={handlePlayerRemove}
                        />
                      ) : (
                        <GameCardPlaceholder team={1} index={i} key={i} handlePlayerDrop={handlePlayerDrop} />
                      )
                    )}
                  </div>
                  <div className="space-y-7">
                    {lounge.Away.map((card, i) =>
                      card.User.Id ? (
                        <GameCard
                          {...card}
                          key={i}
                          team={2}
                          isSub={isSub}
                          isGameStart={isGameStart}
                          handlePlayerRemove={handlePlayerRemove}
                        />
                      ) : (
                        <GameCardPlaceholder team={2} index={i} key={i} handlePlayerDrop={handlePlayerDrop} />
                      )
                    )}
                  </div>
                </div>
                <div className="relative mt-10 flex items-center justify-center">
                  <button
                    style={{ color: `${startColor}` }}
                    className={`relative select-none overflow-hidden rounded-full px-16 py-4 text-2xl font-medium text-${startColor} ${isGameStart ? `${startBgColor}` : "bg-[hsl(15,100%,50%)]"
                      }`}
                    // onMouseDown={(e) => { isSub ? dispatch(setIsSub(true)) : handlePress(e) }}
                    // onMouseUp={(e) => {
                    //   if (isSub) {
                    //     if (isFullTeamNotPresent()) return;
                    //     else dispatch(setGameError({ error: false, warn: false }));
                    //     dispatch(updateStatus({ GameId: game.Id, Status: GAME_STATUS.Started }));
                    //   }
                    //   else handleRelease(e)
                    // }}
                    onClick={() =>
                      isSub
                        ? resumeGame()
                        : isGameStart
                          ? endGameHandler()
                          : isCountDownStarted
                            ? dispatch(setIsCountDownStarted(false))
                            : handlePress()
                    }
                  >
                    <p className="relative select-none z-10 uppercase">
                      {isSub ? "RESUME GAME" : isGameStart ? "End Game" : isCountDownStarted ? "Cancel" : "Start Game"}
                    </p>
                    {!isGameStart && (
                      <div
                        className={`absolute top-0 bottom-0 right-0 ${startBgColor}`}
                        style={{ left: `${startBtnProgress}%` }}
                      />
                    )}
                  </button>
                  <div className="absolute select-none -bottom-8 flex items-center justify-center gap-2">
                    {gameError.warn && (
                      <>
                        <img src={warn} alt="warn" className="w-5" />
                        <p className="text-sm text-[#ff5700]">
                          Teams must have the same amount of players on both sides.
                        </p>
                      </>
                    )}
                    {gameError.error && <p className="text-[#f80000]">Press and hold to start game</p>}
                    {error && <p className="text-[#f80000]">{error}</p>}
                  </div>
                </div>
              </div>
            </div>
            {/* right column */}
            <div style={{ width: '100%' }} className="py-2">
              {/* bottom row left column */}
              <div className={`rounded-3xl ${bgBody}`}>
                <div className={`rounded-tr-3xl rounded-tl-3xl ${footHeadBg}`}>
                  <div className="mx-auto grid max-w-5xl grid-cols-[1fr_0.5fr_1fr] items-center justify-center py-1 px-3 text-center">
                    <p className="text-3xl font-medium">{textHome}</p>
                    <div className="">
                      <Counter startDate={startedTime} />
                    </div>
                    <p className="text-3xl font-medium">{TextAway}</p>
                  </div>
                </div>
                <div className="mx-auto flex max-w-5xl items-center justify-center text-center xl:grid xl:grid-cols-[1fr_0.5fr_1fr]">
                  <div className="mx-auto">
                    <ControlledCounter
                      count={homeCount}
                      side={1}
                      setCount={(side, val) => handleScoreUpdate(side, val)}
                    />
                  </div>
                  <div className={`mx-auto my-4 w-1 min-w-[4px] self-stretch ${bgLinefoot} xl:my-0 xl:h-[85%] xl:self-center`} />
                  <div className="mx-auto">
                    <ControlledCounter
                      count={awayCount}
                      side={2}
                      setCount={(side, val) => handleScoreUpdate(side, val)}
                    />
                  </div>
                </div>
              </div>

              {/* bottom row right column */}
              {/* <div className="flex h-full items-center justify-center rounded-3xl bg-white p-3" >
              <div alt="qr code" className="mx-auto  w-full max-w-[230px] object-contain">
                <QRCode
                  value={qrcode}
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </div> */}
            </div>
          </div>
          <div
            style={{ width: '19.2vw', height: 'auto', padding: "6px 6px 4px 11px" }}
            id="rmv-player"
            className={`relative z-10 flex flex-col justify-between rounded-2xl mb-2 ${loungeBgColor}
               `}
          >
            <div className="h-auto" >
              <div className="flex items-center justify-between gap-1 py-1">
                <h3 className="text-2xl font-semibold">{loungeHeading}</h3>
                <div style={{ width: "49px", height: '33px', display: "flex", justifyContent: 'end' }} onClick={() => dispatch(setActivityBar(true))}>
                  <img
                    src={time}
                    id="audit"
                    alt="time"
                    className="w-9 min-w-9 cursor-pointer object-contain"
                    onTouchStart={() => dispatch(setActivityBar(true))}

                  />
                </div>
              </div>
              <div
                onDragOver={handleDraggingForTable}
                style={{ scrollBehavior: "smooth", marginRight: '5px', height: '810px' }}
                className="divide-y lounge-box divide-[#f0e5cc55] mt-4 overflow-y-auto overflow-x-hidden select-none"
                ref={listRef}
              >
                {/* <Reorder.Group
                    onReorder={setQueue} values={queue} className="py-2">
                    {queue.map((card, index, arr) => {
                      return (
                        <DragPlayerCard
                          key={card.User.Id}
                          card={card}
                          setRootDragging={setIsDragging}
                          isBtnHover={isBtnHover}
                          setRmvPlayerDialog={setRmvPlayerDialog}
                          setRmvSelectedPlayer={setRmvSelectedPlayer}
                          index={index}
                          lastIndex={arr.length === index + 1}
                          setXChangePlayer={setXChangePlayer}
                          queue={arr}
                          original={lounge.Queue}
                          handleReorder={handleReorder}
                           dragItem={dragItem}
                          dragOverItem={dragOverItem}
                        />
                      );
                    })}
                  </Reorder.Group> */}
                <div className="">
                  {lounge.Queue.map((card, index, arr) => {
                    return (
                      <DragPlayerCard
                        key={card.User.Id}
                        card={card}
                        setRootDragging={setIsDragging}
                        setRmvPlayerDialog={setRmvPlayerDialog}
                        setRmvSelectedPlayer={setRmvSelectedPlayer}
                        index={index}
                        lastIndex={arr.length === index + 1}
                        setXChangePlayer={setXChangePlayer}
                        queue={arr}
                        original={lounge.Queue}
                        handleReorder={handleReorder}
                        dragItem={dragItem}
                        dragOverItem={dragOverItem}
                        isListHover={isListHover}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className={`bg-${loungeBgColor} sticky`}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                const player = JSON.parse(e.dataTransfer.getData("player"));
                dispatch(setRmvSelectedPlayer(player));
                dispatch(setRmvPlayerDialog(true));
              }}
            >
              <motion.button
                id="join-btn"
                style={{ color: `${ButtonTextcolor}`, fontSize: '1.5rem' }}
                className={`mt-1 w-full rounded-full py-2 font-medium text-4xl text-${ButtonTextcolor} ${isDragging ? "bg-red-600" : `${ButtonBgColor}`
                  }`}
                onClick={() => dispatch(setGuestDialog(true))}
              >
                {isDragging ? (
                  <div className="flex items-center justify-center gap-7">
                    REMOVE <TrashIcon className="w-5" />
                  </div>
                ) : (
                  `${ButtonText}`
                )}
              </motion.button>
            </div>
          </div>
        </div>
      </div>
      <DesktopOnly />
    </>
  );
};

export default Game;
