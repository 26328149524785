import { Link, useParams } from 'react-router-dom'
import GameRecords from '../../components/GameRecords'
import { useEffect, useState } from 'react';
import axios from '../../libs/http';
import { toast } from '../../utils/customToast';
import Spinner from '../../utils/Spinner';
import { formatRating } from '../../utils/helpers';

// ----------------- Profile page, me and others based on props ----------
const ViewProfile = () => {
    const { id } = useParams();
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        axios.get(`/Players/getPlayerRank/${id}`).then(res => {
            setIsLoading(false);
            res.data.Rating = formatRating(res.data.Rating);
            setUser(res.data);
        }).catch(error => {
            setIsLoading(false);
            console.log(error);
            toast.error('Unable to fetch user profile');
        })
    }, [])

    return (
        <>
            {isLoading && <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                <Spinner />
                <p className="text-white ml-2">Fetching user profile, Please wait ...</p>
            </div>}
            <section className="h-full sm:px-5 w-full sm:flex flex-1 items-center justify-center py-10 ">
                <div className="mx-auto text-center w-full bg-primary sm:min-w-[385px] max-w-[426px]">
                    <div className="px-6 py-3 sm:px-8 sm:py-5">
                        <div className="flex py-2 w-full">
                            <div className="sm:w-16 w-14 bg-orange-500 sm:h-16 h-14 rounded-full overflow-hidden select-none flex items-center justify-center">
                                <img className="max-w-full w-full object-contain" src={user.ArcheType?.Icon} alt="" />
                            </div>
                            <div className="sm:pl-11 pl-5 sm:pr-4 pr-2 text-left">
                                <h3 className="text-xl font-black text-white sm:text-2xl">
                                    {user.DisplayName}
                                </h3>
                                <h4 className="font-normal mt-[-7px] text-white sm:text-xl text-lg">
                                    {user.GroupName || "SG"} {" - "}{user.ArcheType?.Name || "Sharpshooter"}
                                </h4>
                                <p className="min-[325px]:text-sm text-[12px] italic mt-1">
                                    Member since {new Date(user.CreatedOn).toDateString()}
                                </p>
                            </div>
                        </div>

                        {user.DisplayStatistics && <div className="py-4 flex justify-evenly sm:gap-4">
                            <div className="p-2 text-center flex-1">
                                <h5 className="font-normal text-sm">Rating</h5>
                                <p className="my-3 font-medium text-[18px]">
                                    {user.Rating}
                                </p>
                            </div>
                            <div className="p-2 text-center flex-1 border-white border-opacity-25 border-solid border-r-0 border-t-0 border-b-0 border-l-2">
                                <h5 className="font-normal text-sm">Rank</h5>
                                <p className="my-3 font-medium text-[18px]">
                                    # {user.Rank}
                                </p>
                            </div>
                            <div className="p-2 text-center flex-1 border-white border-opacity-25 border-solid border-r-0 border-t-0 border-b-0 border-l-2">
                                <h5 className="font-normal text-sm">Record</h5>
                                <p className="my-3 font-medium text-[18px]">
                                    {user.Record?.Win} - {user.Record?.Loss}
                                </p>
                            </div>
                        </div>}
                    </div>

                    <div className="bg-[#011f19] rounded-tl-[25px] rounded-tr-[25px] pt-3 height-65vh">
                        <h3 className='font-normal my-2'>Recent Games</h3>
                        <div className="sm:mt-5 ">
                            <GameRecords userId={id} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ViewProfile