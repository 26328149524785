import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader";
import RegisterSuccess from "./RegisterSuccess";
import { register } from "../../store/auth/authAPI";
import { setSuccess } from "../../store/auth/authSlice";

// ***** Register page ********
const Register = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, error, success } = useSelector((store) => store.auth);
  // 
  let initial = {
    email: "",
    firstName: '',
    lastName: '',
    password: "",
  };
  const [errorState, setErrorState] = useState({
    emailError: false,
    passwordError: false,
    policyError: false
  });
  const [formData, setFormData] = useState(initial);
  const [submitted, setSubmitted] = useState(false);
  const [policy, setPolicy] = useState(true);

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    if (success) {
      navigate("/registration-success");
      setTimeout(() => {
        dispatch(setSuccess(false));
      }, 3000);
    }
  }, [isAuthenticated, success]);

  // input fields on change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Call signup in method API
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (validate()) {
      const { email, password, firstName, lastName } = formData;
      dispatch(register({ Username: email.toLowerCase(), Password: password, Email: email, FirstName: firstName, LastName: lastName }));
    }
  };
  const validate = () => {
    let emailError = false;
    let passwordError = false;
    let policyError = false;
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


    if (!formData.password) {
      passwordError = true;
    }
    else if (formData.password.length < 6) {
      passwordError = true;
    }
    if (reg.test(formData.email) === false) {
      emailError = true;
    }
    if (!policy) {
      policyError = true;
    }

    if (emailError || passwordError || policyError) {
      setErrorState({
        emailError,
        passwordError,
        policyError
      });
      return false;
    }
    setErrorState({
      emailError: false,
      passwordError: false,
      policyError: false
    });
    return true;
  };
  return (
    <section className="h-full my-auto sm:px-5 w-full sm:flex items-center justify-center">
      <div className="mx-auto my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[456px] sm:px-12 sm:py-9">
        <h3 className="my-4 text-3xl font-black text-white sm:text-4xl text-center">
          dreamhoops
        </h3>
        <p className="text-center text-white pb-6">
          Pickup basketball. Reimagined.
        </p>
        {error && <div className="flex items-center justify-center gap-2">
          <p className="text-sm text-[#ff5700]">{error}</p>
        </div>}
        <form className=" sm:pt-4 text-white" onSubmit={handleSubmit}>
          <div className="mb-5">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full placeholder:text-gray-400 appearance-none rounded-full bg-inputbg p-4 text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="email"
              type="text"
              name="email"
              placeholder="name@gmail.com"
              autoFocus
              onChange={handleChange}
              value={formData?.email}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={50}
            />
            {errorState.emailError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">Valid email is required</label>}

          </div>
          <div className="">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="w-full placeholder:text-gray-400 appearance-none rounded-full bg-inputbg p-4 text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="password"
              type="password"
              name="password"
              placeholder="At least 6 characters"
              onChange={handleChange}
              value={formData?.password}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={50}
            />
            {errorState.passwordError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">This field is required (min 6 characters)</label>}

          </div>
          <div className="flex items-start pt-4">
            <input className="accent-orange-600 cursor-pointer" checked={policy} onChange={(e) => setPolicy(e.target.checked)} type="checkbox" name="policy" id="" />
            <p className="pl-2 text-[12px]">
              I agree to Dreamhoops' Terms of Service and Privacy Policy.
            </p>
            {errorState.policyError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">Please agree to the terms of service</label>}
          </div>
          <button
            disabled={isLoading}
            role="button"
            title={isLoading ? "Loading..." : "Sign up"}
            type="submit"
            className="my-6 block w-full cursor-pointer bg-orange-600 rounded-full p-2 text-center text-[16px] text-white hover:text-white border-0 py-4 disabled:opacity-70 sm:p-3"
          >
            {isLoading ? <Loader /> : "Create Account"}
          </button>
          <p className="text-sm text-center">
            Already have an account?
            <Link
              to={isLoading ? "/register" : "/login"}
              role="button"
              title="login to your account"
              className="inline pl-1 font-normal text-white underline hover:text-white"
            >
              Sign in here
            </Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default Register;
