import React, { useEffect, useState } from "react";
import path from "../../assets/images/path.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import Spinner from '../../utils/Spinner';
import { toast } from '../../utils/customToast';
import axios from '../../libs/http';
import { formatRating } from "../../utils/helpers";

// ----------------- Top players component form home page -------------------
const TopPlayers = () => {
  // placeholder data

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios.get(`/Players/getTop5Players`).then(res => {
      setIsLoading(false);
      res.data.forEach(x => x.Rating = formatRating(x.Rating));
      setData(res.data);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    })
  }, [])

  return (
    <section className="bg-[#1a1919] pt-7 pb-10 md:pt-12">
      <div className="container mx-auto max-w-[750px] sm:w-5/6">
        <div className="px-6 lg:px-8">
          <h2 className="my-4 text-center text-3xl font-semibold sm:text-3xl md:text-4xl lg:text-4xl">Top Players {isLoading && <Spinner />}</h2>
        </div>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="top_player mx-auto min-h-[540px] max-w-full sm:my-7"
        >
          {data?.map((v, i) => (
            <SwiperSlide key={i} className="px-2 py-5 sm:px-6">
              <div
                className="mx-auto w-full max-w-full rounded-[35px] px-2 py-5 shadow-xl sm:px-8 "
                style={{ background: "linear-gradient(to bottom, #00413a,#003d36,#003831,#002b23)" }}
              >
                <h3 className="mx-auto my-6 text-center text-2xl font-semibold text-[#ff8200] sm:text-[50px]">
                  Rank #{v.Rank}
                </h3>
                <div className="my-6 flex w-full items-center justify-center">
                  <div className="flex h-10 w-10 select-none items-center justify-center overflow-hidden rounded-full bg-orange-500 sm:h-12 sm:w-12">
                    <img className="w-full max-w-full object-contain" src={v.ArcheType.Icon} alt="" />
                  </div>
                  <h4 className="ml-6 inline-block text-center text-[20px] font-semibold md:text-[25px] lg:text-[40px]">
                    <Link to={`/profile/${v.Id}`}>{v.DisplayName}</Link>
                  </h4>
                </div>
                <div className="mt-2 flex w-full py-2 sm:px-3">
                  <div className="w-1/2 px-3 text-center font-semibold sm:px-7">
                    <p className="text-[22px] md:text-[28px]">Record</p>
                    <h4 className="w-full py-[30px] text-[45px] font-semibold text-[#ff8200] md:text-[60px] lg:text-[80px]">
                      {v.Record.Win} - {v.Record.Loss}
                    </h4>
                  </div>
                  <div className="h w-[2.5px] bg-gray-300 opacity-[0.5]"></div>
                  <div className="w-1/2 px-3 text-center font-semibold sm:px-7">
                    <p className="text-[22px] md:text-[28px]">Rating (7d)</p>
                    <h4 className="flex w-full items-end justify-center py-[30px] text-[45px] font-semibold text-[#ff8200] md:text-[60px] lg:text-[80px]">
                      {v.Rating}
                      {
                        !v.Progress ? "" :
                          v.Progress == "up" ? (
                            <div className="translate-y-[-20px] text-[#5dff35] lg:translate-y-[-32px]">
                              <img className="w-[25px] object-contain lg:w-[35px]" src="assets/images/path.png" alt="" />
                            </div>
                          ) : (
                            <span className="mt-auto pl-2 text-red-600">
                              <FaSortDown style={{ fontSize: "22px" }} />
                            </span>
                          )
                      }

                      {v.RatingDiff > 0 && <span
                        className={` ml-2 translate-y-[-10px] font-semibold lg:translate-y-[-30px] ${v.Progress.toLocaleLowerCase() == "up" ? "text-[#5dff35]" : "text-red-600"
                          } text-[20px] md:text-[30px]`}
                      >
                        {v.RatingDiff}
                      </span>}
                    </h4>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <Link
          to="/leaderboard"
          className="mx-auto mb-5 mt-[20px] block w-max max-w-full rounded-3xl bg-[#ff8200] py-3 px-14 text-sm font-medium uppercase text-white sm:text-3xl"
        >
          View Full Leaderboard
        </Link>
      </div>
    </section>
  );
};

export default TopPlayers;
