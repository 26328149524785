import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../libs/http';

export const joinLounge = createAsyncThunk(
  'game/joinLounge',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/PlayersLounge/join`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const joinAsGuestLounge = createAsyncThunk(
  'game/joinAsGuestLounge',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/PlayersLounge/joinAsGuest`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentLounge = createAsyncThunk(
  'game/getCurrentLounge',
  async (locationId, { rejectWithValue }) => {
    try {
            
      const response = await axios.get(
        `/PlayersLounge/getByLocation/` + locationId);
      console.log(response);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const leavePlayer = createAsyncThunk(
  'game/leavePlayer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/PlayersLounge/leavePlayer`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const leaveGuestPlayer = createAsyncThunk(
  'game/leaveGuestPlayer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/PlayersLounge/leaveGuestPlayer`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePlayerPosition = createAsyncThunk(
  'game/updatePlayerPosition',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/PlayersLounge/updatePosition`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addTeamPlayer = createAsyncThunk(
  'game/addTeamPlayer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/PlayersLounge/addTeamPlayer`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const removeTeamPlayer = createAsyncThunk(
  'game/removeTeamPlayer',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/PlayersLounge/removeTeamPlayer`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const startGame = createAsyncThunk(
  'game/startGame',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/Game/start`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCurrentLocationGame = createAsyncThunk(
  'game/getCurrentLocationGame',
  async (locationId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/Game/getByLocation/${locationId}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateScore = createAsyncThunk(
  'game/updateScore',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/Game/updateScore`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const updateStatus = createAsyncThunk(
  'game/updateStatus',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/Game/updateStatus`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const endGame = createAsyncThunk(
  'game/endGame',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/Game/end`,
        data
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);