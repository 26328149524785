import { Link } from "react-router-dom";

// *********** 404 notfound page *************
const Community = () => {
  return (
    <section className="flex flex-1 items-center justify-center bg-dark py-4">
      <div className="mx-auto my-auto max-w-[93%] rounded-xl border-3 border-solid border-orange-500 bg-primary md:p-8 p-5 shadow-lg sm:my-2 md:max-w-[690px]">
        <img className="mb-2" width="60" src="/fav.png" alt="Dreamhoops logo" />
        <p className="mb-5 inline-block md:pl-5 pl-2 md:text-3xl text-2xl font-bold text-gray-100">
          Welcome to Dreamhoops!
        </p>
        <p className="text-lg text-gray-100">
          Coming Soon
        </p>
        <Link
          to="/"
          className="mt-2 no-underline block w-max rounded bg-orange-600 py-2 px-4 font-bold text-white hover:bg-orange-700 hover:text-white"
        >
          Go Back
        </Link>
      </div>
    </section>
  );
};

export default Community;
