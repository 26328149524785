import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../utils/Loader";
import EmailSent from "./EmailSent"
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../store/auth/authAPI";
import { setSuccess } from "../../store/auth/authSlice";
// ***** ForgotPassword page ********
const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let initial = {
    email: "",
  };
  const { isAuthenticated, isLoading, error, success } = useSelector(
    (state) => state.auth
  );
  const [formData, setFormData] = useState(initial);
  const [errorState, setErrorState] = useState({
    emailError: false
  });
  const [submitted, setSubmitted] = useState(false);
  const validate = () => {
    let emailError = false;
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(formData.email) === false) {
      emailError = true;
      setErrorState({
        emailError
      });
      return;
    }
    setErrorState({
      emailError
    });
    return true;
  };
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    if (success) {
      navigate("/forgot-password-success")
      setTimeout(() => {
        dispatch(setSuccess(false));
      }, 3000);
    }
  }, [isAuthenticated, success]);

  // input fields on change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (validate()) {
      setSubmitted(false);
      dispatch(forgotPassword({ Email: formData.email }));
    }
  };
  return (
    <section className="h-full my-auto sm:px-5 w-full sm:flex items-center justify-center">
      <div className="mx-auto my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
        <h3 className="my-4 relative text-xl font-black text-white sm:text-2xl text-center">
          <Link
            to={
              isLoading
                ? "/forgot-password"
                : formData.email
                  ? `/login?email=${formData.email}`
                  : "/login"
            }
            title="go back"
            className="text-white absolute left-0 top-[5px]"
          >
            <IoMdArrowBack />
          </Link>
          Forgot Password
        </h3>
        <p className="text-center mt-8 text-sm text-white pb-4">
          Enter the email associated with your account and <br />
          we'll send an email with instructions to reset your password.
        </p>
        {error && <div className="flex items-center justify-center gap-2">
          <p className="text-sm text-[#ff5700]">{error}</p>
        </div>}
        <form className=" sm:pt-4 text-white" onSubmit={handleSubmit}>
          <div className="mb-5">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full appearance-none rounded-full bg-inputbg p-4 text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="email"
              type="email"
              name="email"
              autoFocus
              onChange={handleChange}
              value={formData?.email}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={50}
            />
            {errorState.emailError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">Valid email is required</label>}
          </div>
          <button
            disabled={isLoading}
            role="button"
            title={isLoading ? "Loading..." : "Send Instructions"}
            type="submit"
            className="my-6 block w-full cursor-pointer bg-orange-600 rounded-full p-2 text-center text-[16px] text-white hover:text-white border-0 py-4 disabled:opacity-70 sm:p-3"
          >
            {isLoading ? <Loader /> : "Send Instructions"}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ForgotPassword;
