

import React, { useEffect } from 'react';

import signupSuccess from '../../assets/images/signupSuccess.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { verifyEmail } from '../../store/auth/authAPI';
import { setSuccess } from '../../store/auth/authSlice';
import Loader from "../../utils/Loader";
// ------------------ Success page for Sign up/Register ---------------
const VerifyEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token, userid } = useParams();
    const { isAuthenticated, isLoading, error, success } = useSelector(
        (state) => state.auth
    );

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
        }
        if (success) {
            setTimeout(() => {
                navigate('/login');
                dispatch(setSuccess(false));
            }, 5000);
        }
    }, [isAuthenticated, success]);

    useEffect(() => {
        dispatch(verifyEmail({ UserId: userid, Token: token }));
    }, []);

    return (
        <section className="h-full sm:px-5 w-full sm:flex flex-1 items-center justify-center">
            {isLoading && <div className="mx-auto my-auto flex content-center items-center justify-center py-5 px-2 text-center sm:px-4">
                <Loader />
            </div>}
            {error && <div className="flex items-center justify-center gap-2">
                <p className="text-sm text-[#ff5700]">{error}</p>
            </div>}
            {success && <div className="mx-auto text-center my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
                <h3 className="mb-3 text-3xl font-black text-white sm:text-4xl text-center">
                    dreamhoops
                </h3>
                <img className="w-full max-w-[260px] my-2 mx-auto" src={signupSuccess} alt="" />
                <p className="text-center text-white pb-6">
                    Verification complete.
                    <br />
                    {success}
                </p>
                <Link
                    to="/login"
                    className="my-6 block w-full no-underline cursor-pointer bg-orange-600 rounded-full p-2 text-center text-[16px] text-white hover:text-white border-0 py-4 disabled:opacity-70 sm:p-3"
                >
                    Login
                </Link>
            </div>}
        </section>
    )
}

export default VerifyEmail