import React, { useEffect, useState } from "react";
import { FaLocationArrow } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// import required modules
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import Spinner from '../../utils/Spinner';
import { toast } from '../../utils/customToast';
import axios from '../../libs/http';
import { formatRating } from "../../utils/helpers";

const RecentGames = () => {
  // placeholder data
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    axios.get(`/Game/getRecent`).then(res => {
      setIsLoading(false);
      setData(res.data);
    }).catch(error => {
      setIsLoading(false);
      console.log(error);
    })
  }, [])
  return (
    <section className=" bg-[#fff] py-7 md:py-12">
      <div className="container mx-auto max-w-[750px] sm:w-5/6">
        <div className="px-6 lg:px-8">
          <h2 className="my-4 text-center text-3xl font-semibold text-[#191919] sm:text-3xl md:text-4xl lg:text-5xl">
            Recent Games {isLoading && <Spinner />}
          </h2>
        </div>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          className="recent_games mx-auto min-h-[540px] max-w-full sm:my-7 sm:min-h-[720px]"
        >
          {data.map((v, i) => (
            <SwiperSlide key={i} className="px-5 sm:px-6">
              <div className="mx-auto w-full max-w-full rounded-[35px] bg-[#1a1919]">
                <div
                  className="w-full rounded-tr-[35px] rounded-tl-[35px] px-4 pt-[40px] pb-[25px]"
                  style={{ background: "linear-gradient(to right, #00413a,#003d36,#003831,#002b23)" }}
                >
                  <div className="flex flex-wrap items-center justify-center gap-5 sm:gap-0">
                    <span className="my-1 flex gap-1 rounded-full text-center text-sm font-medium text-white sm:px-5 sm:text-[28px]">
                      {/* <FaLocationArrow /> */}
                      {v.LocationName}
                    </span>
                    <span className="my-1 flex gap-1 rounded-full text-center text-sm font-medium text-[#f55703] sm:px-5 sm:text-[28px]">
                      {/* <MdDateRange /> */}
                      {new Date(v.CreatedOn).toDateString()}
                    </span>
                  </div>
                  <hr className="mx-auto mt-7 w-[82%] border-[1.2px] text-[#000]" />
                  <h3 className="mx-auto mt-6 pb-3 text-center text-6xl font-semibold sm:text-9xl">{v.Score.HomeScore} - {v.Score.AwayScore}</h3>
                </div>
                <div className="mx-auto flex w-full items-center justify-between py-2 text-[18px] sm:text-[28px]">
                  <p className="w-[50%] text-center font-semibold">
                    Home
                    <span className={`px-2 font-semibold ${v.IsHomeWin ? 'text-[#00dd00]' : 'text-[#ff0000]'}`}>{v.IsHomeWin ? "W" : "L"}</span>
                  </p>
                  <p className="w-[50%] text-center font-semibold">
                    Away
                    <span className={`px-2 font-semibold ${v.IsHomeWin ? 'text-[#ff0000]' : 'text-[#00dd00]'}`}>{v.IsHomeWin ? "L" : "W"}</span>
                  </p>
                </div>
                <div className=" flex w-full rounded-bl-[35px] rounded-br-[35px] bg-[#e7dcc3] py-4 sm:px-3">
                  <div className="w-1/2 px-3 font-semibold text-[#191919] sm:px-7">
                    <table className="mx-auto mt-3 w-full table-auto text-[14px] text-black sm:text-2xl">
                      <tbody className="leading-[1.8]">
                        {
                          v.Home.map(x => <tr key={x.Id}>
                            <td className="py-1 text-left">
                              <Link className="" target="_blank" to={`/profile/${x.Id}`}>
                                {x.Name}
                              </Link>
                            </td>
                            <td className="py-1 text-right">{x.IsGuest ? "-" : formatRating(x.Rating)}</td>
                          </tr>)
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="h w-[2px] bg-[#605c55]"></div>
                  <div className="w-1/2 px-3 font-semibold text-[#191919] sm:px-7">
                    <table className="mx-auto mt-3 w-full table-auto text-[14px] sm:text-2xl">
                      <tbody className="leading-[1.8]">
                        {
                          v.Away.map(x => <tr key={x.Id}>
                            <td className="py-1 text-left">
                              <Link className="" target="_blank" to={`/profile/${x.Id}`}>
                                {x.Name}
                              </Link>
                            </td>
                            <td className="py-1 text-right">{x.IsGuest ? "-" : formatRating(x.Rating)}</td>
                          </tr>)
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* <Link
          to="/leaderboard"
          className="mx-auto mb-5 mt-[20px] block w-max max-w-full rounded-3xl bg-[#ff8200] py-3 px-14 text-sm font-medium uppercase text-white sm:text-3xl"
        >
          view all games
        </Link> */}
      </div>
    </section>
  );
};

export default RecentGames;
