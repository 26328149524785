import { useState, useEffect } from 'react';
import { BsArrowLeft, BsFillEyeFill, BsFillEyeSlashFill, BsPencilFill } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { editArcheType } from '../../store/auth/authAPI';
import Loader from '../../utils/Loader';


// ----------------- Edit Profile page, me and others based on props ----------
const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, error, success } = useSelector((store) => store.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    stats: true,
    firstName: '',
    displayName: ''
  });
  const [errorState, setErrorState] = useState({
    firstNameError: false,
    displayNameError: false
  });

  useEffect(() => {
    setFormData({ firstName: user.FirstName, displayName: user.DisplayName, stats: user.DisplayStatistics });
  }, [user]);

  const validate = () => {
    let firstNameError = false;
    let displayNameError = false;

    if (!formData.firstName) {
      firstNameError = true;
    }
    if (!formData.displayName) {
      displayNameError = true;
    }
    if (firstNameError || displayNameError) {
      setErrorState({
        firstNameError,
        displayNameError
      });
      return false;
    }
    setErrorState({
      firstNameError: false,
      displayNameError: false
    });
    return true;
  };
  const onSaveProfile = (evt) => {
    evt.preventDefault();
    setSubmitted(true);
    if (validate()) {
      setSubmitted(false);
      dispatch(editArcheType({ UserId: user.Id, ArcheType: user.ArcheType, GroupName: user.GroupName, FirstName: formData.firstName, DisplayName: formData.displayName, DisplayStatistics: formData.stats }));
    }
  }

  return (
    <section className="h-full sm:px-5 w-full sm:flex flex-1 items-start justify-center">
      <div className="mx-auto text-center w-full bg-primary sm:min-w-[385px] max-w-[446px]">
        <div className="bg-[#011f19] px-6 sm:px-8 sm:pt-7 pt-3 sm:rounded-br-[25px] rounded-br-[190px] rounded-bl-[190px] sm:rounded-bl-[25px] text-center relative pb-[50px] w-full">
          <span className="cursor-pointer rounded-full flex items-center justify-center text-white overflow-hidden select-none absolute left-[15px] sm:top-[19px] top-[8px] p-[4px] shadow-lg"
            role="button"
            title="Go back"
            onClick={() => navigate("/profile")}
          >
            <BsArrowLeft style={{ fontSize: "22px" }} />
          </span>
          <h3 className="text-lg mb-4 font-black text-white sm:text-xl">
            Edit Profile
          </h3>
          <h3 className="text-xl font-semibold text-white sm:text-2xl">
            {user.DisplayName}
          </h3>
          <h4 className="font-normal mt-[-7px] text-white sm:text-xl text-lg">
            {user.GroupName} {" - "}{user.ArcheType.Name}
          </h4>
          <p className="min-[325px]:text-sm text-[12px] italic mt-1">
            Member since {new Date(user.CreatedOn).toDateString()}          
          </p>
        </div>
        <div className="relative z-[2] mt-[-39px] w-max max-w-full mx-auto">
          <div className="sm:w-16 shadow-md w-14 bg-orange-500 sm:h-16 h-14 rounded-full overflow-hidden select-none flex items-center justify-center mx-auto">
            <img className="max-w-full w-full object-contain" src={user.ArcheType?.Icon || "/avatar.png"} alt="" />
          </div>
          <span className="cursor-pointer rounded-full bg-white flex items-center justify-center text-primary overflow-hidden select-none absolute right-[-5px] top-[4px] p-[4px] shadow-md"
            role="button"
            title="Edit Archetype"
            onClick={() => navigate("/edit-archetype")}
          >
            <BsPencilFill style={{ fontSize: "11px" }} />
          </span>
        </div>
        {error && <div className="flex items-center justify-center gap-2">
          <p className="text-sm text-[#ff5700]">{error}</p>
        </div>}
        <div className="px-6 sm:px-8 py-3 sm:py-5">
          <form action="" onSubmit={onSaveProfile}>
            <div className="mb-5">
              <label
                className="text-md text-left mb-1 block font-normal"
                htmlFor="name"
              >
                First Name
              </label>
              <div className="relative">
                <input
                  className="w-full placeholder:text-gray-400 appearance-none rounded-0 bg-primary py-3 text-white border-2 border-l-0 border-r-0 border-t-0 border-b-solid border-[#ffffff2c] focus:border-b-orange-600 focus:outline-none focus:ring-0 disabled:opacity-60"
                  id="firstName"
                  type="text"
                  name="firstName"
                  placeholder="Enter your first name"
                  autoComplete="off"
                  defaultValue={user.FirstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                />
                {errorState.firstNameError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">First name is required</label>}
              </div>
            </div>
            <div className="mb-5">
              <label
                className="text-md text-left mb-1 block font-normal"
                htmlFor="name"
              >
                Display Name
              </label>
              <div className="relative">
                <input
                  className="w-full placeholder:text-gray-400 appearance-none rounded-0 bg-primary py-3 text-white border-2 border-l-0 border-r-0 border-t-0 border-b-solid border-[#ffffff2c] focus:border-b-orange-600 focus:outline-none focus:ring-0 disabled:opacity-60"
                  id="displayName"
                  type="text"
                  name="displayName"
                  placeholder="Enter your display name (public)"
                  autoComplete="off"
                  defaultValue={user.DisplayName}
                  maxLength={14} 
                  onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
                />
                {errorState.displayNameError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">Display name is required</label>}
              </div>
            </div>
            {/* <div className="mb-5">
              <label
                className="text-md text-left mb-1 block font-normal"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative">
                <input
                  className="w-full placeholder:text-gray-400 appearance-none rounded-0 bg-primary py-3 text-white border-2  border-l-0 border-r-0 border-t-0 border-b-solid border-b-[#ffffff2c] focus:border-b-orange-600 focus:outline-none focus:ring-0 disabled:opacity-60"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="At least 8 characters"
                  required
                  autoComplete="off"
                  defaultValue="admin123"
                />
                <div role="button" tabIndex="0" onClick={() => setShowPassword((prev) => !prev)}
                  className="absolute right-0 top-[50%] cursor-pointer select-none flex items-center justify-center p-1"
                  style={{ transform: "translateY(-50%)" }}
                >
                  {showPassword ?
                    <BsFillEyeFill style={{ fontSize: "20px" }} />
                    :
                    <BsFillEyeSlashFill style={{ fontSize: "20px" }} />
                  }
                </div>
              </div>
            </div> */}
            <div className="mb-5 text-left">
              <label
                className="text-md mb-1 block font-normal"
                htmlFor=""
              >
                Privacy
              </label>
              <div className="my-4 flex items-center justify-between">
                <p className="font-semibold text-[18px]">
                  Display Statistics
                </p>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    onChange={(e) => setFormData({ ...formData, stats: e.target.checked })}
                    type="checkbox"
                    value={formData.stats}
                    className="sr-only peer" checked={formData.stats}
                  />
                  <div className="w-11 h-6 bg-gray-500 bg-opacity-40 rounded-full peer peer-checked:after:translate-x-[100%] peer-checked:bg-orange-600 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
                </label>
              </div>
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="mt-6 mb-3 block w-full mx-auto cursor-pointer bg-orange-600 rounded-full text-center text-[16px] text-white hover:text-white border-0 sm:py-4 py-3 disabled:opacity-70 sm:p-3"
            >
              {isLoading ? <Loader /> : "Save"}
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default EditProfile