import SwalT from 'sweetalert2'
import SwalN from 'sweetalert2'

let toastConfig = {
  toast: true,
  icon: '',
  title: '',
  position: 'top-right',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', SwalT.stopTimer)
    toast.addEventListener('mouseleave', SwalT.resumeTimer)
  }
};
export const toast = {
  success: (text) => {
    SwalT.fire({
      ...toastConfig,
      icon: 'success',
      title: text,
    });
  },
  info: (text) => {
    SwalT.fire({
      ...toastConfig,
      icon: 'info',
      title: text,
    });
  },
  error: (text) => {
    SwalT.fire({
      ...toastConfig,
      icon: 'error',
      title: text,
    });
  },
  warning: (text) => {
    SwalT.fire({
      ...toastConfig,
      icon: 'warning',
      title: text,
    });
  },
}

export const notification = {
  show: (icon, title, text) => {
    SwalN.fire({
      icon,
      title,
      html: text,
      backdrop: true
    });
  },
  showLoading: () => {
    SwalN.showLoading();
  },
  hideLoading: () => {
    SwalN.hideLoading();
  }
};