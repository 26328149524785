import { ReactComponent as XRedIcon } from "../../assets/images/game/SVG/x.svg";
import { useSelector } from "react-redux";
import { themes } from "./theme";

const GameCard = ({ User, team, isSub, isGameStart, handlePlayerRemove }) => {
  const { user } = useSelector((store) => store.auth);
  const bgLinefoot = themes[user.theme].card.activeCard;

  const handleRmv = () => {
    handlePlayerRemove({ Team: team, UserId: User.Id, IsSub: isSub });
  };
  return (
    <div
      className={`relative flex w-full items-baseline justify-between gap-2 rounded-full bg-[#ffffff] py-4 pr-5 text-black xl:min-w-[330px] ${isSub ? "[box-shadow:0_0_17px_#d2ef3a]" : ""
        }`}
    >
      <div className="absolute top-0 bottom-0 aspect-square overflow-hidden rounded-full p-2 shadow-black/40 [box-shadow:0_0_10px_rgb(0_0_0_/_0.5)]">
        <img src={`${bgLinefoot}`} alt="icon" className="h-full w-full object-contain" />
      </div>
      <p className="pl-16 font-bold lg:pl-[75px]">{User.Name}</p>
      {isGameStart ? (
        isSub ? (
          <XRedIcon className="aspect-square w-4 cursor-pointer" onClick={handleRmv} />
        ) : null
      ) : (
        <XRedIcon className="aspect-square w-4 cursor-pointer" onClick={handleRmv} />
      )}
    </div>
  );
};
export default GameCard;
