import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../libs/http';


export const signIn = createAsyncThunk(
  'auth/fetchUser',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/Account/login`, userData);
      return response.data;
    } catch (err) {
      if (err.response.data.errors) {
        if (err.response.data.errors.Password)
          return rejectWithValue({
            Errors: err.response.data.errors.Password,
          });
        if (err.response.data.errors.Username)
          return rejectWithValue({
            Errors: err.response.data.errors.Username,
          });
      }
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const register = createAsyncThunk(
  'auth/register',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/Account/register`, userData);
      return response.data;
    } catch (err) {
      if (err.response.data.errors) {
        if (err.response.data.errors.Password)
          return rejectWithValue({
            Errors: err.response.data.errors.Password,
          });
        if (err.response.data.errors.Username)
          return rejectWithValue({
            Errors: err.response.data.errors.Username,
          });
        if (err.response.data.errors.Email)
          return rejectWithValue({
            Errors: err.response.data.errors.Email,
          });
      }
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/Account/forgotpassword`,
        userData
      );
      return response.data;
    } catch (err) {
      if (err.response.data.errors) {
        if (err.response.data.errors.Email)
          return rejectWithValue({
            Errors: err.response.data.errors.Email,
          });
      }
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/Account/resetpassword`,
        userData
      );
      return response.data;
    } catch (err) {
      if (err.response.data.errors) {
        if (err.response.data.errors.ConfirmPassword)
          return rejectWithValue({
            Errors: err.response.data.errors.ConfirmPassword,
          });
      }
      // Use `err.response.data` as `action.payload` for a `rejected` action,
      // by explicitly returning it using the `rejectWithValue()` utility
      return rejectWithValue(err.response.data);
    }
  }
);
export const logoutUser = createAsyncThunk(
  'auth/logout',
  async (email) =>
    await axios.post(`/Account/logout/${email}`)
);
export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/Account/verifyEmail`,
        userData
      );
      return response.data;
    } catch (err) {
      if (err.response.data.errors) {
        if (err.response.data.errors.UserId)
          return ({
            Errors: err.response.data.errors.UserId,
          });
        if (err.response.data.errors.Token)
          return ({
            Errors: err.response.data.errors.Token,
          });
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const editArcheType = createAsyncThunk(
  'user/editArcheType',
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/UserProfile/editProfile`,
        userData
      );
      return response.data;
    } catch (err) {
      if (err.response.data.errors) {
        if (err.response.data.errors.DisplayName)
          return rejectWithValue({
            Errors: err.response.data.errors.DisplayName,
          });
        if (err.response.data.errors.FirstName)
          return rejectWithValue({
            Errors: err.response.data.errors.FirstName,
          });
      }

      return rejectWithValue(err.response.data);
    }
  }
);
export const editAccountOnBoarding = createAsyncThunk(
  'user/editAccountOnBoarding',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/UserProfile/editAccountOnBoarding/${userId}`
      );
      return response.data;
    } catch (err) {
      
      return rejectWithValue(err.response.data);
    }
  }
);
export const clearLounge = createAsyncThunk(
  'PlayersLounge/clear',
  async (id) =>
    await axios.post(`/PlayersLounge/clear/${id}`)
);