import React, { useState } from "react";
import "./style.css";
import Popup from "./Popup";
import { NavLink } from "react-router-dom";
import basket from "../../assets/images/basket.png"
import axios from "../../libs/http";
import { toast, notification } from "../../utils/customToast";
import Spinner from "../../utils/Spinner";
const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  let initial = {
    email: "",
    firstName: '',
    lastName: '',
    message: "",
  };
  const [errorState, setErrorState] = useState({
    emailError: false,
    firstNameError: false,
    lastNameError: false,
    messageError: false
  });
  const [formData, setFormData] = useState(initial);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const validate = () => {
    let emailError = false;
    let firstNameError = false;
    let lastNameError = false;
    let messageError = false;
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


    if (!formData.firstName) {
      firstNameError = true;
    }
    if (!formData.lastName) {
      lastNameError = true;
    }
    if (reg.test(formData.email) === false) {
      emailError = true;
    }
    if (!formData.message) {
      messageError = true;
    }

    if (emailError || firstNameError || lastNameError || messageError) {
      setErrorState({
        emailError,
        firstNameError,
        lastNameError,
        messageError
      });
      return false;
    }
    setErrorState({
      emailError: false,
      firstNameError: false,
      lastNameError: false,
      messageError: false
    });
    return true;
  };
  const handleSubmit = () => {
    setSubmitted(true);
    if (validate()) {
      setIsLoading(true);
      const { email, message, firstName, lastName } = formData;
      const data = { Email: email, FirstName: firstName, LastName: lastName,  Message: message };
      axios.post('/Account/sendMessage', data).then(res => {
        setIsLoading(false);
        setFormData(initial);
        setSubmitted(false);
        notification.show("success", "Message Sent", 'Thank you for you message. We will respond to you as soon as possible')
      }).catch(error => {
        setIsLoading(false);
        toast('Request cannot be completed at the moment. Try again later');
      })
    }
  };
  // input fields on change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className="relative">
      {/* POPUP MESSAGE */}
      {/* CONTACT US PAGE FOR DESKTOP  */}
      {isLoading && <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
        <Spinner />
      </div>}
      <div className="block lg:hidden contact-mobile-main ">
        <div className="contactMobile flex justify-end items-center pr-6 py-6 relative h-[250px]">
          <img src={basket} className="absolute opacity-60  top-0 left-0  h-full w-[300px] mobileImg bg-red-00 object-contain " alt="" />
          <h2 className="mt-4 text-center font-semibold text-2xl  lg:text-6xl z-20">Get in Touch!</h2>
        </div>
        <div className="pt-5">


          <div className={`flex flex-col w-[90%] max-w-[500px] mt-8 mx-auto gap-5 h-[500px]  `}>
            <div className="flex justify-between gap-4">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                disabled={isLoading}
                readOnly={isLoading}
                autoComplete="off"
                maxLength={50}
                placeholder="First Name"
                className="w-[90%] py-2 text-[#000] px-4 border-none rounded-[20px] selection:bg-gray-400 outline-none bg-white"
              // className="w-[90%] py-2 text-[#000] px-4 border-none rounded-[20px] selection:bg-gray-400 outline-none bg-[#f5deb3]"
              />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                disabled={isLoading}
                readOnly={isLoading}
                autoComplete="off"
                maxLength={50}
                placeholder="Last Name"
                className="w-[90%] py-2 text-[#000] px-4 border-none rounded-[20px] selection:bg-gray-400 outline-none bg-white"
              // className="w-[90%] py-2 text-[#000] px-4 border-none rounded-[20px] selection:bg-gray-400 outline-none bg-[#f5deb3]"
              />
            </div>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={100}
              placeholder="Email"
              className="py-2 text-[#000] px-4 selection:bg-gray-400 border-none rounded-[20px] outline-none bg-white" />
            {/* <input type="text" placeholder="Email" className="py-2 text-[#000] px-4 selection:bg-gray-400 border-none rounded-[20px] outline-none bg-[#f5deb3]" /> */}
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={500}
              cols="30"
              rows="10"
              className="bg-white text-[#000] rounded-[20px] px-2 py-4 border-none selection:bg-gray-400 outline-none"
              placeholder="Your Message"
            ></textarea>
            <p>
              {" "}
              <span className="font-medium">By sending this message you  agree to the</span>{" "}
              <NavLink to="/privacy" className="text-[#bed1ff]"> Privacy Policy</NavLink>
              <span className="mx-1 text-white">

                and
              </span>
              <NavLink to="/termsandconditions" className="text-[#aabff7]"> Terms and Conditions </NavLink>
            </p>
            {errorState.firstNameError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*First name is required</p>}
            {errorState.lastNameError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*Last name is required</p>}
            {errorState.emailError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*Valid email is required</p>}
            {errorState.messageError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*Message is required</p>}
            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className="mb-5 mt-[-10px]  block  rounded-3xl bg-[#ff8200] md:py-1 px-14 text-base font-medium uppercase text-white sm:text-2xl py-3"
            >
              SEND
            </button>
          </div>
        </div>
      </div>

      {/* CONTACT US PAGE FOR DESKTOP  */}
      {/* <Popup showPopup={showPopup} setShowPopup={setShowPopup} /> */}

      <div className={`contactUs hidden lg:flex  pr-8 h-[570px] py-5 relative`}>
        <img src={basket} className="absolute top-0 left-0 h-[95%]  object-contain" alt="" />

        <div className=" flex justify-end items-center w-full  max-w-[1350px] mx-auto h-max  ">
          <div className={`contactbox flex flex-col w-[450px] ml-auto py-0 z-10`}>
            <div className="flex justify-end pt-0">
              <h2 className="mt-4 text-center text-3xl font-semibold sm:text-3xl md:text-4xl lg:text-4xl">
                Get in Touch!
              </h2>
            </div>
            <div className="flex justify-between gap-4 ">
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                disabled={isLoading}
                readOnly={isLoading}
                autoComplete="off"
                maxLength={50}
                placeholder="First Name"
                className="w-[90%] py-2 px-4 selection:bg-gray-400 border-none text-[#000] rounded-[20px] outline-none bg-white"
              />
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                disabled={isLoading}
                readOnly={isLoading}
                autoComplete="off"
                maxLength={50}
                placeholder="Last Name"
                className="w-[90%] py-2 px-4 selection:bg-gray-400 border-none text-[#000] rounded-[20px] outline-none bg-white"
              />
            </div>
            <input
              type="text"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={100}
              placeholder="Email"
              className="py-2 px-4 my-2 border-none selection:bg-gray-400  text-[#000] rounded-[20px] outline-none bg-white"
            />
            <div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                disabled={isLoading}
                readOnly={isLoading}
                autoComplete="off"
                maxLength={500}
                rows="5"
                className="w-full bg-white selection:bg-gray-400 text-[#000] rounded-[20px] px-2 py-4 border-none outline-none"
                placeholder="Your Message"
              ></textarea>
            </div>
            <p>
              {" "}
              <span className="font-medium">By sending this message you  agree to the</span>{" "}
              <NavLink to="/privacy" className="text-[#bed1ff]"> Privacy Policy</NavLink>
              <span className="mx-1 text-white">

                and
              </span>
              <NavLink to="/termsandconditions" className="text-[#aabff7]"> Terms and Conditions </NavLink>
            </p>
            {errorState.firstNameError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*First name is required</p>}
            {errorState.lastNameError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*Last name is required</p>}
            {errorState.emailError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*Valid email is required</p>}
            {errorState.messageError && submitted && <p class="text-xs mb-1 block font-normal pl-2">*Message is required</p>}

            <button
              onClick={handleSubmit}
              disabled={isLoading}
              className="desktopBtn mb-5 block  rounded-3xl bg-[#ff8200] py-2 px-14 text-sm font-medium uppercase text-white sm:text-2xl"
            >
              SEND
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
