import { useState, useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import ResetPasswordSuccess from './ResetPasswordSuccess';
import { resetPassword } from "../../store/auth/authAPI";

// ***** ResetPassword page ********
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { isAuthenticated, isLoading, error, success } = useSelector((store) => store.auth);

  let initial = {
    password: "",
    confirmPassword: "",
  };
  const [errorState, setErrorState] = useState({
    passwordError: false,
    confirmPasswordError: false
  });
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState(initial);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
    if (success) {
      navigate("/reset-password-success", { replace: true });
      setTimeout(() => {
        dispatch(setSuccess(false));
        navigate("/login");
      }, 3000);
    }
  }, [isAuthenticated, success]);
  // input fields on change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // handle submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (validate()) {
      dispatch(resetPassword({ Password: formData.password, ConfirmPassword: formData.confirmPassword, Token: id }));
    }
  };
  const validate = () => {
    let passwordError = false;
    let confirmPasswordError = false;
    if (!formData.password) {
      passwordError = true;
    }
    if (!formData.confirmPassword) {
      confirmPasswordError = true;
    }

    if (passwordError || confirmPasswordError) {
      setErrorState({
        passwordError,
        confirmPasswordError
      });
      return false;
    }
    setErrorState({
      passwordError: false,
      confirmPasswordError: false
    });
    return true;
  };
  return (
    <section className="h-full my-auto sm:px-5 w-full sm:flex items-center justify-center">
      <div className="mx-auto my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
        <h3 className="my-4 relative text-xl font-black text-white sm:text-2xl text-center">
          <Link
            to={
              isLoading
                ? "/forgot-password"
                : formData.email
                  ? `/login`
                  : "/login"
            }
            title="go back"
            className="text-white absolute left-[-8px] top-[5px]"
          >
            <IoMdArrowBack />
          </Link>
          Create New Password
        </h3>
        <p className="text-center mt-8 text-sm text-white pb-4">
          Your new password must be different from <br /> previous used passwords.
        </p>
        {error && <div className="flex items-center justify-center gap-2">
          <p className="text-sm text-[#ff5700]">{error}</p>
        </div>}
        <form className=" sm:pt-4 text-white" onSubmit={handleSubmit}>
          <div className="mb-5">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="password"
            >
              Password
            </label>
            <div className="relative">
              <input
                className="w-full placeholder:text-gray-400 appearance-none rounded-full bg-inputbg p-4 text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
                id="password"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="At least 6 characters"
                autoFocus
                onChange={handleChange}
                value={formData?.password}
                disabled={isLoading}
                readOnly={isLoading}
                autoComplete="off"
                maxLength={50}
              />
              <div role="button" tabIndex="0" onClick={() => setShowPassword((prev) => !prev)}
                className="absolute right-[9px] top-[50%] cursor-pointer select-none flex items-center justify-center p-1"
                style={{ transform: "translateY(-50%)" }}
              >
                {showPassword ?
                  <BsFillEyeFill style={{ fontSize: "20px" }} />
                  :
                  <BsFillEyeSlashFill style={{ fontSize: "20px" }} />
                }
              </div>
            </div>
            <p className="text-[14px] pl-2 mt-2">
              Password must be at least 6 characters.
            </p>
            {errorState.passwordError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">This field is required</label>}
          </div>
          <div className="">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="confirmPassword"
            >
              Confirm Password
            </label>
            <input
              className="w-full placeholder:text-gray-400 appearance-none rounded-full bg-inputbg p-4 text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="confirmPassword"
              type="password"
              name="confirmPassword"
              placeholder="Re-type your password"
              onChange={handleChange}
              value={formData?.confirmPassword}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={50}
            />
            <p className="text-[14px] pl-2 mt-2">
              Both passwords must  match.
            </p>
            {errorState.confirmPasswordError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">This field is required</label>}
          </div>
          <button
            disabled={isLoading}
            role="button"
            type="submit"
            className="my-6 block w-full cursor-pointer bg-orange-600 rounded-full p-2 text-center text-[16px] text-white hover:text-white border-0 py-4 disabled:opacity-70 sm:p-3"
          >
            Reset Password
          </button>
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;
