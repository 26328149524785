import { Link } from 'react-router-dom'

// ------------------ Open page no login, Sign up ---------------
const Opening = () => {
    return (
        <section className="h-full sm:px-5 w-full sm:flex flex-1 items-center justify-center">
            <div className="mx-auto text-center my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
                <h3 className="mb-3 text-3xl font-black text-white sm:text-4xl text-center">
                    dreamhoops
                </h3>
                <h4 className="mb-3 font-black text-white text-xl text-center">
                    Check ball.
                </h4>
                <div className="mt-[100px]">
                    <Link
                        to="/login"
                        className="my-6 block w-full no-underline cursor-pointer bg-primary border border-solid border-orange-600  rounded-full p-2 text-center text-[16px] text-white hover:text-white py-4 disabled:opacity-70 sm:p-3"
                    >
                        Log in
                    </Link>
                    <Link
                        to="/register"
                        className="my-6 block w-full no-underline cursor-pointer bg-orange-600 rounded-full p-2 text-center text-[16px] text-white hover:text-white border-0 py-4 disabled:opacity-70 sm:p-3"
                    >
                        Sign up
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Opening