import { useSelector } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
// ---------- util ----------
import { ROLES } from "../utils/constants";
// ---------- Layout wrapper --------------
import WithHeader from "../Layout";
// ---------- Auth routes ---------
import Profile from "../pages/Profile";
import EditProfile from "../pages/EditProfile";
import EditArcheType from "../pages/EditArcheType";
import EditProfile2 from "../pages/EditProfile2";
// ---------- UnAuth routes -----------
import Login from "../pages/Login";
import Register from "../pages/Register";
import RegisterSuccess from "../pages/Register/RegisterSuccess";
import VerifyEmail from "../pages/Register/VerifyEmail";

import ForgotPassword from "../pages/ForgotPassword";
import EmailSent from "../pages//ForgotPassword/EmailSent";
import ResetPassword from "../pages/ResetPassword";
import LeaderBoard from "../pages/Leaderboard";
import GameHistory from "../pages/GameHistory";
// ---------- Other pages ----------
import Home from "../pages/Home";
import Opening from "../pages/Opening";
import NotFoundPage from "../pages/NotFound";
import UnAuthorized from "../pages/UnAuthorized";
// ---------- loader/spinner -----------
import Spinner from "../utils/Spinner";
import Game from "../pages/Game";
import ResetPasswordSuccess from "../pages/ResetPassword/ResetPasswordSuccess";
import LoginSuccess from "../pages/Login/LoginSuccess";
import JoinGame from "../pages/Login/JoinGame";
import ViewProfile from "../pages/Profile/ViewProfile";
import About from "../pages/AboutUs/About";
import Contact from "../pages/ContactUs/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import Community from "../pages/Community";

// ========= This is a app router component which contains all the routes of the websites.
//  with authentication and user roles base routes
const AppRouter = () => {
  return (
    <Routes>
      <Route exact path="/" element={<WithHeader />}>
        {/* ===== Home routes ========== */}
        <Route index exact element={<Home />} />
        <Route path="home" exact element={<Home />} />
        {/* ===== authenticated routes with protected routes ========== */}
        <Route
          exact
          path="profile"
          element={
            <ProtectedRoute redirectPath="/login" roles={[ROLES.Player]}>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="edit-profile"
          element={
            <ProtectedRoute redirectPath="/login" roles={[ROLES.Player]}>
              <EditProfile />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/onboarding/:locationId?"
          element={
            <ProtectedRoute redirectPath="/login" roles={[ROLES.Player]}>
              <EditProfile2 />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="edit-archetype"
          element={
            <ProtectedRoute redirectPath="/login" roles={[ROLES.Player]}>
              <EditArcheType />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="login-success"
          element={
            <ProtectedRoute redirectPath="/login" roles={[ROLES.Player]}>
              <LoginSuccess />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="join-game/:locationId"
          element={
            <ProtectedRoute redirectPath="/login" roles={[ROLES.Player]}>
              <JoinGame />
            </ProtectedRoute>
          }
        />
        {/* unauthenticated route to see other profiles */}
        <Route exact path="profile/:id" element={<ViewProfile />} />
        {/* ====== unauthenticated login, register, forgot-password, reset-password  ======== */}
        <Route exact path="leaderboard" element={<LeaderBoard />} />
        <Route exact path="/login/:locationId?" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/registration-success" element={<RegisterSuccess />} />
        <Route exact path="/verify/:token/:userid" element={<VerifyEmail />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route exact path="/forgot-password-success" element={<EmailSent />} />
        <Route exact path="/reset-password/:id" element={<ResetPassword />} />
        <Route exact path="/reset-password-success" element={<ResetPasswordSuccess />} />
        {/* ===== game related routes ========= */}
        <Route exact path="game-history" element={<GameHistory />} />
        <Route exact path="/game/:locationId" element={
          <ProtectedRoute redirectPath="/login" roles={[ROLES.Manager]}>
            <Game />
          </ProtectedRoute>
        } />
        {/* ===== 404 not found route ========= */}
        <Route path="/unauthorized" element={
          <ProtectedRoute redirectPath="/login" roles={[ROLES.Manager, ROLES.Player]}>
            <UnAuthorized />
          </ProtectedRoute>
        } />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/privacy" element={<PrivacyPolicy />} />
        <Route exact path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/community" element={<Community />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

// This is wrapper component for protected routes & it will also validate role base routing.
const ProtectedRoute = ({ redirectPath = "/", children, roles }) => {
  const { isAuthenticated, user } = useSelector((store) => store.auth);
  let location = useLocation();

  // if auth loading
  // if (isLoading) {
  //   return (
  //     <section className="flex flex-1 items-center justify-center">
  //       <Spinner type="" />
  //     </section>
  //   );
  // }
  // if user is not authenticated
  if (!isAuthenticated) {
    // return <Navigate to={redirectPath} replace state={{ from: location }} />;
    return <Opening />;
  }
  // if user is authenticated but not contains provided roles
  if (roles && roles.indexOf(user.role.Name) == -1) {
    return <Navigate to={"/unauthorized"} replace state={{ from: location }} />;
  }
  return children ? children : <Outlet />;
};

export default AppRouter;
