import React, { useEffect, useState } from 'react'
import { FaMinus, FaSortDown, FaSortUp } from 'react-icons/fa';
import { formatRating, isEven } from '../../utils/helpers';
import axios from '../../libs/http'
import { Link } from "react-router-dom";
import "./style.css"
import Loader from '../../utils/Loader';
import Spinner from '../../utils/Spinner';
// ------------ Leader board page --------
const LeaderBoard = () => {
    let matchmedia = window.matchMedia("(max-width:640px)");
    const [data, setLeaderBoard] = useState([]);
    const [total, setTotal] = useState(0);
    const [currentpage, setCurrentPage] = useState(1)
    const [itemsPerpage, setitemsPerpage] = useState(10);
    const [pagelimit, setpagelimit] = useState(4)
    const [maxpagelimit, setmaxpagelimit] = useState(4)
    const [minpagelimit, setminpagelimit] = useState(0)
    const [pages, setPages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getData(currentpage);
    }, []);
    useEffect(() => {
        window.addEventListener("resize", () => {
            if (matchmedia.matches) {
                setpagelimit(2);
                setmaxpagelimit(2)
            }
            else {
                setpagelimit(4)
                setmaxpagelimit(4)
            }
        })
        return () => window.removeEventListener("resize", {})
    });

    function getData(page) {
        setIsLoading(true);
        axios.get('/players/getRanking/' + page).then(res => {
            setIsLoading(false);
            const { players, total } = res.data;
            players.forEach(x => x.Rating = formatRating(x.Rating));
            let zeroPlayers = players.filter(x => x.Record.Win == 0 && x.Record.Loss == 0);
            let ids = zeroPlayers.map(x=>x.Id);
            let nonZeroPlayers = players.filter(x => ids.indexOf(x.Id) == -1);
            setLeaderBoard([...nonZeroPlayers, ...zeroPlayers]);
            setTotal(total);
            const p = [];
            for (let index = 0; index < Math.ceil(total / itemsPerpage); index++) {
                p.push(index + 1);
            }
            setPages(p);
        });
    }

    const handleClick = (e) => {
        const page = Number(e.target.id)
        setCurrentPage(page);
        getData(page);
    }
    const handleNext = () => {
        const page = currentpage + 1;
        setCurrentPage(page)
        if (page > maxpagelimit) {
            setmaxpagelimit(maxpagelimit + pagelimit);
            setminpagelimit(minpagelimit + pagelimit);
        }
        getData(page);
    }
    const handlePrev = () => {
        const page = currentpage - 1;
        setCurrentPage(page)
        if ((page) % pagelimit == 0) {
            setmaxpagelimit(maxpagelimit - pagelimit);
            setminpagelimit(minpagelimit - pagelimit);
        }
        getData(page);
    }
    let pageIncrementBtn = null;
    if (pages.length > maxpagelimit) {
        pageIncrementBtn = <li onClick={handleNext} className={`w-8 sm:text-2xl text-lg flex justify-center items-center cursor-pointer mr-2 h-8   pagination-btn `}>&hellip;</li>
    }
    let pageDecrementBtn = null;
    if (minpagelimit >= 1) {
        pageDecrementBtn = <li onClick={handlePrev} className={`w-8 sm:text-2xl text-xl flex justify-center items-center cursor-pointer mr-2 h-8   pagination-btn `}>&hellip;</li>
    }

    const RenderPageNumber = pages.map((number) => {
        if (number < maxpagelimit + 1 && number > minpagelimit) {
            return (
                <li key={number} id={number} onClick={handleClick} className={`w-8 flex justify-center items-center cursor-pointer mr-2 h-8  text-lg pagination-btn    ${currentpage == number ? "text-white" : "text-gray-400"}`}> {number}</li >
            )
        }
        else {
            return null
        }
    });

    // sorting function for not any games played
    function sortRecords(a, b) {
        // Check if Record in both objects is { Win: 0, Loss: 0 }
        const isZeroRecordA = a?.Record?.Win === 0 && a?.Record?.Loss === 0;
        const isZeroRecordB = b?.Record?.Win === 0 && b?.Record?.Loss === 0;

        if (isZeroRecordA && !isZeroRecordB) {
            return 1; // Move A to the end
        } else if (!isZeroRecordA && isZeroRecordB) {
            return -1; // Move B to the end
        } else {
            return 0; // Leave the order unchanged
        }
    }

    return (
        <>
            {isLoading && <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
                <Spinner />
                <p className="text-white ml-2">Please wait ...</p>
            </div>}
            <section className="md:h-full h-[1500px]  bg-[#1a1a1a]  w-full sm:flex  items-start justify-center">
                {isLoading && <div className="mx-auto my-auto flex content-center items-center justify-center py-5 px-2 text-center sm:px-4">
                    <Loader />
                </div>}
                <div className="mx-auto pt-4 pb-28 text-center mt-5 w-full bg-[#1a1a1a]">
                    <h3 className="my-2 md:text-3xl text-2xl">Leaderboard</h3>
                    <div className="md:rounded overflow-hidden mx-auto sm:mt-5 mt-3 ">
                        <div className="  ">
                            <div className="flex items-center w-full justify-between py-3">
                                <div className="leaderboardHead flex text-center items-center gap-1 justify-between w-full max-w-[900px] mx-auto whitespace-nowrap">
                                    <p className="w-[95px] sm:text-sm text-[14px] cursor-pointer font-medium">
                                        Rank
                                    </p>
                                    <p className="w-[95px] cursor-pointer sm:text-sm  font-medium">Player Name</p>
                                    <p className="w-[95px] cursor-pointer sm:text-sm font-medium">Record</p>
                                    <p className="w-[95px] cursor-pointer sm:text-sm font-medium">Rating (7d)</p>
                                </div>
                            </div>
                            <div>
                                {data?.map((v, i) => (
                                    <Link to={`/profile/${v.Id}`} target='_blank' key={i}>
                                        <div className={` tableData ${i === 0 ? 'bg-[#000]' : ''} hover:bg-[#004039]  hover:bg-opacity-70 cursor-pointer`}>
                                            <div className="flex items-center justify-between w-full max-w-[900px] mx-auto text-center">
                                                <div className="w-[95px]">
                                                    <p className="flex justify-center">
                                                        <span className="py-3 sm:text-sm text-[12px] flex">
                                                            {v.Rank ? v.Rank : <span className="text-white opacity-60">
                                                                <FaMinus style={{ color: "", fontSize: "12px" }} />
                                                            </span>}
                                                        </span>
                                                    </p>
                                                </div>

                                                <div className=" flex items-center  gap-2 w-[140px]">
                                                    <img width={"20px"} src={v.ArcheType.Icon} alt="" />
                                                    <p className="sm:text-sm text-[14px] whitespace-nowrap border-b-[1px] max-w- border-white w-max">{v.DisplayName}</p>
                                                </div>
                                                <div className="w-[95px]">
                                                    <p className="sm:text-sm text-[14px]">{v.Record.Win} / {v.Record.Loss}</p>
                                                </div>
                                                <div className="w-[95px]">
                                                    <p className="flex justify-center">
                                                        <span className="py-3 sm:text-sm text-[12px] flex">
                                                            {v.Rating}
                                                            {!v.Progress || parseInt(v?.Rating) <= 60 ?
                                                                ''
                                                                :
                                                                v.Progress?.toString()?.toLocaleLowerCase() === "up" ?
                                                                    <span className="text-[#5dff35] pl-2">
                                                                        <FaSortUp style={{ color: "", fontSize: "12px" }} />
                                                                    </span>
                                                                    : <span className="text-red-600 pl-2">
                                                                        <FaSortDown style={{ fontSize: "12px" }} />
                                                                    </span>
                                                            }
                                                            {parseInt(v?.Rating) <= 60 ? null : <span className={`${v.Progress?.toLocaleLowerCase() === "up" ? "text-[#5dff35]" : "text-red-600"} text-[10px]`}>
                                                                {v.RatingDiff ? Math.abs(v.RatingDiff) : ''}
                                                            </span>}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            <div className="w-full md:w-10/12 lg:w-11/12 sm:w-full mx-auto mt-20 py-7 ">
                                <ul className="flex  justify-center px-1 mx-auto  flex-wrap  sm:gap-y-6 gap-y-3  w-full ">
                                    <li >
                                        <button className={`py-1 px-2 flex justify-center items-center cursor-pointer mr-2 h-8      pagination-btn hover:text-white duration-300 text-base `} onClick={handlePrev}
                                            //  disabled={currentpage == pages[0] ? (true, console.log("prev button is eqal")) : false}

                                            disabled={currentpage == pages[0] ? true : null}>

                                            Prev
                                        </button>
                                    </li>
                                    {pageDecrementBtn}

                                    {RenderPageNumber}
                                    {pageIncrementBtn}

                                    <li >
                                        <button className={`py-1 px-2 flex justify-center items-center cursor-pointer mr-2 h-8      pagination-btn hover:text-white duration-300 text-base `} onClick={handleNext} disabled={currentpage === pages.length ? true : null}>

                                            Next
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>

    )
}

export default LeaderBoard