import { Link, NavLink } from "react-router-dom";
import tablet from "../../assets/images/tablet.png";

// ---- DreamhoopsSolution section form home page -----------
const DreamhoopsSolution = () => {
  return (
    <section className="bg-[#1a1919] pb-16">
      <div className="container mx-auto w-full px-4 pt-[50px] sm:w-10/12 sm:px-6 sm:pt-[70px] lg:px-8">
        <div className="mx-auto max-w-[830px] pb-9">
          <h2 className="mb-6 text-left text-3xl font-bold sm:text-center sm:text-3xl md:text-4xl lg:text-[45px]">
            Dreamhoops Solution
          </h2>
          <div className="relative text-center md:my-6   overflow-hidden bg-[#1a1919]">
            {/* <div className="relative text-center md:my-6 border-[13px] border-[#000] rounded-[35px] overflow-hidden bg-[#1a1919]"> */}
            <img className="mx-auto object-contain shadow-xl" src={tablet} alt="Basketball image" />
          </div>
        </div>
        <div className="mx-auto w-full max-w-[750px] rounded-3xl bg-lightBlack py-10 px-4 sm:px-6 lg:px-10">
          <h2 className="text-xl font-medium sm:text-4xl">Welcome to Dreamhoops</h2>
          <p className="py-5 text-base font-medium sm:text-2xl">
            The ultimate digital sign-in system designed to revolutionize the way pickup baseketball games are organized! Our cutting-edge software brings convenience, efficiency, and a whole new level of excitement to the world of basketball.
          </p>
          <p className="py-5 text-base font-medium sm:text-2xl mb-6 ">
            At Dreamhoops, we recognize the challenges that often arise when it comes to organizing these games. That's why we created a platform that combines the best of technologies and basketball, empowering players and enhancing their overall experience
          </p>
          <NavLink className="rounded-3xl bg-[#f55703] px-14 py-3 text-lg font-semibold sm:text-xl" to="/register">LET'S GO!</NavLink>
        </div>
      </div>
    </section>
  );
};

export default DreamhoopsSolution;
