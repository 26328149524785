import { Link } from "react-router-dom";

const DesktopOnly = () => {
  return (
    <section className="flex flex-1 items-center justify-center bg-dark py-4 [@media(min-width:900px)]:hidden">
      <div className="border-3 mx-auto my-auto max-w-[93%] rounded-xl border-solid border-orange-500 bg-primary p-5 shadow-lg sm:my-2 md:max-w-[690px] md:p-8">
        <img width="80" src="/fav.png" alt="Dreamhoops logo" className="mx-auto" />
        <p className="py-3 text-center text-2xl text-gray-100">
          Open in desktop mode <br /> to play the game
        </p>
        <Link
          to="/"
          className="mt-2 block w-full rounded bg-orange-600 py-2 px-4 text-center text-xl font-bold text-white no-underline hover:bg-orange-700 hover:text-white"
        >
          Go Home
        </Link>
      </div>
    </section>
  );
};

export default DesktopOnly;
