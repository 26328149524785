

import React, { useEffect } from 'react';

import signupSuccess from '../../assets/images/signupSuccess.png'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { verifyEmail } from '../../store/auth/authAPI';
import { setSuccess } from '../../store/auth/authSlice';
import Loader from "../../utils/Loader";
import { joinLounge } from '../../store/game/gameAPI';

const JoinGame = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { locationId } = useParams();

    const { isLoading, error, success } = useSelector(
        (state) => state.game
    );
    const { user } = useSelector(
        (state) => state.auth
    );
    useEffect(() => {
        if (success) {
            setTimeout(() => {
                navigate('/');
                dispatch(setSuccess(false));
            }, 5000);
        }
    }, [success]);

    useEffect(() => {
        dispatch(joinLounge({ UserId: user.Id, LocationId: locationId, Name: user.DisplayName }));
    }, []);

    return (
        <section className="h-full sm:px-5 w-full sm:flex flex-1 items-center justify-center">
            {isLoading && <div className="mx-auto my-auto flex content-center items-center justify-center py-5 px-2 text-center sm:px-4">
                <Loader />
            </div>}
            {error && <div className="flex items-center justify-center gap-2">
                <p className="text-sm text-[#ff5700]">{error}</p>
            </div>}
            {success && <div className="mx-auto text-center my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
                <h3 className="mb-3 text-3xl font-black text-white sm:text-4xl text-center">
                    dreamhoops
                </h3>
                <img className="w-full max-w-[260px] my-2 mx-auto" src={signupSuccess} alt="" />
                <p className="text-center text-white pb-6">
                    Joined!
                    <br />
                    {success}
                </p>
            </div>}
        </section>
    )
}

export default JoinGame