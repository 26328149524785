import Hero from "./Hero";
import DreamhoopsSolution from "./Solution";
import CompletedGames from "./CompletedGames";
import RecentGames from "./RecentGames";
import TopPlayers from "./TopPlayers";

//---------------- Home page wrapper ------------------------
const Home = () => {
  return (
    <>
      <Hero />
      <DreamhoopsSolution />
      <RecentGames />
      <TopPlayers />
    </>
  );
};

export default Home;
