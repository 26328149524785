import { useState, useEffect } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { setIsCountDownStarted } from "../../store/game/gameSlice";
import { useDispatch , useSelector } from "react-redux";
import { themes } from "./theme";


function CountDown({onComplete}) {
  const { user } = useSelector((store) => store.auth);
  // const colorLoad = '#ff5700'
  // const colorLoadtext = '[#ff5700]'
  const colorLoad= themes[user.theme].startLoaderClr;
  const colorLoadtext = themes[user.theme].startLoaderClrText

  const dispatch = useDispatch();
  const [timerKey, setTimerKey] = useState(0);
  const [textCountdown, setTextCountdown] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      if (textCountdown > 0) {
        setTextCountdown((prevCountdown) => prevCountdown - 1);
      }
      if (textCountdown === 0) {
        setTextCountdown(3);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [textCountdown]);

  const colors = [`${colorLoad}`, `${colorLoad}`, `${colorLoad}`];

  return (
    <div className="countdown absolute top-[42%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-full bg-white">
      <CountdownCircleTimer
        key={timerKey}
        isPlaying
        duration={3}
        colors={[colors[textCountdown - 1 ]]}
        isSmoothColorTransition
        onComplete={() => {
          dispatch(setIsCountDownStarted(false));
          setTimerKey((prevKey) => prevKey + 1); // Change timerKey to re-render the timer
          onComplete()
        }}
      >
        {({ remainingTime }) => (
          <div className="timer">
            <span className={`time text-6xl font-bold text-${colorLoadtext}`}>{textCountdown}</span>
          </div>
        )}
      </CountdownCircleTimer>
    </div>
  );
}

export default CountDown;
