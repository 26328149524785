import { useState } from "react";
import logo from "../../assets/images/dh-underline-green.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { toast } from "../../utils/customToast";
// icons
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosLogOut } from "react-icons/io";
// css
import "./header.css";
import { clearLounge, logoutUser } from "../../store/auth/authAPI";
import { db } from '../../libs/db';
import { ROLES } from "../../utils/constants";


// -------------- Header component ====-----------
const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, isAuthenticated, isLoading } = useSelector((store) => store.auth);
  const { gameMode } = useSelector((store) => store.game);
  const [menuOpen, setMenuOpen] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);

  // scroll to related section
  const scrollTo_Section = (sec_id) => {
    if (sec_id === "home") {
      window.scrollTo(0, 0);
      return;
    }
    let element = document.getElementById(sec_id);
    var yOffset = -50;

    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  // close menu
  const closeMenu = () => {
    setMenuOpen(false);
  };

  // sign out
  const logout = () => {
    if (user.role.Name == ROLES.Manager) {
      dispatch(clearLounge(user.LocationId));
    }
    db.activity.clear();
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(logoutUser(user.Email));
    closeMenu();
    setProfileMenu(false);
    navigate('/', { replace: true });
  };

  return (
    <header
      className={`web-header ${
        // pathname?.includes("edit-profile") || pathname?.includes("edit-archetype") ? "bg-[#011f19]" : ""
        pathname?.includes("edit-profile") || pathname?.includes("edit-archetype") ? "" : ""
        } ${gameMode ? 'hidden' : ''}`}
    >
      <div className="mx-auto w-full max-w-[1400px]">
        <div className="header_row flex items-center justify-between relative">
          <div className="header_logo  ">
            <Link to="/">
              <img
                className=""
                // className="max-w-full h-[100px] w-[100px]  object-cover"
                // className="max-w-full md:h-[70px] h-14 object-contain"
                onClick={() => {
                  scrollTo_Section("home");
                  closeMenu();
                }}
                src={logo}
                alt="Dreamhoops"
              />
            </Link>
          </div>
          {/* flex-col from below nav  */}
          <nav className={`header_nav flex flex-col lg:flex-row  lg:justify-end justify-start lg:items-center ${menuOpen ? "menu_opened" : ""}`}>
            <div className="mobile_profile bg-white">
              {isAuthenticated && !isLoading ? (
                <div className="nav-profile flex w-full bg-white overflow-hidden">
                  <div className="flex h-9 min-h-[2.25rem] w-9 min-w-[2.25rem] items-center justify-center overflow-hidden rounded-full bg-gray-400">
                    <img
                      className="h-auto max-h-full w-max max-w-full object-contain"
                      src={user.ArcheType.Icon}
                      alt=""
                    />
                  </div>
                  <div className="pl-2">
                    <p className="max-w-full overflow-hidden text-ellipsis text-lg font-medium">{user.FirstName}</p>
                    <div className="w-[95%]  truncate overflow-hidden">

                      <p className="-mt-1 pt-1 w-7/12  overflow-hidden text-ellipsis text-sm font-medium text-black ">
                        {user.Email}
                      </p>
                    </div>
                    <NavLink
                      to="/profile"
                      className={({ isActive }) => {
                        return `text-xs ${isActive ? "font-medium text-primary" : "text-gray-500"} no-underline`;
                      }}
                      onClick={() => {
                        setProfileMenu(false);
                        closeMenu();
                      }}
                    >
                      View profile
                    </NavLink>
                  </div>
                </div>
              ) : null}
              <span role="button" onClick={closeMenu} className="cta-btn menu-btn cursor-pointer">
                <AiOutlineClose />
              </span>
            </div>
            {isAuthenticated && !isLoading ? null : <div className="block min-h-[56px] w-full lg:hidden"></div>}
            <ul className="flex items-center justify-end">
              {!isAuthenticated ? (
                <div className="block w-full lg:hidden">
                  <li>
                    <NavLink
                      to="/login"
                      className={({ isActive }) => {
                        return `navlink px-10 ${isActive ? "active-navlink" : ""} register-btn`;
                      }}
                      onClick={closeMenu}
                    >
                      Sign in
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/register"
                      className={({ isActive }) => {
                        return `navlink px-10 ${isActive ? "active-navlink" : ""} register-btn`;
                      }}
                      onClick={closeMenu}
                    >
                      Sign up
                    </NavLink>
                  </li>
                </div>
              ) : null}
              <li>
                <NavLink
                  to="/community"
                  className={({ isActive }) => {
                    return `navlink mx-0 lg:mx-3  ${isActive ? "active-navlink" : ""}`;
                  }}
                  onClick={closeMenu}
                >
                  Community
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/leaderboard"
                  className={({ isActive }) => {
                    return `navlink mx-0 lg:mx-3  ${isActive ? "active-navlink" : ""}`;
                  }}
                  onClick={closeMenu}
                >
                  Leaderboard
                </NavLink>
              </li>

              <li>
                <NavLink
                  to="/about"
                  className={({ isActive }) => {
                    return `navlink mx-0 lg:mx-3 ${isActive ? "active-navlink" : ""}`;
                  }}
                  onClick={closeMenu}
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/contact"
                  className={({ isActive }) => {
                    return `navlink mx-0 lg:mx-3 ${isActive ? "active-navlink" : ""}`;
                  }}
                  onClick={closeMenu}
                >
                  Contact Us
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/profile"
                  className={({ isActive }) => {
                    return `navlink mx-0 lg:mx-3 ${isActive ? "active-navlink" : ""}`;
                  }}
                  onClick={closeMenu}
                >
                  Profile
                </NavLink>
              </li> */}
              {!isAuthenticated ? (
                <div className="hidden items-center justify-center lg:flex">
                  <li>
                    <NavLink
                      to="/login"
                      className={({ isActive }) => {
                        return `navlink rounded-full border-2 border-[#00423b] px-10 ${isActive ? "active-navlink" : ""
                          }`;
                      }}
                      onClick={closeMenu}
                    >
                      Sign in
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/register"
                      className={({ isActive }) => {
                        return `navlink rounded-full border-2 border-[#00423b] bg-[#00423b] text-white px-10 ${isActive ? "active-navlink" : ""
                          }`;
                      }}
                      onClick={closeMenu}
                    >
                      Sign up
                    </NavLink>
                  </li>
                </div>
              ) : null}
            </ul>

            {isAuthenticated && (
              <ul className=" mt-auto lg:mt-0 lg:hidden hidden w-32 signout-btn">
                {/* <ul className=" block mt-auto lg:mt-0 lg:hidden w-max bg-red-400">  */}
                <li>
                  <button
                    className="ml-0 flex w-max cursor-pointer  place-items-center border-0 bg-transparent py-1 pr-4 text-left text-lg font-medium text-black "
                    // className="ml-8 flex w-max cursor-pointer place-items-center border-0 bg-transparent py-1 pr-4 text-left text-lg font-medium text-black"
                    onClick={logout}
                  >
                    <IoIosLogOut style={{ fontSize: "28px", marginRight: "6px" }} /> Sign out
                  </button>
                </li>
              </ul>
            )}

            <div className="header_right   flex items-center justify-end ">
              {isAuthenticated && !isLoading ? (
                <ClickAwayListener onClickAway={() => setProfileMenu(false)}>
                  <div className="nav-profile relative hidden w-full px-4 lg:flex">
                    <div
                      onClick={() => setProfileMenu((prev) => !prev)}
                      role="button"
                      className="flex h-9 w-9 cursor-pointer select-none items-center justify-center overflow-hidden rounded-full bg-gray-400"
                    >
                      <img
                        className="h-auto max-h-full w-max max-w-full object-contain"
                        src={user.ArcheType.Icon}
                        alt=""
                      />
                    </div>
                    {profileMenu && (
                      <div className="absolute right-[.85rem] top-[58px] min-w-[240px] max-w-[269px] space-y-[10px] rounded bg-white py-[17px] px-4 shadow-lg">
                        <div className="menu-arrow" />
                        <div className="flex">
                          <div className="flex h-11 w-11 items-center justify-center overflow-hidden rounded-full bg-gray-400">
                            <img
                              className="h-auto max-h-full w-max max-w-full object-contain"
                              src={user.ArcheType.Icon}
                              alt=""
                            />
                          </div>
                          <div className="pl-3 text-black">
                            <p className="text-lg font-medium">{user.FirstName}</p>
                            <p className="-mt-1 text-sm font-medium text-black">{user.Email}</p>
                            <NavLink
                              to="/profile"
                              onClick={() => {
                                setProfileMenu(false);
                                closeMenu();
                              }}
                              className={({ isActive }) => {
                                return `text-xs ${isActive ? "font-medium text-primary" : "text-gray-500"} no-underline`;
                              }}
                            >
                              View profile
                            </NavLink>
                            <div className="block w-full">
                              <button
                                className="mt-3 flex w-max cursor-pointer place-items-center border-0 bg-transparent py-1 text-left text-[17px] font-medium text-black"
                                onClick={logout}
                              >
                                <IoIosLogOut style={{ fontSize: "17px", marginRight: "5px" }} /> Sign out
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              ) : null}
            </div>
          </nav>
          {/* <div className="header_right   flex items-center justify-end bg-red-400">
          {isAuthenticated && !isLoading ? (
            <ClickAwayListener onClickAway={() => setProfileMenu(false)}>
              <div className="nav-profile relative hidden w-full px-4 lg:flex">
                <div
                  onClick={() => setProfileMenu((prev) => !prev)}
                  role="button"
                  className="flex h-9 w-9 cursor-pointer select-none items-center justify-center overflow-hidden rounded-full bg-gray-400"
                >
                  <img
                    className="h-auto max-h-full w-max max-w-full object-contain"
                    src={user?.archeType?.icon || "/avatar.png"}
                    alt=""
                  />
                </div>
                {profileMenu && (
                  <div className="absolute right-[.85rem] top-[58px] min-w-[240px] max-w-[269px] space-y-[10px] rounded bg-white py-[17px] px-4 shadow-lg">
                    <div className="menu-arrow" />
                    <div className="flex">
                      <div className="flex h-11 w-11 items-center justify-center overflow-hidden rounded-full bg-gray-400">
                        <img
                          className="h-auto max-h-full w-max max-w-full object-contain"
                          src={user?.archeType?.icon || "/avatar.png"}
                          alt=""
                        />
                      </div>
                      <div className="pl-3 text-black">
                        <p className="text-lg font-medium">{user?.userName}</p>
                        <p className="-mt-1 text-sm font-medium text-black">{user?.email}</p>
                        <NavLink
                          to="/profile"
                          onClick={() => {
                            setProfileMenu(false);
                            closeMenu();
                          }}
                          className={({ isActive }) => {
                            return `text-xs ${isActive ? "font-medium text-primary" : "text-gray-500"} no-underline`;
                          }}
                        >
                          View profile
                        </NavLink>
                        <div className="block w-full">
                          <button
                            className="mt-3 flex w-max cursor-pointer place-items-center border-0 bg-transparent py-1 text-left text-[17px] font-medium text-black"
                            onClick={logoutUser}
                          >
                            <IoIosLogOut style={{ fontSize: "17px", marginRight: "5px" }} /> Sign out
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ClickAwayListener>
          ) : null}
        </div> */}
          <span
            role="button"
            onClick={() => setMenuOpen((prev) => !prev)}
            className="cta-btn menu-btn flex cursor-pointer items-center justify-center text-black text-2xl"
          >
            <FiMenu />
          </span>
          {menuOpen ? <div onClick={closeMenu} className="header_backdrop"></div> : null}
        </div>
      </div>
    </header>
  );
};

export default Header;
