import { createSlice } from '@reduxjs/toolkit';
import {
  signIn,
  register,
  forgotPassword,
  resetPassword,
  logoutUser,
  verifyEmail,
  editArcheType,
  editAccountOnBoarding
} from './authAPI';
import { toast } from '../../utils/customToast';

let isAuth = localStorage.getItem('token');
let User = JSON.parse(localStorage.getItem('user'));
const initialState = {
  isAuthenticated: isAuth && User ? true : false,
  user: User ? User : {},
  error: '',
  success: false,
  isLoading: false,
  emailVerified: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSuccess: (state, action) => {
      state.success = false;
      state.error = '';
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.success = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
      state.error = '';
    }
  },
  extraReducers: {
    [signIn.pending]: (state, action) => {
      state.isLoading = true;
      state.username = action.meta.arg.Username;
      state.password = action.meta.arg.Password;
    },
    [signIn.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.Errors
        ? action.payload.Errors.join(',')
        : 'Network Error';
    },
    [signIn.fulfilled]: (state, action) => {
      state.error = '';
      const { user, token, role } = action.payload;
      state.isLoading = false;
      state.user = user;
      state.user.CreatedOn = new Date(user.CreatedOn).toDateString();
      state.user.role = role;
      state.token = token;
      state.isAuthenticated = true;
      state.user.theme = user.Theme; //24hours, la
      localStorage.setItem('user', JSON.stringify(state.user));
      localStorage.setItem('token', token);
    },
    [forgotPassword.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    },
    [forgotPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.Errors
        ? action.payload.Errors.join(',')
        : 'Network Error';
    },
    [forgotPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.success = 'A verification link has been sent to your email address';
    },
    [resetPassword.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    },
    [resetPassword.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.Errors
        ? action.payload.Errors.join(',')
        : action.payload || 'Network Error';
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.success = true;
    },
    [logoutUser.pending]: (state, action) => {
      state.isLoading = true;
    },
    [logoutUser.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = {};
      state.token = '';
      state.error = '';
      // Remove login user from session storage
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
    [logoutUser.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.user = {};
      state.token = '';
      state.error = '';
      // Remove login user from session storage
      localStorage.removeItem('user');
      localStorage.removeItem('token');
    },
    [register.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    },
    [register.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.Errors
        ? action.payload.Errors.join(',')
        : action.payload || 'Network Error';
    },
    [register.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.success = true;
    },
    [verifyEmail.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    },
    [verifyEmail.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload?.Errors
        ? action.payload.Errors.join(',')
        : 'Network Error';
    },
    [verifyEmail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.success = 'Congratulation! Your email account has been verified. Login to get started.';
    },
    [editArcheType.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    },
    [editArcheType.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload?.Errors
        ? action.payload.Errors.join(',')
        : 'Network Error';
      toast.error('Some error occurred while editing profile');
    },
    [editArcheType.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.success = true;
      const { ArcheType, FirstName, DisplayName, GroupName, DisplayStatistics } = action.payload;
      state.user.ArcheType = ArcheType;
      state.user.FirstName = FirstName;
      state.user.DisplayName = DisplayName;
      state.user.GroupName = GroupName;
      state.user.DisplayStatistics = DisplayStatistics;
      localStorage.setItem('user', JSON.stringify(state.user));
      toast.success('Profile updated!');
    },
    [editAccountOnBoarding.pending]: (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    },
    [editAccountOnBoarding.rejected]: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload?.Errors
        ? action.payload.Errors.join(',')
        : 'Network Error';
    },
    [editAccountOnBoarding.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.user.IsAccountOnBoarded = true;
      localStorage.setItem('user', JSON.stringify(state.user));
    }
  }
});

export const {
  setSuccess,
  setError,
  setLoading
} = authSlice.actions;

export default authSlice.reducer;
