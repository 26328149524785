import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader from "../../utils/Loader";
import LoginSuccess from "./LoginSuccess"
import { signIn } from "../../store/auth/authAPI";
import { ROLES } from "../../utils/constants";

// ***** Login page ********
const Login = () => {
  const { locationId } = useParams();
  console.log('locationid:' + locationId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, error, user } = useSelector((store) => store.auth);
  let initial = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(initial);
  const [errorState, setErrorState] = useState({
    emailError: false,
    passwordError: false
  });
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const validate = () => {
    let emailError = false;
    let passwordError = false;

    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;


    if (!formData.password) {
      passwordError = true;
    }

    if (reg.test(formData.email) === false) {
      emailError = true;
    }

    if (emailError || passwordError) {
      setErrorState({
        emailError,
        passwordError
      });
      return false;
    }
    setErrorState({
      emailError: false,
      passwordError: false
    });
    return true;
  };
  // Call logged in method API
  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (validate()) {
      const { email, password } = formData;
      dispatch(signIn({ Username: email.toLowerCase(), Password: password }));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user.role.Name == ROLES.Manager) {
        navigate("/game/" + user.LocationId);
      }
      else if (!user.IsAccountOnBoarded) {
        let url = '/onboarding';
        if (locationId) {
          url = `${url}/${locationId}`;
        }
        navigate(url);
      }
      else if (locationId) {
        navigate('/join-game/' + locationId);
      }
      else {
        navigate("/login-success");
      }
    }
  }, [isAuthenticated]);


  return (
    <section className="h-full my-auto sm:px-5 w-full sm:flex items-center justify-center">
      <div className="mx-auto my-3 w-full bg-primary px-6 py-7 sm:min-w-[385px] max-w-[426px] sm:px-12 sm:py-9">
        <h3 className="my-4 text-3xl font-black text-white sm:text-4xl text-center">
          dreamhoops
        </h3>
        <p className="text-center text-white pb-6">
          Clock in.
        </p>
        {error && <div className="flex items-center justify-center gap-2">
          <p className="text-sm text-[#ff5700]">{error}</p>
        </div>}
        <form className="sm:pt-4 text-white" onSubmit={handleSubmit}>
          <div className="mb-5">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full appearance-none rounded-full bg-inputbg p-4 text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="email"
              type="text"
              name="email"
              autoFocus
              onChange={handleChange}
              value={formData?.email}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
              maxLength={50}
            />
            {errorState.emailError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">Valid email is required</label>}

          </div>
          <div className="">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="w-full appearance-none rounded-full bg-inputbg p-4 text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="password"
              type="password"
              name="password"
              onChange={handleChange}
              value={formData?.password}
              disabled={isLoading}
              readOnly={isLoading}
              autoComplete="off"
            />
            {errorState.passwordError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">This field is required</label>}

          </div>
          <div>
            <Link
              to={
                isLoading
                  ? "/login"
                  : formData.email
                    ? `/forgot-password?email=${formData.email}`
                    : "/forgot-password"
              }
              className="text-sm pl-2 mt-2 inline-block text-white underline"
            >
              Forgot Password?
            </Link>
          </div>
          <button
            disabled={isLoading}
            role="button"
            title={isLoading ? "Loading..." : "Sign in"}
            type="submit"
            className="my-6 block w-full cursor-pointer bg-orange-600 rounded-full p-2 text-center text-[16px] text-white hover:text-white border-0 py-4 disabled:opacity-70 sm:p-3"
          >
            {isLoading ? <Loader /> : "Log in to Dreamhoops"}
          </button>
          <p className="text-sm text-center">
            No account?
            <Link
              to={isLoading ? "/login" : "/register"}
              role="button"
              title="Create your account"
              className="inline pl-1 font-normal text-white underline hover:text-white"
            >
              Sign Up here
            </Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default Login;
