import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as CrossIcon } from "../assets/images/icons/cross.svg";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { themes } from "../pages/Game/theme";


const MyDialog = ({ isOpen, setIsOpen, title, children, rootClasses}) => {

  const { user } = useSelector((store) => store.auth);
  const diaButtonsBg = themes[user.theme].dialogs.buttons.backgroundColor;
  const bgDialogHeader = themes[user.theme].dialogs.bgHeader;


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center" style={{marginTop: '13%' }}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={`w-full max-w-xl p-3 ${rootClasses ? rootClasses : ""}`}>
                <div className="rounded-2xl bg-white">
                  <Dialog.Title
                    as={"div"}
                    className={`relative flex items-center justify-center rounded-tl-2xl ${bgDialogHeader} rounded-tr-2xl py-4 px-2 text-center text-2xl font-medium uppercase text-white`}
                  >
                    <h3>{title}</h3>
                    <CrossIcon
                      className="absolute right-3 aspect-square h-5 w-5 cursor-pointer [&_*]:stroke-white"
                      onClick={() => setIsOpen(false)}
                    />
                  </Dialog.Title>

                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
export default MyDialog;
