import React from "react";

const TermsAndConditions = () => {
  const privacyPolicy = [
    {
      title: "Introduction",
      text: "DreamHoops.com is committed to protecting the privacy of its users. This Agreement outlines the terms and conditions under which DreamHoops.com collects, uses, and protects the personal information provided by its users.",
    },
    {
      title: "Personal Information 2.1. ",
      text: "DreamHoops.com may collect personal information such as full name and email address when you voluntarily submit it through the Website's registration or contact forms. 2.2. By providing your personal information, you acknowledge and consent to DreamHoops.com collecting, storing, and using the information in accordance with this Agreement and applicable privacy laws. 2.3. DreamHoops.com will never sell or share your personal information with any third parties without your explicit consent, except as required by law or as described in this Agreement.",
    },
    {
      title: "Use and Protection of Personal Information 3.1. ",
      text: "DreamHoops.com may use your personal information for the following purposes: a) To provide and improve the services offered on the Website. b) To respond to your inquiries and communicate with you regarding your use of the Website. c) To send you promotional materials and updates about DreamHoops.com, provided you have given your explicit consent. 3.2. DreamHoops.com will take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. 3.3. DreamHoops.com may disclose your personal information if required to do so by law or in good faith belief that such action is necessary to comply with legal obligations or protect the rights, property, or safety of DreamHoops.com, its users, or others.",
    },
    {
      title: "Cookies and Tracking Technologies 4.1. ",
      text: "DreamHoops.com may use cookies and other tracking technologies to enhance your user experience on the Website. These technologies collect certain non-personally identifiable information about your usage patterns and preferences. 4.2. You have the option to disable cookies through your web browser settings. However, please note that certain features of the Website may not function properly if cookies are disabled.",
    },
    {
      title: "Third-Party Links ",
      text: "The Website may contain links to third-party websites or services that are not operated or controlled by DreamHoops.com. DreamHoops.com is not responsible for the privacy practices or content of such third-party websites. We encourage you to review the privacy policies of those websites before providing any personal information.",
    },
    {
      title: "Changes to this Agreement ",
      text: "DreamHoops.com reserves the right to modify or update this Agreement at any time without prior notice. The updated Agreement will be effective immediately upon posting on the Website. It is your responsibility to review this Agreement periodically for any changes. Continued use of the Website after any modifications signify your acceptance of the updated Agreement.",
    },
    {
      title: "Disclaimer of Liability ",
      text: "DreamHoops.com does not guarantee the accuracy, completeness, or timeliness of any information on the Website. You acknowledge and agree that your use of the Website is at your own risk, and DreamHoops.com shall not be held liable for any direct, indirect, incidental, consequential, or exemplary damages resulting from your use of the Website or any content therein",
    },
  ];

  return (
    <div className="p-4 sm:p-8 bg-white padding-top ">
      <div className="mb-12">
        <h1 className="font-bold mb-3 text-xl sm:text-3xl text-[#00352e]">DreamHoops.com Terms and Conditions</h1>
        <p className="w-[90%] md:w-[80%] text-sm sm:text-base text-[#000] ">
          These Terms and Conditions ("Agreement") govern your use of the DreamHoops.com website ("Website"). By
          accessing or using the Website, you signify your acceptance of this Agreement. If you do not agree to this
          Agreement, please refrain from using the Website.
        </p>
      </div>
      <div>
        {privacyPolicy.map((item) => (
          <div className="flex flex-col mb-8">
            <h3 className="font-bold mb-1 text-base sm:text-xl text-[#00352e]">{item.title}</h3>
            <p className="w-[90%] md:w-[80%] text-sm sm:text-base text-[#000]">{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;
