import GameRecords from '../../components/GameRecords'


// -------------------- Game History page ------------------
const GameHistory = () => {
    return (
        <section className="h-full sm:px-5 w-full sm:flex flex-1 items-start justify-center">
            <div className="mx-auto text-center mt-5 w-full bg-primary sm:min-w-[385px] max-w-[426px]">
                <h3 className='font-black my-2 md:text-3xl text-2xl'>Game History</h3>
                <div className="border border-solid w-max px-3 mx-auto border-orange-400 rounded-full overflow-hidden">
                    <select name="" id="" className="py-2 pr-1 text-orange-400 bg-primary focus:outline-none outline-none cursor-pointer select-none bg-transparent shadow-none border-r-5 border-transparent text-[14px]">
                        <option value="">
                            Long Beach - Bellflower
                        </option>
                        <option value="">
                            Lorem - ispsum
                        </option>
                        <option value="">
                            Lorem - ispsum
                        </option>
                    </select>
                </div>
                <div className="md:bg-[#011f19] md:rounded overflow-hidden sm:mt-5 mt-3">
                    <GameRecords />
                </div>
            </div>
        </section>
    )
}

export default GameHistory