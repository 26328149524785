export const themes = {
  "dh": {
    tooltip: {
      introSteps: [
        {
          title: "WELCOME TO DREAMHOOPS",
          content:
            "A simple but effective game management tool that will assist in creating the dream pickup basketball environment. Scan the QR code when you arrive to let everyone know you’ve got next. Win games to earn points towards your rating and see where you stack up against the best hoopers in your community!",
          placement: "center",
          target: "body",
          styles: {
            custom: {
              maxWidth: 512,
            },
          },
        },
        {
          title: "JOIN PLAYERS LOUNGE",
          content:
            "Scan the QR code to create an account or log into the player’s lounge with your credentials. Don’t have a device with you? Press Join to login as a guest player for the day.",
          target: "#qr-code",
          placement: "left",
        },
        {
          title: "SHUFFLE PLAYERS",
          content: "Use this button to randomize the players on the court.",
          target: "#shuffle",
          placement: "right",
        },
        {
          title: "REMOVE PLAYER",
          content: "Drag and drop any player card to remove from the player's lounge",
          target: "#sub",
          placement: "left",
        },
        {
          title: "SUBSTITUTIONS",
          content:
            "During a game, use the SUB button to substitute players from the lounge as needed. *Subbed players will maintain their position in the lounge following a loss*",
          target: "#rmv-player",
          placement: "left",
        },
        {
          title: "AUDIT LOG",
          content: "Review the audit log to track all activity in the lobby as it happens",
          target: "#audit",
          placement: "left",
        },
      ],
      nextButton: {
        backgroundColor: "bg-[#ff5700]",
        color: "black",
        text: "Next",
      },
      joinPlayerBorder: {
        left: "973px",
        height: "379px",
        width: "276px",
        top: "740px",
      },
      introStepsIcon: "/assets/images/game/info.png",
    },

    gameMode: {
      startButton: {
        backgroundColor: "bg-[#ff5700]",
        color: "white",
      },
      backgroundImage: "/assets/images/game/bg.png",
      logo: "",
      text: "",
      iconColor: "",
      subButtonClr: "text-white",
      resumeButton: {
        textClr: "text-[#ff8900]",
        text: "RESUME GAME",
      },
    },
    dialogs: {
      buttons: {
        backgroundColor: "bg-[#ff5700]",
        color: "white",
      },
      bgHeader: "bg-[#00423b]",
      dialogEndGame: {
        homeAwayBg: "bg-[#ff5700]",
        borderClr: "border-[#ff5700]",
        bordertext: "text-[#ff5700]",
        bordertextActive: "text-white",
      },
    },
    lounge: {
      backgroundColor: "bg-[#00423b]",
      lobbyButton: {
        backgroundColor: "bg-[#ff5700]",
        color: "white",
        text: "JOIN (GUEST)",
      },
      playersHeading: "Player's Lounge",
      userBg: "bg-[#f0e5cc]",
    },
    site: {
      siteColor: "#00423b",
      siteName: "www.dreamhoops.com",
    },
    card: {
      cardIcons: "/assets/images/game/png/Asset 11.png",
      activeCard: '/assets/images/themes/LA-24/user_dh.png'

    },
    footer: {
      footHead: {
        homeText: "HOME",
        awayText: "AWAY",
        bgClr: "bg-[#00423b]",
      },
      footBody: {
        bodyBg: "bg-[#f0e5cc]",
        bgGrey: "bg-[#dac8a4]",
      },
    },
    loaderImgSm: "/logo.png",
    startLoaderClr: '#ff5700',
    startLoaderClrText: '[#ff5700]'

  },
  "la": {
    tooltip: {
      introSteps: [
        {
          title: "BASKETBALL SIGN IN",
          content: `Welcome to our pickup basketball digital sign in system! \n
                We recognize the challenges that often arise when organizing pickup games. This tool empowers the players and provides a fair opportunity for all who want to play. Join the lobby to let everyone know you've got next!`,

          placement: "center",
          target: "body",
          styles: {
            custom: {
              maxWidth: 500,
              whiteSpace: "pre-line",
            },
          },
        },
        {
          title: "JOIN PLAYERS LOUNGE",
          content: "Press Join Lobby and enter your name to reserve your spot in the next game!",
          target: "#join-btn",
          placement:'top'
        },
        {
          title: "REMOVE PLAYER",
          content: (
            <div>
              <h3 className="text-xs">Drag and drop any player card to remove from the player's lounge</h3>
              <img src="/assets/images/themes/LA-24/Asset 3-lafitness.png" alt="" />
            </div>
          ),
          target: "#rmv-player",
          placement: "left",
          whiteSpace: "preline",
          styles: {
            custom: {
              maxWidth: 310,
            },
          },
        },
        {
          title: "SUBSTITUTIONS",
          content: (
            <div>
              <h3 className="text-xs">
                During a game, use the SUB button to substitute players from the lounge as needed .*Subbed players will
                maintain their position in the lounge following a loss*
              </h3>
              <img src="/assets/images/themes/LA-24/Asset 4-lafitness.png" alt="" />
            </div>
          ),
          target: "#shuffle",
          styles: {
            custom: {
              maxWidth: 900,
              whiteSpace: "pre-line",
            },
          },
          placement: "center",
        },
        {
          title: "AUDIT LOG",
          content: "Review the audit log to track all activity in the lobby as it happens",
          target: "#audit",
          placement: "left",
        },
      ],
      nextButton: {
        backgroundColor: "bg-[#fcba0c]",
        color: "#142665",
        text: "Finish",
      },
      joinPlayerBorder: {
        right:'30px',
        left: "revert",
        height: "65px",
        width: "257px",
        top: "760px",
      },
      introStepsIcon: "/assets/images/themes/LA-24/Info_LA.png",
    },

    gameMode: {
      startButton: {
        backgroundColor: "bg-[#fcba0c]",
        color: "#142665",
      },
      backgroundImage: "/assets/images/themes/LA-24/bg_LA.png",
      logo: "",
      text: "",
      iconColor: "",
      subButtonClr: "text-[#142665]",
      resumeButton: {
        textClr: "text-[#142665]",
        text: "RESUME",
      },
    },
    dialogs: {
      buttons: {
        backgroundColor: "bg-[#fcba0c]",
        color: "#142665",
      },
      bgHeader: "bg-[#142665]",
      dialogEndGame: {
        homeAwayBg: "bg-[#fcba0c]",
        borderClr: "border-[#fcba0c]",
        bordertext: "text-[#142665]",
        bordertextActive: "text-[#142665]",
      },
    },
    lounge: {
      backgroundColor: "bg-[#142665]",
      lobbyButton: {
        backgroundColor: "bg-[#fcba0c]",
        color: "#142665",
        text: "JOIN LOBBY",
      },
      playersHeading: "Players Lounge",
      userBg: "bg-[#e2e2e2]",
    },
    site: {
      siteColor: "#142665",
      siteName: "www.lafitness.com",
    },
    card: {
      cardIcons: "/assets/images/themes/LA-24/user_plus_LA.png",
      activeCard: '/assets/images/themes/LA-24/user_LA.png'

    },
    footer: {
      footHead: {
        homeText: "HOME",
        awayText: "AWAY",
        bgClr: "bg-[#142665]",
      },
      footBody: {
        bodyBg: "bg-[#ffff]",
        bgGrey: "bg-[#e2e2e2]",
      },
    },
    loaderImgSm: "/assets/images/themes/LA-24/LAF_logo_2C_H.png",
    startLoaderClr: '#142665',
    startLoaderClrText: '[#142665]'
  },
  "24hours": {
    tooltip: {
      introSteps: [
        {
          title: "BASKETBALL SIGN IN",
          content: `Welcome to our pickup basketball digital sign in system! \n
                We recognize the challenges that often arise when organizing pickup games. This tool empowers the players and provides a fair opportunity for all who want to play. Join the lobby to let everyone know you've got next!`,

          placement: "center",
          target: "body",
          styles: {
            custom: {
              maxWidth: 500,
              whiteSpace: "pre-line",
            },
          },
        },
        {
          title: "JOIN PLAYERS LOUNGE",
          content: "Press Join Lobby and enter your name to reserve your spot in the next game!",
          target: "#join-btn",
          placement: "top",
        },
        {
          title: "REMOVE PLAYER",
          content: (
            <div>
              <h3 className="text-xs">Drag and drop any player card to remove from the player's lounge</h3>
              <img src="/assets/images/themes/LA-24/Asset 1-24hour.png" alt="" />
            </div>
          ),
          target: "#rmv-player",
          placement: "left",
          whiteSpace: "preline",
          styles: {
            custom: {
              maxWidth: 310,
            },
          },
        },
        {
          title: "SUBSTITUTIONS",
          content: (
            <div>
              <h3 className="text-xs">
                During a game, use the SUB button to substitute players from the lounge as needed .*Subbed players will
                maintain their position in the lounge following a loss*
              </h3>
              <img src="/assets/images/themes/LA-24/Asset 2-24hour.png" alt="" />
            </div>
          ),
          target: "#shuffle",
          styles: {
            custom: {
              maxWidth: 900,
              whiteSpace: "pre-line",
            },
          },
          placement: "center",
        },
        {
          title: "AUDIT LOG",
          content: "Review the audit log to track all activity in the lobby as it happens",
          target: "#audit",
          placement: "left",
        },
      ],
      joinPlayerBorder: {
        right:'30px',
        left: "revert",
        height: "65px",
        width: "257px",
        top: "760px",
      },
      nextButton: {
        backgroundColor: "bg-black",
        color: "white",
        text: "Finish",
      },
      introStepsIcon: "/assets/images/themes/LA-24/Info_24.png",
    },
    gameMode: {
      startButton: {
        backgroundColor: "bg-black",
        color: "white",
      },
      backgroundImage: "/assets/images/themes/LA-24/bg_24.png",
      logo: "",
      text: "",
      iconColor: "",
      subButtonClr: "text-[#0068b7]",
      resumeButton: {
        textClr: "text-[#0068b7]",
        text: "RESUME",
      },
    },
    dialogs: {
      buttons: {
        backgroundColor: "bg-black",
        color: "white",
      },
      bgHeader: "bg-[#0068b7]",
      dialogEndGame: {
        homeAwayBg: "bg-black",
        borderClr: "border-[#000000]",
        bordertext: "text-[#142665]",
        bordertextActive: "text-white",
      },
    },
    lounge: {
      backgroundColor: "bg-[#0068b7]",
      lobbyButton: {
        backgroundColor: "bg-black",
        color: "white",
        text: "JOIN LOBBY",
      },
      playersHeading: "Players Lounge",
      userBg: "bg-[#e2e2e2]",
    },
    site: {
      siteColor: "black",
      siteName: "www.24hourfitness.com",
    },
    card: {
      cardIcons: "/assets/images/themes/LA-24/user_plus_24.png",
      activeCard: '/assets/images/themes/LA-24/user_24.png'
    },
    footer: {
      footHead: {
        homeText: "HOME",
        awayText: "AWAY",
        bgClr: "bg-[#0068b7]",
      },
      footBody: {
        bodyBg: "bg-[#ffff]",
        bgGrey: "bg-[#e2e2e2]",
      },
    },
    loaderImgSm: "/assets/images/themes/LA-24/24_logo_2C_H.png",
    startLoaderClr: '#0068b7',
    startLoaderClrText: '[#0068b7]',
  },
};
