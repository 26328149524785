import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { archeTypeList } from "../../config/archeTypeList"
import { editAccountOnBoarding, editArcheType } from '../../store/auth/authAPI';
import { setSuccess } from '../../store/auth/authSlice';
import Loader from '../../utils/Loader';

// ----------------- Edit Profile page 2 ----------
const EditProfile2 = () => {
  const { locationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading: loading, error, success } = useSelector((store) => store.auth);
  const [errorState, setErrorState] = useState({
    firstNameError: false,
    displayNameError: false
  });
  useEffect(() => {
    setArcheType(user.ArcheType);
    setActiveGroup(user.GroupName);
    setFormData({ firstName: user.FirstName, displayName: user.DisplayName });
    if (success) {
      if (locationId) {
        navigate('/join-game/' + locationId);
      }
      else {
        navigate('/edit-profile');
      }
      dispatch(setSuccess(false));
    }
  }, [user, success]);


  const [submitted, setSubmitted] = useState(false);
  const [activeGroup, setActiveGroup] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    displayName: ""
  });
  const [archeType, setArcheType] = useState({});
  // groups
  let groups = [
    { title: "PG" },
    { title: "SG" },
    { title: "SF" },
    { title: "PF" },
    { title: "C" },
  ];
  const validate = () => {
    let firstNameError = false;
    let displayNameError = false;

    if (!formData.firstName) {
      firstNameError = true;
    }
    if (!formData.displayName) {
      displayNameError = true;
    }
    if (firstNameError || displayNameError) {
      setErrorState({
        firstNameError,
        displayNameError
      });
      return false;
    }
    setErrorState({
      firstNameError: false,
      displayNameError: false
    });
    return true;
  };

  const onSaveArcheType = (evt) => {
    evt.preventDefault();
    setSubmitted(true);
    if (validate()) {
      setSubmitted(false);
      dispatch(editArcheType({ UserId: user.Id, ArcheType: archeType, GroupName: activeGroup, FirstName: formData.firstName, DisplayName: formData.displayName, DisplayStatistics: user.DisplayStatistics }));
      if (!user.IsAccountOnBoarded) {
        dispatch(editAccountOnBoarding(user.Id));
      }
    }
  };


  return (
    <section className="h-max sm:px-5 w-full sm:flex flex-1 items-start justify-center">
      <div className="mx-auto text-center w-full bg-primary sm:min-w-[385px] max-w-[420px]">
        <div className="px-6 sm:px-8 sm:pt-7 pt-5 text-center relative pb-4 w-full">
          <h3 className="text-xl mb-4 font-semibold text-white sm:text-2xl">
            Profile Details
          </h3>
          <p className="mt-1">
            Welcome to Dreamhoops!
            <br />
            Customize your player profile.
          </p>
        </div>
        {error && <div className="flex items-center justify-center gap-2">
          <p className="text-sm text-[#ff5700]">{error}</p>
        </div>}
        <div className="px-6 sm:px-8 py-3 sm:py-5">
          <div className="mb-5 text-left">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="firstName"
            >
              First Name
            </label>
            <input
              className="w-full appearance-none rounded-full bg-inputbg px-4 py-3  text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="firstName"
              type="text"
              name="firstName"
              autoFocus
              onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              value={formData?.firstName}
              disabled={loading}
              readOnly={loading}
              autoComplete="off"
              maxLength={50}
            />
            {errorState.firstNameError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">First name is required</label>}

          </div>
          <div className="mb-5 text-left">
            <label
              className="text-md mb-1 block font-normal pl-2"
              htmlFor="userName"
            >
              Display Name (Public)
            </label>
            <input
              className="w-full appearance-none rounded-full bg-inputbg px-4 py-3  text-white focus:border-orange-600 focus:outline-none focus:ring-orange-600 border-2 border-transparent disabled:opacity-60"
              id="displayName"
              type="text"
              name="displayName"
              autoFocus
              onChange={(e) => setFormData({ ...formData, displayName: e.target.value })}
              value={formData?.displayName}
              disabled={loading}
              readOnly={loading}
              autoComplete="off"
              maxLength={14} 
            />
            {errorState.displayNameError && submitted && <label class="text-xs text-red-500 mb-1 block font-normal pl-2" for="email">Display name is required</label>}

          </div>
          <h3 className="text-xl mt-6 mb-5 font-semibold text-white">
            Player Archetype
          </h3>
          <div className="flex my-3 justify-center sm:gap-4 min-[350px]:gap-3 gap-1">
            {groups?.map((v, i) => (
              <button key={i} title={v?.title} onClick={() => setActiveGroup(v?.title)} className={`border border-white uppercase flex-1 max-[350px]:h-[50px] sm:w-[57px] sm:h-[57px] w-[53px] h-[53px] font-semibold text-2xl rounded cursor-pointer select-none ${activeGroup === v?.title ? " bg-white text-black" : ""} transition duration-300`}>
                {v?.title}
              </button>
            ))}
          </div>
          <div className="flex-wrap grid grid-cols-3 my-6 min-[376px]:gap-3 max-[376px]:gap-2 max-[310px]:gap-1"
            style={{
              justifyItems: "baseline"
            }}
          >
            {archeTypeList?.map((v, i) => (
              v.position.includes(activeGroup) ? (
                <button
                  key={i}
                  title={v?.name}
                  onClick={() => setArcheType({ Icon: v.icon, Name: v.name })}
                  className={`flex
              min-h-full 
              w-full
              max-w-[100px]
              cursor-pointer 
              select-none 
              flex-col 
              items-center
              justify-center
              rounded
              border-0 bg-[#011f19] p-2 py-1
              uppercase
              min-[376px]:mb-2
              ${archeType.Name === v?.name ? " bg-[#084438]" : ""}
              `}
                >
                  <img className="w-ful mx-auto mb-auto object-contain px-2 pt-2" width="100" src={v?.icon} alt="..." />
                  <span className="mt-1 text-[9px] min-[325px]:text-[11px]">{v?.name}</span>
                </button>
              ) : null
            ))}
          </div>
          <button
            type="button"
            disabled={loading}
            className="mt-6 mb-3 block w-full mx-auto cursor-pointer bg-orange-600 rounded-full text-center text-[16px] text-white hover:text-white border-0 sm:py-4 py-3 disabled:opacity-70 sm:p-3"
            onClick={onSaveArcheType}
          >
            {loading ? <Loader /> : "Save"}
          </button>
        </div>
      </div>
    </section>
  )
}

export default EditProfile2