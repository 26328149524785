import React, { useEffect } from "react";
import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross.svg";
import { useDispatch, useSelector } from "react-redux";
import { themes } from "./theme";
import { setNeedIntro } from "../../store/game/gameSlice";

const Tooltip = ({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  closeProps,
  size
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.auth);
  const bgButton = themes[user.theme].tooltip.nextButton.backgroundColor;
  const Finish = themes[user.theme].tooltip.nextButton.text;
  const colorButton = themes[user.theme].tooltip.nextButton.color
  const borderHeight = themes[user.theme].tooltip.joinPlayerBorder.height
  const borderLeft = themes[user.theme].tooltip.joinPlayerBorder.left
  const borderRight = themes[user.theme].tooltip.joinPlayerBorder.right
  const borderWidth = themes[user.theme].tooltip.joinPlayerBorder.width
  const borderTop = themes[user.theme].tooltip.joinPlayerBorder.top
  
  useEffect(() => {
    const time = setTimeout(() => {
      if (index === 1) {
        const spotlight = document.querySelector("#react-joyride-portal .react-joyride__spotlight");
        if (spotlight) {
          // spotlight.style.top = `${borderTop}`;
          // spotlight.style.height = `${borderHeight}`;
          // spotlight.style.width =`${borderWidth}`;
          // spotlight.style.left = `${borderLeft}`;
          // spotlight.style.right = `${borderRight}`;

        }
      }
    }, 200);
    return () => {
      clearTimeout(time);
    };
  }, []);

  return (
    <div
      style={step?.styles?.custom || {}}
      {...tooltipProps}
      className="rest-body-scroll relative w-full min-w-[290px] max-w-[370px] rounded-2xl bg-white px-4 pt-6 pb-5"
    >
      <button {...closeProps} onClick={() => dispatch(setNeedIntro(false))} className="absolute right-4 top-3 text-lg">
        <CrossIcon className="[&_*]:stroke-black" />
      </button>
      <div className="text-center">
        {step.title && <h3 className="mb-3 text-xl font-semibold">{step.title}</h3>}
        {step.content && <p className="mb-4 font-medium leading-5">{step.content}</p>}
      </div>
      <div className="flex items-center justify-between">
        <p className="text-[#808083]">
          {index + 1}/{size}
        </p>
        <div className="flex gap-4 pt-1">
          {index === 0 && (
            <button
              {...skipProps}
              className="whitespace-nowrap uppercase text-[#808083] underline decoration-[#808083]"
              onClickCapture={() => dispatch(setNeedIntro(false))}
            >
              skip tour
            </button>
          )}
          {index > 0 && (
            <button {...backProps} className="whitespace-nowrap uppercase text-[#808083] decoration-[#808083]">
              back
            </button>
          )}
          <button
            {...primaryProps}
            style={{color:`${colorButton}`}}
            className={`w-full max-w-[700px] rounded-xl ${bgButton}  py-2 px-12 text-lg font-medium`}
            onClickCapture={() => (index === 5 ? dispatch(setNeedIntro(false)) : null)}
          >
            {index === 0 ? `GET STARTED` : "Next" && index === 4 ? `${Finish}`:'Next' }
          </button>
        </div>
      </div>
    </div>
  );
};
export default Tooltip;
