import { useState, useEffect } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { archeTypeList } from "../../config/archeTypeList";
import { editArcheType } from "../../store/auth/authAPI";
import Loader from "../../utils/Loader";

// ----------------- Edit Profile page archeType ----------
const EditArcheType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading: loading, error } = useSelector((store) => store.auth);
  //
  const [archeType, setArcheType] = useState({});
  const [activeGroup, setActiveGroup] = useState('');

  useEffect(() => {
    setArcheType(user.ArcheType);
    setActiveGroup(user.GroupName);
  }, [user]);

  let groups = [{ title: "PG" }, { title: "SG" }, { title: "SF" }, { title: "PF" }, { title: "C" }];


  const onSaveArcheType = () => {
    dispatch(editArcheType({ UserId: user.Id, ArcheType: archeType, GroupName: activeGroup, FirstName: user.FirstName || "-", DisplayName: user.DisplayName, DisplayStatistics: user.DisplayStatistics }));
  };

  console.log(user)
  return (
    <section className=" w-full flex-1 items-start justify-center sm:flex sm:px-5">
      <div className="mx-auto w-full max-w-[420px] bg-primary text-center sm:min-w-[385px]">
        <div className="relative w-full rounded-br-[190px] rounded-bl-[190px] bg-[#011f19] px-6 pt-3 pb-[50px] text-center sm:rounded-br-[25px] sm:rounded-bl-[25px] sm:px-8 sm:pt-7">
          <span
            className="absolute left-[15px] top-[8px] flex cursor-pointer select-none items-center justify-center overflow-hidden rounded-full p-[4px] text-white shadow-lg sm:top-[19px]"
            role="button"
            title="Go back"
            onClick={() => navigate("/edit-profile")}
          >
            <BsArrowLeft style={{ fontSize: "22px" }} />
          </span>
          <h3 className="mb-4 text-lg font-black text-white sm:text-xl">Edit Player Archetype</h3>
          <h3 className="text-xl font-semibold text-white sm:text-2xl">{user.FirstName} {user.LastName}</h3>
          <h4 className="mt-[-7px] text-lg font-normal text-white sm:text-xl">
            {user.GroupName} {" - "}
            {user.ArcheType?.Name || "Sharpshooter"}
          </h4>
          <p className="mt-1 text-[12px]  italic min-[325px]:text-sm">Member since {user.CreatedOn}</p>
        </div>
        <div className="relative z-[2] mx-auto mt-[-39px] w-max max-w-full">
          <div className="mx-auto flex h-14 w-14 select-none items-center justify-center overflow-hidden rounded-full bg-orange-500 shadow-md sm:h-16 sm:w-16">
            <img className="w-full max-w-full object-contain" src={user.ArcheType?.Icon || "/avatar.png"} alt="" />
          </div>
        </div>
        <div className="px-6 py-3 sm:px-8 sm:py-5">
          <div className="my-3 flex justify-center gap-1 min-[350px]:gap-3 sm:gap-4">
            {groups?.map((v, i) => (
              <button
                key={i}
                title={v?.title}
                onClick={() => setActiveGroup(v?.title)}
                className={`h-[53px] w-[53px] flex-1 cursor-pointer select-none rounded border border-white text-2xl font-semibold uppercase sm:h-[57px] sm:w-[57px] ${activeGroup === v?.title ? " bg-white text-black" : ""
                  } transition duration-300`}
              >
                {v?.title}
              </button>
            ))}
          </div>
          <div
            className="my-6 grid grid-cols-3 flex-wrap max-[376px]:gap-2 max-[310px]:gap-1 min-[376px]:gap-3"
            style={{
              justifyItems: "baseline",
            }}
          >
            {archeTypeList?.map((v, i) =>
              v.position.includes(activeGroup) ? (
                <button
                  key={i}
                  title={v?.name}
                  onClick={() => setArcheType({ Icon: v.icon, Name: v.name })}
                  className={`flex
                min-h-full 
                w-full
                max-w-[100px]
                cursor-pointer 
                select-none 
                flex-col 
                items-center
                justify-center
                rounded
                border-0 bg-[#011f19] p-2 py-1
                uppercase
                min-[376px]:mb-2
                ${archeType.Name === v?.name ? " bg-[#084438]" : ""}
                `}
                >
                  <img className="w-ful mx-auto mb-auto object-contain px-2 pt-2" width="100" src={v?.icon} alt="..." />
                  <span className="mt-1 text-[9px] min-[325px]:text-[11px]">{v?.name}</span>
                </button>
              ) : null
            )}
          </div>
          <button
            type="button"
            disabled={loading}
            className="mx-auto mt-6 mb-3 block w-full cursor-pointer rounded-full border-0 bg-orange-600 py-3 text-center text-[16px] text-white hover:text-white disabled:opacity-70 sm:p-3 sm:py-4"
            onClick={onSaveArcheType}
          >
            {loading ? <Loader /> : "Save"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default EditArcheType;
