import axios from 'axios';
import { toast } from "../utils/customToast";
import { db } from '../libs/db'
import { TEAMS } from '../utils/constants';
import { GAME_STATUS } from '../pages/Game/constant';
/**
 * Create an Axios Client with defaults
 */

const options = {
    baseURL: import.meta.env.VITE_APIURL
};

const client = axios.create(options);

let subPlayers = [];

// Add a request interceptor
client.interceptors.request.use(async function (config) {
    let useLocal = false;
    let user = JSON.parse(localStorage.getItem('user'));
    if (user) {
        if (user.theme == 'la' || user.theme == '24hours') {
            useLocal = true;
        }
    }
    if (useLocal) {
        if (config.url.includes("/PlayersLounge/getByLocation/")) {
            const id = config.url.split('/')[3];
            let data = { Id: id, Queue: [], Home: [], Away: [] };
            let lounge = JSON.parse(localStorage.getItem(`lounge_${id}`));
            if (!lounge) {
                localStorage.setItem(`lounge_${id}`, JSON.stringify(data));
            }
            else {
                data = lounge;
            }
            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: data,
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };

                    return resolve(res);
                });
            }
        }
        else if (config.url.includes("/PlayersLounge/joinAsGuest")) {
            const data = config.data;
            let player = {
                Position: 0,
                User: {
                    Id: Date.now().toString(36) + Math.random().toString(36).substring(2),
                    Name: data.Name,
                    ArcheType: {
                        Icon: '/assets/images/arche-icons/guest-player.png',
                        Name: ''
                    }
                },
                IsGuest: true,
                QueueStartTime: new Date().toISOString()
            }
            let lounge = JSON.parse(localStorage.getItem(`lounge_${data.LocationId}`));
            lounge.Queue.push(player);
            localStorage.setItem(`lounge_${data.LocationId}`, JSON.stringify(lounge));
            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: player,
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };

                    return resolve(res);
                });
            }
        }
        else if (config.url.includes("/PlayersLounge/leaveGuestPlayer")) {
            const data = config.data;
            let lounge = JSON.parse(localStorage.getItem(`lounge_${data.LocationId}`));
            let index = lounge.Queue.findIndex(x => x.User.Id == data.UserId);
            if (index > -1) {
                lounge.Queue.splice(index, 1);
            }
            localStorage.setItem(`lounge_${data.LocationId}`, JSON.stringify(lounge));
            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: 'Removed',
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };

                    return resolve(res);
                });
            }
        }
        else if (config.url.includes("/PlayersLounge/addTeamPlayer")) {
            const data = config.data;
            let lounge = JSON.parse(localStorage.getItem(`lounge_${data.LocationId}`));
            let game = JSON.parse(localStorage.getItem(`game_${lounge.Id}`));
            let index = lounge.Queue.findIndex(x => x.User.Id == data.UserId);
            if (index > -1) {
                let player = lounge.Queue[index];
                if (game) {
                    if (game.GameStatus == GAME_STATUS.Paused) {
                        player.Position = index;
                        subPlayers.push(player);
                    }
                }
                lounge.Queue.splice(index, 1);
                player.Position = data.PlayerPosition;
                const key = data.Team == TEAMS.Home ? "Home" : "Away";
                lounge[key].push(player);
                localStorage.setItem(`lounge_${data.LocationId}`, JSON.stringify(lounge));
                config.adapter = function (config) {
                    return new Promise((resolve, reject) => {
                        const res = {
                            data: { team: data.Team, user: player },
                            status: 200,
                            statusText: "OK",
                            headers: { "content-type": "application/json; charset=utf-8" },
                            config,
                            request: {}
                        };

                        return resolve(res);
                    });
                }
            }
        }
        else if (config.url.includes("/PlayersLounge/removeTeamPlayer")) {
            const data = config.data;
            let lounge = JSON.parse(localStorage.getItem(`lounge_${data.LocationId}`));
            const key = data.Team == TEAMS.Home ? "Home" : "Away";
            const index = lounge[key].findIndex((x) => x.User.Id == data.UserId);
            if (index > -1) {
                let player = lounge[key][index];
                lounge[key].splice(index, 1);
                player.Position = 0;
                player.QueueStartTime = new Date().toISOString();
                if (data.IsSub) {
                    lounge.Queue.splice(0, 0, player);
                } else {
                    lounge.Queue.push(player);
                }
                localStorage.setItem(`lounge_${data.LocationId}`, JSON.stringify(lounge));
                config.adapter = function (config) {
                    return new Promise((resolve, reject) => {
                        const res = {
                            data: { team: data.Team, user: player, isSub: data.IsSub },
                            status: 200,
                            statusText: "OK",
                            headers: { "content-type": "application/json; charset=utf-8" },
                            config,
                            request: {}
                        };

                        return resolve(res);
                    });
                }
            }
        }
        else if (config.url.includes("/PlayersLounge/updatePosition")) {
            const data = config.data;
            let lounge = JSON.parse(localStorage.getItem(`lounge_${data.LocationId}`));
            const index = lounge.Queue.findIndex((x) => x.User.Id == data.UserId);
            if (index > -1) {
                let player = lounge.Queue[index];
                lounge.Queue.splice(index, 1);
                lounge.Queue.splice(data.NewPosition, 0, player);
                localStorage.setItem(`lounge_${data.LocationId}`, JSON.stringify(lounge));
                config.adapter = function (config) {
                    return new Promise((resolve, reject) => {
                        const res = {
                            data: { team: data.Team, user: player, position: data.NewPosition },
                            status: 200,
                            statusText: "OK",
                            headers: { "content-type": "application/json; charset=utf-8" },
                            config,
                            request: {}
                        };

                        return resolve(res);
                    });
                }
            }
        }
        else if (config.url.includes("/Game/start")) {
            const data = config.data;
            let game = JSON.parse(localStorage.getItem(`game_${data.LoungeId}`));
            if (!game) {
                let lounge = JSON.parse(localStorage.getItem(`lounge_${data.LocationId}`));
                game = {
                    Id: Date.now().toString(36) + Math.random().toString(36).substring(2),
                    LocationId: data.LocationId,
                    LoungeId: lounge.Id,
                    GameStatus: 0,
                    Score: { AwayScore: 0, HomeScore: 0 },
                    CreatedOn: new Date().toISOString()
                };
                localStorage.setItem(`game_${data.LocationId}`, JSON.stringify(game));
            }

            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: game,
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };
                    return resolve(res);
                });
            }
        }
        else if (config.url.includes("/Game/updateStatus")) {
            const data = config.data;
            let game = JSON.parse(localStorage.getItem(`game_${data.LocationId}`));
            game.GameStatus = data.Status;
            localStorage.setItem(`game_${data.LocationId}`, JSON.stringify(game));
            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: data,
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };
                    return resolve(res);
                });
            }
        }
        else if (config.url.includes("/Game/getByLocation")) {
            const id = config.url.split('/')[3];
            let game = JSON.parse(localStorage.getItem(`game_${id}`));
            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: game,
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };
                    return resolve(res);
                });
            }
        }
        else if (config.url.includes("/Game/updateScore")) {
            const data = config.data;
            let game = JSON.parse(localStorage.getItem(`game_${data.Location}`));
            if (data.Side == TEAMS.Home) {
                game.Score.HomeScore = data.Score;
            } else {
                game.Score.AwayScore = data.Score;
            }
            localStorage.setItem(`game_${data.Location}`, JSON.stringify(game));
            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: data,
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };
                    return resolve(res);
                });
            }
        }
        else if (config.url.includes("/Game/end")) {
            const data = config.data;
            let game = JSON.parse(localStorage.getItem(`game_${data.LocationId}`));
            let lounge = JSON.parse(localStorage.getItem(`lounge_${data.LocationId}`));
            let IsHomeWin = false;
            if (game.Score.HomeScore > game.Score.AwayScore)
                IsHomeWin = true;
            else if (game.Score.HomeScore < game.Score.AwayScore)
                IsHomeWin = false;

            let away = lounge.Queue.slice(0, 5);
            for (let i = 0; i < away.length; i++) {
                const element = away[i];
                away[i].Position = i;
                let index = lounge.Queue.findIndex(x => x.User.Id == element.User.Id);
                if (index > -1) {
                    lounge.Queue.splice(index, 1);
                }
            }
            let list;
            if (IsHomeWin) {
                list = [...lounge.Away];
                for (let i = 0; i < list.length; i++) {
                    const item = { ...list[i] };
                    item.QueueStartTime = new Date().toISOString();
                    item.Position = 0;
                    lounge.Queue.push(item);
                }
                lounge.Away = [...away];
            }
            else {
                list = [...lounge.Home];
                for (let i = 0; i < list.length; i++) {
                    const item = { ...list[i] };
                    item.QueueStartTime = new Date().toISOString();
                    item.Position = 0;
                    lounge.Queue.push(item);
                }
                lounge.Home = [...lounge.Away];
                lounge.Away = [...away];
            }

            if (subPlayers.length > 0) {
                for (let i = 0; i < subPlayers.length; i++) {
                    const p = subPlayers[i];
                    let index = lounge.Queue.findIndex(x => x.User.Id == p.User.Id);
                    if (index > -1) {
                        let pl = lounge.Queue[index];
                        lounge.Queue.splice(index, 1);
                        lounge.Queue.splice(p.Position, 0, pl);
                    }
                }
            }
            subPlayers = [];

            localStorage.setItem(`lounge_${data.LocationId}`, JSON.stringify(lounge));
            localStorage.removeItem(`game_${data.LocationId}`)
            config.adapter = function (config) {
                return new Promise((resolve, reject) => {
                    const res = {
                        data: { Team: IsHomeWin ? TEAMS.Home : TEAMS.Away, Result: { Home: lounge.Home, Away: lounge.Away, Queue: lounge.Queue } },
                        status: 200,
                        statusText: "OK",
                        headers: { "content-type": "application/json; charset=utf-8" },
                        config,
                        request: {}
                    };
                    return resolve(res);
                });
            }
        }
    }


    // Do something before request is sent
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (error) {
    console.log(error);
    if (error.response.status === 401) {
        let user = JSON.parse(localStorage.getItem('user'));
        axios.post(`${options.baseURL}/Account/logout/${user.Email}`).then(r => console.log('logout successfull'));
        //place your reentry code
        removeItem('user');
        removeItem('token');
        toast.warning("Session expired. Please re-login to continue");
        window.location.href = '/';
        db.activity.clear();
    }
    return Promise.reject(error);
});

client.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status) {
        //place your reentry code
        removeItem('user');
        removeItem('token');
        toast.warning("Session expired. Please re-login to continue");
        window.location.href = '/login';
    } else {
        return Promise.reject(error);
    }
});


export default client;
