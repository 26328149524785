
// check all the roles are available or not
export const containsAllRoles = (rolesWeWantToCheck, rolesUserHave) => {
  return rolesWeWantToCheck?.every((i) =>
    rolesUserHave?.includes(i?.toLowerCase())
  );
};

// Formate 1st letter of string into capitalize
export const capitalFirstLetter = (str = "") => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()
}

// convert string into capitalize
export const capitalizeString = (str = "") => {
  return str?.toUpperCase();
}

// convert string into lowercase
export const lowerString = (str = "") => {
  return str?.toLocaleLowerCase();
}

// check for even numbers
export const isEven = (n) => {
  n = Number(n);
  return n === 0 || !!(n && !(n % 2));
};

// check for odd numbers
export const isOdd = (n) => {
  return isEven(Number(n) + 1);
};
export const pad = (num, size) => {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}
export const formatRating = (rating) => {
  rating = rating.toFixed(0);
  if (rating >= 100) return 99;
  if (rating < 60) return 60;
  return rating;
}