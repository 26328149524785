

export const archeTypeList = [
  {
    icon: "/assets/images/arche-icons/2-way-player.png",
    name: "2 way player",
    position: "PG,SG,SF,PF",
  },
  {
    icon: "/assets/images/arche-icons/3-level-scorer.png",
    name: "3 level scorer",
    position: "PG,SG,SF",
  },
  {
    icon: "/assets/images/arche-icons/3&D.png",
    name: "3&D",
    position: "SG,SF",
  },
  {
    icon: "/assets/images/arche-icons/athletic-finisher.png",
    name: "Athletic finisher",
    position: "SF,PF",
  },
  {
    icon: "/assets/images/arche-icons/glass-cleaner.png",
    name: "Glass Cleaner",
    position: "PF,C",
  },
  {
    icon: "/assets/images/arche-icons/lock-down-defender.png",
    name: "Lock Down Defender",
    position: "PG,SG,SF,PF",
  },
  {
    icon: "/assets/images/arche-icons/midrange-specialist.png",
    name: "Midrange Specialist",
    position: "SG,SF,PF",
  },
  {
    icon: "/assets/images/arche-icons/paint-beast.png",
    name: "Paint Beast",
    position: "C",
  },
  {
    icon: "/assets/images/arche-icons/playmaker.png",
    name: "Playmaker",
    position: "PG,SG",
  },
  {
    icon: "/assets/images/arche-icons/post-scorer.png",
    name: "Post Scorer",
    position: "PF,C",
  },
  {
    icon: "/assets/images/arche-icons/pure-scorer.png",
    name: "Pure Scorer",
    position: "PG,SG,SF",
  },
  {
    icon: "/assets/images/arche-icons/sharpshooter.png",
    name: "Sharpshooter",
    position: "PG,SG,SF",
  },
  {
    icon: "/assets/images/arche-icons/shot-creator.png",
    name: "Shot Creator",
    position: "PG,SG,SF",
  },
  {
    icon: "/assets/images/arche-icons/slasher.png",
    name: "Slasher",
    position: "SG,SF,PF",
  },
  {
    icon: "/assets/images/arche-icons/stretch-big.png",
    name: "Stretch Big",
    position: "PF,C",
  },
];
