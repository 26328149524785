import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0);
    let ele = document.getElementById("main-app-wrapper-");
    if(ele) {
      ele.scrollTop = 0
    }
  }, [pathname])

  return null
}

export default ScrollTop