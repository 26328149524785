import React from "react";
import tablet from "../../assets/images/tablet.png";

const About = () => {
  return (
    <div className="bg-white py-12 padding-top ">
      <div className="mx-auto flex w-[80%] max-w-[1250px] flex-col flex-wrap">
        < div className="text-center" >
          <h2 className="my-4 text-center text-3xl font-semibold text-[#000] sm:text-3xl md:text-4xl lg:text-5xl">
            About us
          </h2>
          <p className="py-5 text-base text-[#000] font-medium sm:text-2xl">
            Welcome to Dreamhoops, the ultimate digital sign-in system designed to revolutionize the way pickup basketball games are organized! Our cutting-edge software brings convenience, efficiency, and a whole new level of excitement to the world of basketball
          </p>
        </div >
        <div className="flex flex-col lg:flex-row items-start lg:gap-14">
          <div className="relative flex-1 text-center md:my-6  overflow-hidden ">
            {/* <div className="relative flex-1 text-center md:my-6 border-[13px] border-[#000] rounded-[35px] overflow-hidden bg-[#1a1919]"> */}
            <img className="mx-auto object-contain" src={tablet} alt="Basketball image" />
          </div>
          <div className="mt-4 flex-1">
            <p className="py-5 text-base text-[#000] sm:text-2xl">
              At Dreamhoops, we understand the passion and love for the game that drives players to hit the court and engage in thrilling pickup games. We also recognize the challenges that often arise when it comes to organizing these games. That’s why we created a platform that combines the best of technology and basketball, empowering players and enhancing their overall experience.
            </p>
            <p className="py-5 text-base text-[#000] sm:text-2xl">
              So, whether you’re a casual player looking for a fun pickup game or a dedicated baller striving for greatness, Dreamhoops is your ultimate companion. Together, let’s elevate the game and make every shot count!
            </p>
          </div>
        </div>
      </div >
    </div >
  );
};

export default About;
